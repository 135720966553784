import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges, Optional } from '@angular/core';

import { ShowCalendarModal } from '../CalendarModal/ShowCalendarModal';
import { CalendarUtility } from '../CalendarUtility/CalendarUtility';

@Component({
  selector: 'YearView',
  templateUrl: './YearView.html',
  styleUrls: ['./YearView.css']
})

export class YearView implements OnInit {
    
    @ViewChild(ShowCalendarModal, { static: false }) private Modal: ShowCalendarModal;
    

  /* m_oToday's date */
  @Input() public m_oToday;

  /* calendarUtility date to load data for page */
  @Input() public m_oCurrentPage;

  /* Currently Selected Date */
  @Input() public m_oSelectedDate;

  /* Array with all the calendar data */
  @Input() public m_arrCalendar: any[] = [];

  /* Array with all the Events data */
  @Input() public m_arrEvents: any[] = [];

  /* Array with all the Events data */
  @Input() public Events: any[] = [];
  @Input() public AMTRunningOrder: any[] = [];
  @Input() public EventsList: any[] = [];
  @Input() public ResourcesBookingsList: any[] = [];
  @Input() public EventsData: any[] = [];

  /* Emits the new event, which calls the function in parent component (MainCalendar.ts) */
  @Output() dayClicked: EventEmitter<any> = new EventEmitter();

  /** Constants For header and displaying month names */
  public readonly m_arrMonthNames = [['January', 'February', 'March', 'April'], ['May', 'June', 'July', 'August'], ['September', 'October', 'November', 'December']];
  public readonly m_arrMonthNamesIndexes = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  public readonly m_arrDayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday'];
  public m_strTestEvent = '05-05-2018';
  public m_arrFormattedEvents = [];
    constructor() {
    // CalendarUtility.CheckIsUserLoggedIn();
      
  }

  /* When the page loading is finished, onInit */
    ngOnInit() {
      
    this.m_oCurrentPage = this.m_oCurrentPage;
    this.ChangeYear(0);
  }
  ngOnChanges(changes: SimpleChanges) {
    /* Fill Data in calendar according to date */
    this.ChangeYear(0);
    }

   
  public SetToday() {
    this.m_oCurrentPage = { ...this.m_oToday };
  }

  /** Returns some class, if there is any event! */
  public IsEventPresent(p_strMonth): string {
    /* Check if date is selected */
    for (var i = 0; i < this.Events.length; i++) {
      var l_oDateOfEvent = new Date(this.Events[i].Date);
      var l_intDay = l_oDateOfEvent.getDate();
      var l_intMonth = l_oDateOfEvent.getMonth();
      var l_intYear = l_oDateOfEvent.getFullYear();

      var p_intMonth = this.m_arrMonthNamesIndexes.indexOf(p_strMonth);

      if (l_intMonth == p_intMonth && l_intYear == this.m_oCurrentPage.year) {
        return 'someClass';
      }

    }
    /* No match found */
    return '';
  }

  /** Returns 'Bold (ClassName)' if col is m_oToday */
  public IsMonthAndYearSame(p_strMonth): string {
    var l_oCurrentPage = { ...this.m_oCurrentPage };
    l_oCurrentPage.month = this.m_arrMonthNamesIndexes.indexOf(p_strMonth);
    if ((this.IsSameDate(this.m_oToday.date, l_oCurrentPage, this.m_oToday)) || (this.IsSameDate(this.m_oToday.date, l_oCurrentPage, this.m_oSelectedDate))) {
      return 'ClassName';
    }
    return '';
  }

  /** Select a day in the open page */
  public ChangeFormatToMonth(p_strMonth) {
    this.m_oSelectedDate.date = this.m_oCurrentPage.date;
    this.m_oSelectedDate.month = this.m_arrMonthNamesIndexes.indexOf(p_strMonth);
    this.m_oSelectedDate.year = this.m_oCurrentPage.year;
    let data = {} as any;
    data.Date = this.m_oSelectedDate;
    data.Change = true;
    data.ViewFormat = 'month';
    this.dayClicked.emit(data);
  }

  /* Returns true if two dates are the same with the date taken separately */
  public IsSameDate(p_intDate, p_oFirstMonthAndYear, p_oSecondMonthAndYear): boolean {

    return p_oFirstMonthAndYear.month == p_oSecondMonthAndYear.month &&
      p_oFirstMonthAndYear.year == p_oSecondMonthAndYear.year;
  }

  /** Select a day in the open page */
  public SelectDay(p_strMonth) {
    this.m_oSelectedDate.date = this.m_oToday.date;
    this.m_oSelectedDate.month = this.m_arrMonthNamesIndexes.indexOf(p_strMonth);
    this.m_oSelectedDate.year = this.m_oCurrentPage.year;
    this.dayClicked.emit(this.m_oSelectedDate);
  }

  /** Change the month +1 or -1 */
  public ChangeYear(p_intYear: number) {
    var l_oDate = new Date(this.m_oCurrentPage.year, this.m_oCurrentPage.month, this.m_oCurrentPage.date);
    l_oDate.setFullYear(l_oDate.getFullYear() + p_intYear);

    this.m_oCurrentPage.date = l_oDate.getDate();
    this.m_oCurrentPage.month = l_oDate.getMonth();
    this.m_oCurrentPage.year = l_oDate.getFullYear();

    //this.dayClicked.emit(this.m_oCurrentPage);
    this.RemovePreviouslyBindedEventsBoxes();
    this.m_arrEvents = [];
    this.m_arrRunningOrders = [];
    this.BindEventsFromJSON(this.EventsData);
  }

  public m_arrRunningOrders = [];
  public BindEventsFromJSON(p_oGridData) {
    if (p_oGridData.length > 0) {
      this.Index = -1;
      var l_arrRunningOrders = this.m_arrRunningOrders;
      var l_intCount = 0;
      if (this.m_arrEvents.length > 0)
        l_intCount = this.m_arrEvents.length;
      for (var i = 0; i < this.m_arrMonthNamesIndexes.length; i++) {
        var l_boolMatched = false;
          var l_strText = 'Some text to display';
          var l_strTitle = '';
        var l_intStartMinutesJson = 0;
        var l_intDurationMinutes = 0;
        var l_strStartTime = '';
        var l_strEndTime = '';
        var l_intCounter = 0;
        for (var j = 0; j < p_oGridData.length; j++) {
          if (p_oGridData[j].StartTime != '') {
            var l_boolMatched = false;
            var l_strStartDateInDB = p_oGridData[j].StartTime;
            var l_strURL = 'events';


            if (p_oGridData[j].URL)
              l_strURL = p_oGridData[j].URL;

              var l_intSelectedEventDBId = 0;
              if (p_oGridData[j].ID)
                  l_intSelectedEventDBId = p_oGridData[j].ID;


            var l_strEndDateInDB = p_oGridData[j].EndTime;
            var l_boolAllDay = false as any;
            if (p_oGridData[j].AllDay)
              l_boolAllDay = p_oGridData[j].AllDay;
            var l_oDate = new Date(l_strStartDateInDB) as any;
            if (l_oDate.toString() != 'Invalid Date') {
              var l_intDay = l_oDate.getDate();
              var l_intMonth = l_oDate.getMonth();
              var l_intYear = l_oDate.getFullYear();
              var l_intStartHour = l_oDate.getHours();
              var l_intStartMinutes = l_oDate.getMinutes();

              l_strEndDateInDB = new Date(l_strEndDateInDB) as any;
              var l_intEndDay = l_strEndDateInDB.getDate();
              var l_intEndMonth = l_strEndDateInDB.getMonth();
              var l_intEndYear = l_strEndDateInDB.getFullYear();
              var l_intEndHour = l_strEndDateInDB.getHours();
              var l_intEndMinutes = l_strEndDateInDB.getMinutes();
              var l_intCurrentDate = l_intDay;
              if (l_intMonth == l_intEndMonth)
                l_intCurrentDate = l_intDay;
              if (l_intMonth < l_intEndMonth)
                l_intCurrentDate = l_intEndDay;
              var l_oCurrentPageDate = new Date(this.m_oCurrentPage.year, i, l_intDay);
              var l_oCurrentPageDate2 = new Date(this.m_oCurrentPage.year, i, l_intEndDay);

              if (CalendarUtility.IsDateBetweenTwoDates(l_oDate, l_oCurrentPageDate, l_strEndDateInDB) || CalendarUtility.IsDateBetweenTwoDates(l_oDate, l_oCurrentPageDate2, l_strEndDateInDB)) {
                l_intCounter++;
                l_boolMatched = true;
                l_intStartMinutesJson = l_intStartMinutes;
                l_intDurationMinutes = (Math.abs((l_intEndHour - l_intStartHour)) * 60) + ((l_intEndMinutes - l_intStartMinutes));
                l_strTitle = p_oGridData[j].Title;
                if (p_oGridData[j].Text)
                  l_strText = p_oGridData[j].Text;

                if (l_intStartHour < 10)
                  l_intStartHour = 0 + '' + l_intStartHour;
                if (l_intStartMinutes < 10)
                  l_intStartMinutes = 0 + '' + l_intStartMinutes;
                if (l_intEndHour < 10)
                  l_intEndHour = 0 + '' + l_intEndHour;
                if (l_intEndMinutes < 10)
                  l_intEndMinutes = 0 + '' + l_intEndMinutes;

                if (l_boolAllDay) {
                  l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear;
                  l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear;
                }
                else {
                  l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear + ', ' + l_intStartHour + ':' + l_intStartMinutes;
                  l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear + ', ' + l_intEndHour + ':' + l_intEndMinutes;
                }

                var l_strHour = l_intDay;
                var l_strID = Math.floor(i / 4) + '' + (i % 4);

                //l_arrRunningOrders[l_intCount] = { id: l_strID, Date: l_intDay, Text: l_strText, StartMinutes: l_intStartMinutesJson, Duration: l_intDurationMinutes, StartTime: l_strStartTime, EndTime: l_strEndTime };
                //l_intCount++;
                }
              }
            }
            if (l_boolMatched) {
              var l_intIndex = CalendarUtility.GetIndexExactlyMatchingColumn(l_arrRunningOrders, 'id', l_strID);
              if (l_intIndex > -1) {
                l_arrRunningOrders[l_intIndex].Count++;
                l_intCounter = l_arrRunningOrders[l_intIndex].Count;
              }
              else
                l_arrRunningOrders[l_arrRunningOrders.length] = { id: l_strID, Date: i, Count: l_intCounter };

                this.m_arrEvents[l_intCount] = { id: 'tdColumn_' + l_strID, StartTime: l_strStartTime, EndTime: l_strEndTime, Title: l_strTitle, Text: l_strText, URL: l_strURL, AllDay: l_boolAllDay, SelectedID: l_intSelectedEventDBId};
              l_intCount++;
            }
          }
        }
        //this.m_arrRunningOrders = this.m_arrRunningOrders.concat(l_arrRunningOrders);
        l_arrRunningOrders = this.m_arrRunningOrders;
      }
    }

  public Index = -1;
    public BindEvents(p_intIndex) {
      
    if (p_intIndex != this.Index) {
        this.Index = p_intIndex;
      for (var i = 0; i < this.m_arrRunningOrders.length; i++) {
          var l_strElementID = this.m_arrRunningOrders[i].id;          
          var l_oElement = document.getElementById('tdColumn_' + l_strElementID);
         
          if (l_oElement != null) {
              l_oElement.innerText = this.m_arrRunningOrders[i].Count;
              l_oElement.style.display = '';
          }
      }
    }
    }

  public GetHeightForCell(p_intHour, p_intDuration, p_intStartMinutes) {
    var l_intDivHeight = ((p_intDuration / 60) * 40).toFixed(0);
    var l_intMaxDuration = ((24 - p_intHour) * 60) + (60 - p_intStartMinutes);
    var l_intMaxDivHeight = ((l_intMaxDuration / 60) * 40).toFixed(0);
    if (parseInt(l_intDivHeight) > parseInt(l_intMaxDivHeight))
      return l_intMaxDivHeight;
    else
      return l_intDivHeight;
  }

  public RemovePreviouslyBindedEventsBoxes() {

    for (var i = 0; i < this.m_arrRunningOrders.length; i++) {
      var l_strElementID = this.m_arrRunningOrders[i].id;
      var l_oElement = document.getElementById('tdColumn_' + l_strElementID);
      if (l_oElement != null) {
          l_oElement.innerHTML = '';
           l_oElement.style.display = 'none';
          //l_oElement.removeEventListener('click', this.ShowEventsDataFromJson);
      }
    }
  }

  public ShowEventsDataFromJson(p_oEvent) {

      var l_arrJsonData = CalendarUtility.FilterJsonExactlyMatching(this.m_arrEvents, 'id', p_oEvent);

/*
    this.m_arrFormattedEvents = [];
    var l_arrFormattedColumns = [];
    var l_arrFormattedValues = [];
    let l_intCounter = 0;
    for (let l_oRow of l_arrJsonData) {
      console.log("Row:", l_oRow);
      for (let l_oColumn in l_oRow) {
        console.log("      Column:", l_oColumn, "value:", l_oRow[l_oColumn]);

        if (l_oColumn.indexOf('id') < 0 && l_oColumn.indexOf('Color') && l_oColumn != 'PersonalLeave' &&  l_oColumn != 'Initials') {
          var l_strColumnName = l_oColumn;

          if (l_strColumnName == 'Text')
            l_strColumnName = 'Details';

          l_arrFormattedColumns[l_intCounter] = l_strColumnName;
          l_arrFormattedValues[l_intCounter] = l_oRow[l_oColumn];
          this.m_arrFormattedEvents[l_intCounter] = l_strColumnName + ': ' + l_oRow[l_oColumn]; // + " " + Math.random() * 100 + 100;
          l_intCounter++;
        }

      }
    }   

*/

      if (this.Modal) {

          this.Modal.FormattedValues = l_arrJsonData;
          this.Modal.m_arrFormattedEvents = [];

        //this.Modal.FormattedColumns = l_arrFormattedColumns;
        //this.Modal.FormattedValues = l_arrFormattedValues;
        //this.Modal.m_arrFormattedEvents = this.m_arrFormattedEvents;
        this.Modal.ShowFormattedJsonData();
    }
  }

  /** Gets the DaysPerMonth array, returns value of index corresponding to month number */
  public GetDaysOfMonth(p_intMonth: number, p_intYear: number): number {

    /* Check leap years if February */
    if (p_intMonth == 1 && this.IsLeapYear(p_intYear)) {
      return 29;
    }

    /** Return the number of days, corresponding to that month */
    return [31, 28, 31, 30, 31, 30,
      31, 31, 30, 31, 30, 31][p_intMonth];
  }

  /** Returns true if leap year */
  public IsLeapYear(p_intYear): boolean {
    return ((p_intYear % 4 == 0) && (p_intYear % 100 != 0)) || (p_intYear % 400 == 0);
  }

}
