import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { DefaultTS } from '../SharedComponents/Default';
@Component({
  selector: 'app-Scholar',
  templateUrl: './Scholar.html'
})
export class Scholar {
  @Input() public ShowAddBtn;
  @Input() public LoadWithOutUser;
  @Output() public ScholarOutPutObject = new EventEmitter();
  @Output() ReloadScholarGridData: EventEmitter<any> = new EventEmitter();
  public http: HttpClient;
  public baseUrl: string;
  public IsAdd: boolean;
  public IsEdit: boolean;
  public IsAddress: boolean;
  public valuecheck: boolean;
  public IsUpdateRecord: boolean;
  public ISCID = -1;
  public ScholarID = -1;
  public IndexForAdditionalAddressScholar = -2;
  public PersonIdToDeleted = -1;
  public ScholarIdToDeleted = -1;
  public GridData: any[];
  public GridDataTemp: any[];
  public ISCScholarObject: any = {} as any;
  public ISCScholarOutPutObject: any = {} as any;
  public RelationTypeDropdown: any[];
  public CountryDropdown: any[];
  public AddressTypeDropdown: any[];
  public ISCScholarAddressArray = [];
  public ShowErrors = [];
  public ShowErrorsMsg = false;
  //ZA: Pagination part
  public pagination: any[];
  public GridDataDataAfterFiltering: any[];
  public totalItems = 3;
  public currentPage = 1;
  public itemsPerPage = 10;
  public ItemsPerPageRange: Array<any> = [0, 1];
  public RecordsPerPage = [5, 10, 25, 50, 100, 250, 500, 1000];

  public DuplicateRecordObject: any = {} as any;
  public NeedToShowDuplicateError = false;
  public DuplicateRecordDataID = -1;
  public DuplicateRecordData: any[];
  public SaveEmailIDTemp = '';

  //public minDate = new Date('0001-01-01T00:00:00Z');

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private router: Router, private cd: ChangeDetectorRef, private zone: NgZone, private router2: ActivatedRoute, public Default: DefaultTS) {
    this.http = http;
    this.baseUrl = baseUrl;
    this.ISCScholarObject.ISCScholarID = -1;
    this.ISCScholarObject.NationalityID = 66;
    this.ISCScholarObject.Gender = 0;
    this.ISCScholarObject.MaritalStatus = 0;
    this.IsAdd = false;
    this.IsEdit = false;
    this.IsAddress = false;
    this.valuecheck = false;
    this.IsUpdateRecord = false;
    this.Default.UserTrackingSaveRecord();
    this.Default.CheckLoginUserDetails();
    if (this.Default.AddScholarFromAppointmentScreen) {
      this.IsAdd = true;
    } else {
      this.IsAdd = false;
    }
  }

  ngOnInit() {
    if (this.ShowAddBtn == undefined || this.ShowAddBtn == null)
      this.ShowAddBtn = true;
    if (this.LoadWithOutUser == undefined || this.LoadWithOutUser == null)
      this.LoadWithOutUser = false;

    this.RelationTypeGetGridDataForDopdown();
    this.CountryGetGridDataForDopdown();
    this.AddressTypeGetGridDataForDropdown();
    this.GetScholarData();
  }

  public DefaultDropDownValue() {
    this.ISCScholarObject.NationalityID = 66;
    this.ISCScholarObject.Gender = 0;
    this.ISCScholarObject.MaritalStatus = 0;
  }
  public ISCScholarGetRecordForOutPut(id: number) {

    var l_intIndex = this.Default.GetIndexExactlyMatchingColumn(this.GridData, 'ISCScholarID', id);
    if (l_intIndex > -1) {
      this.ISCScholarOutPutObject.FirstName = this.GridData[l_intIndex]["FirstName"];
      this.ISCScholarOutPutObject.LastName = this.GridData[l_intIndex]["LastName"];
      this.ISCScholarOutPutObject.Email = this.GridData[l_intIndex]["Email"];
      // this.ISCScholarOutPutObject.Email = this.GridData[l_intIndex]["MobileNumber"];
      this.ScholarID = this.GridData[l_intIndex]["ISCScholarID"];
      this.ISCScholarOutPutObject.ISCScholarID = this.GridData[l_intIndex]["ISCScholarID"];
      this.ISCScholarOutPutObject.ISCClientID = 0;
      this.ScholarOutPutObject.emit(this.ISCScholarOutPutObject);
    }
  }
  public DeSelectScholar() {
    this.ScholarID = -1;
    this.ISCScholarOutPutObject = {};
    this.ScholarOutPutObject.emit(this.ISCScholarOutPutObject);
  }
  public GetScholarData() {
    let data = {} as any;
    data.UserFlag = this.LoadWithOutUser ? -1 : 0;
    data.FirstName = this.ISCScholarObject.FilterFirstName;
    data.LastName = this.ISCScholarObject.FilterLastName;
    data.Email = this.ISCScholarObject.FilterEmail;
    data.MobileNumber = this.ISCScholarObject.FilterMobileNumber;

    this.Default.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/ISCScholar/ISCScholarGetCompleteDataSetCustom1', data).subscribe(result => {
      var l_arrResponse = result as any;
      this.GridData = l_arrResponse[0];
      this.Default.SetMasterAnimation(false);
      //this.GridDataTemp = this.Default.AssignGridDataValuesToOtherGridData(this.GridData);

      this.GridDataDataAfterFiltering = this.Default.AssignGridDataValuesToOtherGridData(this.GridData);
      this.pagination = [] as any;
      this.pagination = this.GridData.slice((this.currentPage - 1) * this.itemsPerPage, (this.currentPage) * this.itemsPerPage)
      if (this.pagination.length == 0) {
        this.Default.ShowAlert('No Record Found.');
      }
      this.totalItems = this.GridData.length;
      if (this.totalItems > 0) {
        this.ItemsPerPageRange[0] = ((this.currentPage - 1) * this.itemsPerPage) + 1;
        this.ItemsPerPageRange[1] = (this.ItemsPerPageRange[0] + this.pagination.length) - 1;
      }
      else {
        this.ItemsPerPageRange[0] = ((this.currentPage - 1) * this.itemsPerPage);
        this.ItemsPerPageRange[1] = (this.ItemsPerPageRange[0] + this.pagination.length);
      }
      this.currentPage = 1;
      this.GetListDataForPagination(false);

    }, error => console.error(error));
  }

  public ISCSaveScholarNewRecord() {

    let data = {} as any;
    //person table
    data.Title = '';
    data.FirstName = '';
    data.MiddleName = '';
    data.LastName = '';
    data.DateOfBirth = this.Default.minDate;
    data.PlaceOfBirth = '';
    data.Nationality = '';
    data.NationalityID = 0;
    data.Gender = 0;
    data.MaritalStatus = 0;
    data.NationalityStatus = '';
    data.EmailAddress = '';
    data.MobileNumber = '';
    data.HomePhoneNumber = '';
    data.WorkPhoneNumber = '';
    data.ImageURL = '';

    data.CreatedByID = 0;
    data.LastUpdatedByID = 0;
    data.IsDeleted = false;
    //address table
    data.ISCAdressArray = this.ISCScholarAddressArray;

    for (let field in data) {
      if (this.ISCScholarObject[field] !== undefined && this.ISCScholarObject[field] != null && this.ISCScholarObject[field] != '')
        data[field] = this.ISCScholarObject[field];
    }
    this.Default.SetMasterAnimation(true);

    this.http.post('api/ISCScholar/ISCSaveScholarData', data).subscribe(result => {
      this.IsAdd = false;
      this.ReloadScholarGridData.emit();
      if (this.Default.AddScholarFromAppointmentScreen == false) {
        var l_oResponse = result[0];
        if (l_oResponse.Status == 1) {
          this.Default.ShowAlert("Record has been added successfully.")
        } else {
          this.Default.ShowAlert(l_oResponse.Message);
        }
      }
      this.GetScholarData();
      this.CancelRecord();
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }

  public RelationTypeGetGridDataForDopdown() {
    this.Default.SetMasterAnimation(true);
    this.http.get(this.baseUrl + 'api/ISCRelationType/ISCRelationTypeGetCompleteDataSetForDopdown').subscribe(result => {
      var l_arrResponse = result[0] as any;
      this.RelationTypeDropdown = l_arrResponse;
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }


  public CountryGetGridDataForDopdown() {
    this.Default.SetMasterAnimation(true);
    this.http.get(this.baseUrl + 'api/Country/CountryGetCompleteDataSetForDropdown').subscribe(result => {
      var l_arrResponse = result[0] as any;
      this.CountryDropdown = l_arrResponse;
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }


  public AddressTypeGetGridDataForDropdown() {
    this.Default.SetMasterAnimation(true);
    this.http.get(this.baseUrl + 'api/AddressType/AddressTypeGetCompleteDataSetForDropdown').subscribe(result => {
      var l_arrResponse = result[0] as any;
      this.AddressTypeDropdown = l_arrResponse;
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }

  public GetDivForAdditionalAddressScholar(value) {
    var Address = value;
    if (Address == -1) {
      this.ISCScholarAddressArray.push({ 'Line1': '', 'Line2': '', 'Line3': '', 'AddressTypeID': 2, 'CountryID': 66, 'State': '', 'City': '', 'Town': '', 'PostCode': '', 'AddressID': 0, 'ISCPersonAddressID': 0 });
    }
    else {
      this.ISCScholarAddressArray.splice(value, 1);
    }
  }

  public GetIndexForAdditionalAddressScholar(p_Index) {
    this.IndexForAdditionalAddressScholar = p_Index;
  }
  public ISCScholarGetRecordForEdit(id: number) {
    let data = {} as any;
    data.ISCScholarID = id;
    this.Default.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/ISCScholar/ISCScholarGetDetailsByScholarID', data).subscribe(result => {
      var l_arrResponse = result[0] as any;
      if (l_arrResponse.length > 0) {
        this.ISCScholarObject.ISCScholarID = l_arrResponse[0].ISCScholarID;
        this.ISCScholarObject.SiteUserID = l_arrResponse[0].SiteUserID;
        this.ISCScholarObject.ISCPersonID = l_arrResponse[0].ISCPersonID;
        this.ISCScholarObject.Title = l_arrResponse[0].Title;
        this.ISCScholarObject.FirstName = l_arrResponse[0].FirstName;
        this.ISCScholarObject.MiddleName = l_arrResponse[0].MiddleName;
        this.ISCScholarObject.LastName = l_arrResponse[0].LastName;
       // this.ISCScholarObject.DateOfBirth = l_arrResponse[0].DOB;
        if (l_arrResponse[0].DOB == "1900-01-01T00:00:00") {
          this.ISCScholarObject.DateOfBirth = '';
        } else {
          this.ISCScholarObject.DateOfBirth = l_arrResponse[0].DOB;
        }
        this.ISCScholarObject.PlaceOfBirth = l_arrResponse[0].PlaceOfBirth;
        this.ISCScholarObject.Nationality = l_arrResponse[0].Nationality;
        this.ISCScholarObject.Gender = l_arrResponse[0].Gender;
        this.ISCScholarObject.MaritalStatus = l_arrResponse[0].MaritalStatus;
        this.ISCScholarObject.NationalityStatus = l_arrResponse[0].NationalityStatus;
        this.ISCScholarObject.NationalityID = l_arrResponse[0].NationalityID;
        this.ISCScholarObject.EmailAddress = l_arrResponse[0].Email;
        this.SaveEmailIDTemp = this.ISCScholarObject.EmailAddress;
        this.ISCScholarObject.MobileNumber = l_arrResponse[0].MobileNumber;
        this.ISCScholarObject.HomePhoneNumber = l_arrResponse[0].HomePhoneNumber;
        this.ISCScholarObject.WorkPhoneNumber = l_arrResponse[0].WorkPhoneNumber;
        this.ISCScholarObject.ImageURL = l_arrResponse[0].ImageURL;
        this.ISCScholarAddressArray = [];
        for (var i = 0; i < l_arrResponse.length; i++) {
          this.ISCScholarAddressArray.push({ 'Line1': l_arrResponse[i].Line1, 'Line2': l_arrResponse[i].Line2, 'Line3': l_arrResponse[i].Line3, 'AddressTypeID': l_arrResponse[i].AddressTypeID, 'CountryID': l_arrResponse[i].CountryID, 'State': l_arrResponse[i].State, 'City': l_arrResponse[i].City, 'Town': l_arrResponse[i].Town, 'PostCode': l_arrResponse[i].PostCode, 'AddressID': l_arrResponse[i].AddressID, 'ISCPersonAddressID': l_arrResponse[i].ISCPersonAddressID });

        }
        this.IsAdd = true;
        this.IsEdit = true;
        this.IsAddress = true;
      }
      this.Default.SetMasterAnimation(false);
      //else this.default.showalert('Oops!something went wrong while fetching data');
    }, error => console.error(error));

  }
  public ISCScholarUpdateRecord() {
    let data = {} as any;
    //person table
    data.ISCScholarID = 0;
    data.SiteUserID = 0;
    data.ISCPersonID = 0;
    data.Title = '';
    data.FirstName = '';
    data.MiddleName = '';
    data.LastName = '';
    data.DateOfBirth = this.Default.minDate;
    data.PlaceOfBirth = '';
    data.Nationality = '';
    data.Gender = 0;
    data.MaritalStatus = 0;
    data.NationalityStatus = '';
    data.EmailAddress = '';
    data.MobileNumber = '';
    data.HomePhoneNumber = '';
    data.WorkPhoneNumber = '';
    data.ImageURL = '';
    data.NationalityID = 0;
    data.CountryID = 0;
    data.CreatedByID = 0;
    data.LastUpdatedByID = 0;
    data.IsDeleted = false;
    for (let field in data) {
      if (this.ISCScholarObject[field] !== undefined && this.ISCScholarObject[field] != null && this.ISCScholarObject[field] != '')
        data[field] = this.ISCScholarObject[field];
    }
    data.ISCAdressArray = this.ISCScholarAddressArray;
    this.Default.SetMasterAnimation(true);
    this.http.post('api/ISCScholar/ISCSaveScholarData', data).subscribe(result => {
      var l_oResponse = result[0];
      if (l_oResponse.Status == 1) {
        this.Default.ShowAlert("Record has been updated successfully.")
      } else {
        this.Default.ShowAlert(l_oResponse.Message);
      }
      this.CancelRecord();
      this.GetScholarData();
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }

  public ISCScholarDeleteRecord(PersonID: number, ScholarID: number, ) {
    let data: any = {} as any;
    data.ISCPersonID = PersonID;
    data.ISCScholarID = ScholarID;
    this.Default.SetMasterAnimation(true);
    this.http.post('api/ISCScholar/ISCDeletePersonRecord', data).subscribe(result => {
      var l_oResponse = result[0];
      if (l_oResponse.Status == 1) {
        this.Default.ShowAlert(l_oResponse.Message)
      } else {
        this.Default.ShowAlert(l_oResponse.Message);
      }
      this.ClearObject();
      this.GetScholarData();
      this.Default.SetMasterAnimation(false);
      this.PersonIdToDeleted = -1;
      this.ScholarIdToDeleted = -1;
    }, error => console.error(error));
  }

  public SetDeletableScholarID(p_PersonID, p_ScholarID) {
    this.PersonIdToDeleted = p_PersonID;
    this.ScholarIdToDeleted = p_ScholarID;
  }

  public ClearObject() {
    this.ISCScholarObject = {};
    this.ISCID = -1;
    this.ISCScholarAddressArray = [];
    this.ShowErrors = [];
    this.NeedToShowDuplicateError = false;
    this.DuplicateRecordDataID = -1;
    this.ISCScholarObject.ISCScholarID = -1;
    this.SaveEmailIDTemp = '';
    this.Default.AddScholarFromAppointmentScreen = false;
  }

  public CancelRecord() {
    this.IsAdd = false;
    this.IsEdit = false;
    this.GoBackToAppointmentScreen();
    this.ClearObject();

  }

  public GoBackToAppointmentScreen() {
    if (this.Default.AddScholarFromAppointmentScreen) {
      this.Default.AddClientFromCaseScreen = false;
    }
  }

  public ISCScholarValidation(p_SaveClicked) {

    var l_boolShouldSave = true;
    if (this.ShowErrorsMsg) {
      this.ShowErrors = [];
      if (!this.ISCScholarObject.FirstName || this.ISCScholarObject.FirstName == '') {
        this.ShowErrors[31] = '*Required';
        l_boolShouldSave = false;
      }
      if (!this.ISCScholarObject.LastName || this.ISCScholarObject.LastName == '') {
        this.ShowErrors[32] = '*Required';
        l_boolShouldSave = false;
      }
      if (!this.ISCScholarObject.EmailAddress || this.ISCScholarObject.EmailAddress == '') {
        this.ShowErrors[33] = '*Required';
        l_boolShouldSave = false;
      }
      for (var i = 0; i < this.ISCScholarAddressArray.length; i++) {
        if (!this.ISCScholarAddressArray[i].Line1 || this.ISCScholarAddressArray[i].Line1 == '') {
          this.ShowErrors[i] = '*Required';
          l_boolShouldSave = false;
        }
        if (!this.ISCScholarAddressArray[i].AddressTypeID || this.ISCScholarAddressArray[i].AddressTypeID == '') {
          this.ShowErrors["1" + i] = '*Required';
          l_boolShouldSave = false;
        }
        if (!this.ISCScholarAddressArray[i].CountryID || this.ISCScholarAddressArray[i].CountryID == '') {
          this.ShowErrors["2" + i] = '*Required';
          l_boolShouldSave = false;
        }
      }
      if (this.ISCScholarAddressArray.length == 0) {
        this.ShowErrors[34] = "Atleast One Address Is Required.";
        l_boolShouldSave = false;
      }
      if (this.ISCScholarObject.EmailAddress != undefined) {
        if (!this.Default.isValidEmail(this.ISCScholarObject.EmailAddress)) {
          this.ShowErrors[33] = '*Please Enter Valid Email ID.';
          l_boolShouldSave = false;
        }
        if (this.NeedToShowDuplicateError) {
          this.ShowErrors[33] = '*Please Enter Other Email ID.';
          l_boolShouldSave = false;
        }
        this.NeedToShowDuplicateError = false;
      }

      if (!this.ISCScholarObject.MobileNumber || this.ISCScholarObject.MobileNumber == '') {
        this.ShowErrors[35] = '*Required';
        l_boolShouldSave = false;
      }

      if (l_boolShouldSave && p_SaveClicked && !this.IsUpdateRecord) {
        this.ISCSaveScholarNewRecord();
      }
      if (l_boolShouldSave && p_SaveClicked && this.IsUpdateRecord) {
        this.ISCScholarUpdateRecord();
        this.IsUpdateRecord = false;
      }
    }

  }
  public GetListDataForPagination(p_boolRememberFilter: boolean) {
    this.pagination = this.GridDataDataAfterFiltering.slice((this.currentPage - 1) * this.itemsPerPage, (this.currentPage) * this.itemsPerPage);
    this.totalItems = this.GridDataDataAfterFiltering.length;
    if (this.pagination.length == 0 && this.totalItems > 0 && this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      this.GetListDataForPagination(p_boolRememberFilter);
    }
    if (this.totalItems > 0) {
      this.ItemsPerPageRange[0] = ((this.currentPage - 1) * this.itemsPerPage) + 1;
      this.ItemsPerPageRange[1] = (this.ItemsPerPageRange[0] + this.pagination.length) - 1;
    }
    else {
      this.ItemsPerPageRange[0] = ((this.currentPage - 1) * this.itemsPerPage);
      this.ItemsPerPageRange[1] = (this.ItemsPerPageRange[0] + this.pagination.length);
    }
    //if (p_boolRememberFilter === false)
    //  //this.UserTrackingForFilter = [] as any;
  }


  public ISCScholarGetRecordForDuplicateSearchByEmail() {
    this.NeedToShowDuplicateError = false;
    let data = {} as any;
    data.ISCPersonEmailID = this.ISCScholarObject.EmailAddress;
    this.http.post(this.baseUrl + 'api/ISCPerson/ISCGetPersonDuplicateRecordsByEmailID', data).subscribe(result => {
      var l_arrResponse = result[0] as any;
      var l_arrResponseID = result[2] as any;
      this.DuplicateRecordData = l_arrResponse;
      this.DuplicateRecordDataID = l_arrResponseID[0].ScholarID;
      if (this.DuplicateRecordData.length > 0 && this.DuplicateRecordDataID > 0) {
        if (this.ISCScholarObject.ISCScholarID == this.DuplicateRecordDataID && this.DuplicateRecordData[0].EmailAddress == this.SaveEmailIDTemp) {
          this.NeedToShowDuplicateError = false;
        } else {
          document.getElementById("DuplicateModalButton").click();
          this.NeedToShowDuplicateError = true;
        }
      }
    }, error => console.error(error));
  }

  public SaveScholarDate(p_oevent, p_Date) {
    var Value = p_oevent.target.value;
    if (p_Date == 'DateOfBirth')
      this.ISCScholarObject.DateOfBirth = Value;
  }
}
