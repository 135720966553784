import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule, UrlSerializer } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploaderCustom } from './SharedComponents/FileUploader';
import { SortJsonData } from './Pipes/SortingPipe';
import { FilterJsonData } from './Pipes/FilterPipe';
import { DefaultTS } from './SharedComponents/Default';
import { RoutingState } from './SharedComponents/RoutingState';
import { LowerCaseUrlSerializer } from './SharedComponents/URLSerializer';
import { Timer } from '../app/SharedComponents/Timer';
import { Scholar } from './Scholar/Scholar';
import { Case } from './Case/Case';
import { Client } from './Client/Client';
import { CalendarModule } from './Calendar/CalendarMain/Calendar.module';
import { Messaging } from './Communication/Messaging';
import { PaginationModule, PopoverModule } from 'ngx-bootstrap';
import { ContextMenuModule } from 'ngx-contextmenu';
import { CKEditorModule } from 'ngx-ckeditor';
import { CalendarUtility } from './Calendar/CalendarUtility/CalendarUtility';
import { PTDragDropUpload } from './PTDragDropUpload/PTDragDropUpload';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PaginationModule,
    ContextMenuModule,
    CKEditorModule,
    PopoverModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,


  ],
  declarations: [
    FileUploaderCustom,
    SortJsonData,
    DefaultTS,
    Timer,
    Messaging,
    FilterJsonData,
    CalendarUtility,
    PTDragDropUpload,
    Scholar, Case, Client
  ],
  exports: [
    RouterModule,
    FileUploaderCustom,
    SortJsonData,
    DefaultTS,
    Timer,
    CalendarModule,
    PTDragDropUpload,
    Messaging,    
    Scholar, Case, Client,
    FilterJsonData,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
})


export class SharedDataModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedDataModule,
      providers: [FileUploaderCustom, SortJsonData, FilterJsonData, DefaultTS, Timer, RoutingState, DatePipe, Messaging, PaginationModule, ContextMenuModule, PopoverModule, CKEditorModule, PTDragDropUpload,
        {
          provide: UrlSerializer,
          useClass: LowerCaseUrlSerializer
        }]
  }
    };

}
