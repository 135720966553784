import { Router, NavigationEnd } from '@angular/router';
//import { filter } from 'rxjs/operators/filter';
import { Injectable } from '@angular/core';

@Injectable()
export class RoutingState {
  private history = [];

  previousUrl: string = 'none';
  constructor(private router: Router) {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.previousUrl = event.url;
        }
      });
  }

  //public loadRouting(): void {
  //  this.router.events
  //    .pipe(filter(event => event instanceof NavigationEnd))
  //    .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
  //      this.history = [...this.history, urlAfterRedirects];
  //    });
  //}

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/index';
  }
}
