import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    var l_strBase = window.location.pathname;
    var l_strSearch = window.location.search;
    l_strBase = l_strBase.toLowerCase();
    url = l_strBase + l_strSearch;
    return super.parse(url);
  }
}
