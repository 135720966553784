import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked, SimpleChanges } from '@angular/core';

import { ShowCalendarModal } from '../CalendarModal/ShowCalendarModal';
import { CalendarUtility } from '../CalendarUtility/CalendarUtility';

@Component({
  selector: 'WeekView',
  templateUrl: './WeekView.html',
  styleUrls: ['./WeekView.css']
})

export class WeekView implements OnInit, AfterViewChecked{
  public IsEventsDataBinded = false;
  ngAfterViewChecked(): void {
    if (!this.IsEventsDataBinded) {
      this.IsEventsDataBinded = !this.IsEventsDataBinded;
      this.scrollWeek();
    
    }
  }
  @ViewChild(ShowCalendarModal, { static: false }) Modal: ShowCalendarModal;

  /* Currently Selected Date */
  @Input() public m_oSelectedDate;

  /* calendarUtility date to load data for page */
  @Input() public m_oCurrentPage;

  /* m_oToday's date */
  @Input() public m_oToday;

  /* Array with all the Events data */
  @Input() public m_arrEvents: any[] = [];

  /* Array with all the Events data */

  //@Input() public AMTRunningOrder: any[] = [];
  @Input() public JsonDataForEvents = [];
  @Input() public WeekendDays: any[] = [];
  @Input() public PublicHolidays: any[] = [];

  @Input() public EventsData: any[] = [];

  /** Emits the new event, which calls the function in parent component (MainCalendar.ts) */
  @Output() dayClicked: EventEmitter<any> = new EventEmitter();

  /* To display weekdays name in header of calendar */
  public readonly m_arrDayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public m_arrDayDates = [];
  public m_arrFormattedEvents = [];
  public m_arrHours = [];

  public AvailableResourcesCounts = [];
  public l_arrWeekDates = [] as any;
  public m_arrAvailableResourcesCount = [];
  public m_arrNotAvailableResourcesCount = [];
  public m_arrResourcesCount = [];
  public CalculatedDataFromDates = [];

  constructor() {
   // CalendarUtility.CheckIsUserLoggedIn();
    this.AvailableResourcesCounts = [];
    this.GenerateHours();
  }

  ngOnInit() {
    this.m_oCurrentPage = this.m_oCurrentPage;
    this.ChangeWeek(0);
  }
  ngOnChanges(changes: SimpleChanges) {
    this.AvailableResourcesCounts = [];
    /* Fill Data in calendar according to date */
    this.ChangeWeek(0);
  }
  public SetToday() {
    this.m_oCurrentPage = { ...this.m_oToday };
    this.ChangeWeek(0);
  }

/** Returns some class, if there is any event! */
  public Events = [] as any;
  public IsEventPresent(p_intDate): string {

    /* Check if date is selected */
    for (var i = 0; i < this.Events.length; i++) {
      var l_oDateOfEvent = new Date(this.Events[i].Date);
      var l_intDay = l_oDateOfEvent.getDate();
      var l_intMonth = l_oDateOfEvent.getMonth();
      var l_intYear = l_oDateOfEvent.getFullYear();

      if (p_intDate == l_intDay && l_intMonth == this.m_oCurrentPage.month && l_intYear == this.m_oCurrentPage.year)
        return 'someClass';

    }
    /* No match found */
    return '';
  }

  public ChangeWeek(p_intAddWeek) {
    
    var l_oDate = new Date(this.m_oCurrentPage.year, this.m_oCurrentPage.month, this.m_oCurrentPage.date);
    l_oDate.setDate(l_oDate.getDate() + p_intAddWeek);

    this.m_oCurrentPage.date = l_oDate.getDate();
    this.m_oCurrentPage.month = l_oDate.getMonth();
    this.m_oCurrentPage.year = l_oDate.getFullYear();
  
    var l_intWeekDay = l_oDate.getDay();
    l_oDate.setDate(l_oDate.getDate() - l_intWeekDay);

    for (var i = 0; i < 7; i++) {
      var l_strDate = l_oDate.toString();
      this.m_arrDayDates[i] = ({ DateName: l_strDate, Date: l_oDate.getDate() });
      l_oDate.setDate(l_oDate.getDate() + 1);
    }
    this.GenerateDatesForWeek();
    //this.dayClicked.emit(this.m_oCurrentPage);
    this.IsEventsDataBinded = false;
  }

  public TodayClicked(p_intDay) {

    this.m_oSelectedDate.date = p_intDay;
    this.m_oSelectedDate.month = this.m_oCurrentPage.month;
    this.m_oSelectedDate.year = this.m_oCurrentPage.year;

  }
  
  public GetWeekNumber(p_oDate) {
    var l_oFirstJan = new Date(p_oDate.year, 0, 1) as any;
    var l_oCurrentDate = new Date(p_oDate.year, p_oDate.month, p_oDate.date) as any;

    var l_intMillisecsInDay = 86400000;
    var l_intWeekNumber = Math.ceil((((l_oCurrentDate - l_oFirstJan) / l_intMillisecsInDay) + l_oFirstJan.getDay() + 1) / 7);

    return l_intWeekNumber;
  }

  public GenerateHours() {
    var l_intStartTime = '0' as any;
    for (var i = 0; i < 24; i++) {

      if (i < 12) {
        if (i < 10)
          this.m_arrHours[i] = { Hour: '0' + l_intStartTime + ' : 00', Text: '', id: l_intStartTime, StartMinutes: 0, Duration: 0, StartTime: 0, EndTime: 0 };
        else
          this.m_arrHours[i] = { Hour: l_intStartTime + ' : 00', Text: '', id: l_intStartTime, StartMinutes: 0, Duration: 0, StartTime: 0, EndTime: 0 };
      }
      else
        this.m_arrHours[i] = { Hour: l_intStartTime + ' : 00', Text: '', id: l_intStartTime, StartMinutes: 0, Duration: 0, StartTime: 0, EndTime: 0 };
      l_intStartTime = parseInt(l_intStartTime) + 1;
    }
  }

  public scrollWeek() {
    document.getElementById('tablescroll').scrollTop = 360;
  }

  public GenerateDatesForWeek() {
   
    var l_arrCalendarData = this.m_arrDayDates;

    var l_intCount = 0;

    for (var i = 0; i < l_arrCalendarData.length; i++) {
      this.l_arrWeekDates[l_intCount] = new Date(this.m_oCurrentPage.year, this.m_oCurrentPage.month, l_arrCalendarData[i].Date);
      l_intCount++;
    }
    this.m_arrEvents = [];
    this.RemovePreviouslyBindedEventsBoxesFromJson();
    this.CalculatedDataFromDates = [];
    this.BindEventsFromJSON(this.EventsData);

  }

    public BindEventsFromJSON(p_arrGridData) {
     
    this.Index = -1;
    var l_arrEvents = this.CalculatedDataFromDates
    var l_arrEventsForJson = this.m_arrEvents;
    
    var l_intCount = 0;
    if (l_arrEventsForJson.length > 0)
      l_intCount = l_arrEventsForJson.length;

    this.JsonDataForEvents = p_arrGridData;
    for (var k = 0; k < this.m_arrDayDates.length; k++) {
      for (var i = 0; i < this.m_arrHours.length; i++) {
        var l_boolMatched = false;
          var l_strText = 'Some text to display';
          var l_strTitle = '';
        var l_strTooltipText = '';
        var l_intStartMinutesJson = 0;
        var l_intDurationMinutes = 0;
        var l_strStartTime = '';
        var l_strEndTime = '';
        var l_intCounter = 0;
        var l_oCurrentPageDate = new Date(this.m_arrDayDates[k].DateName);
        var l_intCurrentYear = l_oCurrentPageDate.getFullYear();
        var l_intCurrentMonth = l_oCurrentPageDate.getMonth();

        for (var j = 0; j < this.JsonDataForEvents.length; j++) {
          if (this.JsonDataForEvents[j].StartTime != ' ') {
            var l_strStartDateInDB = this.JsonDataForEvents[j].StartTime;
            var l_strEndDateInDB = this.JsonDataForEvents[j].EndTime;
            var l_boolIsPersonalLeave = false;
            if (this.JsonDataForEvents[j].PersonalLeave)
              l_boolIsPersonalLeave = true;
            var l_strURL = 'events';

            if (this.JsonDataForEvents[j].URL)
                  l_strURL = this.JsonDataForEvents[j].URL;

              var l_intSelectedEventDBId = 0;
              if (this.JsonDataForEvents[j].ID)
                  l_intSelectedEventDBId = this.JsonDataForEvents[j].ID;
            
            var l_strColor = '#A3E1D4';
            if (this.JsonDataForEvents[j].Color)
              l_strColor = this.JsonDataForEvents[j].Color;
            
            var l_boolAllDay = false as any;
            if (this.JsonDataForEvents[j].AllDay)
              l_boolAllDay = this.JsonDataForEvents[j].AllDay;
            var l_oDate = new Date(l_strStartDateInDB) as any;
            if (l_oDate.toString() != 'Invalid Date') {
              var l_intDay = l_oDate.getDate();
              var l_intMonth = l_oDate.getMonth();
              var l_intYear = l_oDate.getFullYear();
              var l_intStartHour = l_oDate.getHours();
              var l_intStartMinutes = l_oDate.getMinutes();

              l_strEndDateInDB = new Date(l_strEndDateInDB) as any;
              var l_intEndDay = l_strEndDateInDB.getDate();
              var l_intEndMonth = l_strEndDateInDB.getMonth();
              var l_intEndYear = l_strEndDateInDB.getFullYear();
              var l_intEndHour = l_strEndDateInDB.getHours();
              var l_intEndMinutes = l_strEndDateInDB.getMinutes();

              if (l_intCurrentYear == l_intYear && l_intCurrentMonth == l_intMonth && this.m_arrDayDates[k].Date == l_intDay) {
                l_intCounter++;
                var l_intHour = this.m_arrHours[i].id;
                l_intHour = parseInt(l_intHour);
                if (l_intStartHour == l_intHour) {
                  if (l_boolAllDay == 1 || l_boolAllDay == true) {
                    l_intStartHour = 0;
                    l_intStartMinutes = 0;
                    l_intHour = 0;
                    l_intEndHour = 23;
                    l_intEndMinutes = 59;
                  }
                  l_boolMatched = true;
                  l_intStartMinutesJson = l_intStartMinutes;
                  if (this.m_arrDayDates[k].Date == l_intEndDay)
                    l_intDurationMinutes = (Math.abs((l_intEndHour - l_intStartHour)) * 60) + ((l_intEndMinutes - l_intStartMinutes));
                  else
                    l_intDurationMinutes = (Math.abs((23 - l_intStartHour)) * 60) + ((60 - l_intStartMinutes));

                  if (this.JsonDataForEvents[j].Title)
                    l_strTitle = this.JsonDataForEvents[j].Title;
                  if (this.JsonDataForEvents[j].Text)
                      l_strText = this.JsonDataForEvents[j].Text;
                  else
                    l_strText = 'Some text to display.';
                  if (this.JsonDataForEvents[j].Tooltip)
                    l_strTooltipText = this.JsonDataForEvents[j].Tooltip;
                  else
                    l_strTooltipText = 'Tooltip data';

                  if (l_intStartHour < 10)
                    l_intStartHour = 0 + '' + l_intStartHour;
                  if (l_intStartMinutes < 10)
                    l_intStartMinutes = 0 + '' + l_intStartMinutes;
                  if (l_intEndHour < 10)
                    l_intEndHour = 0 + '' + l_intEndHour;
                  if (l_intEndMinutes < 10)
                    l_intEndMinutes = 0 + '' + l_intEndMinutes;

                  if (l_boolAllDay) {
                    l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear;
                    l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear;
                  }
                  else {
                    l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear + ', ' + l_intStartHour + ':' + l_intStartMinutes;
                    l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear + ', ' + l_intEndHour + ':' + l_intEndMinutes;
                  }

                  var l_strHour = l_intHour;
                  var l_strID = l_intHour + this.m_arrDayNames[k];
                  
                  var l_intIndex = CalendarUtility.GetIndexExactlyMatchingColumn(l_arrEvents, 'id', l_strID);
                  if (l_intIndex > -1) {
                    l_arrEvents[l_intIndex].Count++;
                    l_intCounter = l_arrEvents[l_intIndex].Count;
                  }

                    // MR
                    l_arrEvents[l_intCount] = { id: l_strID, id2: l_strID + '' + l_intCounter, Hour: l_strHour, Title: l_strTitle, Text: l_strText, Tooltip: l_strTooltipText, StartMinutes: l_intStartMinutesJson, Duration: l_intDurationMinutes, StartTime: l_strStartTime, EndTime: l_strEndTime, Count: l_intCounter, Color: l_strColor, URL: l_strURL, PersonalLeave: l_boolIsPersonalLeave, AllDay: l_boolAllDay, SelectedID: l_intSelectedEventDBId };
                    l_arrEventsForJson[l_intCount] = l_arrEvents[l_intCount];

                  //l_arrEvents[l_intCount] = { id: l_strID, id2: l_strID + '' + l_intCounter, Hour: l_strHour, Text: l_strText, Tooltip: l_strTooltipText, StartMinutes: l_intStartMinutesJson, Duration: l_intDurationMinutes, StartTime: l_strStartTime, EndTime: l_strEndTime, Count: l_intCounter, Color: l_strColor, URL: l_strURL, PersonalLeave: l_boolIsPersonalLeave };
                  //l_arrEventsForJson[l_intCount] = { id: l_strID, id2: l_strID + '' + l_intCounter, StartTime: l_strStartTime, EndTime: l_strEndTime, Text: l_strText, URL: l_strURL };
                  l_intCount++;
                }
              }
              else if (l_intCurrentYear == l_intEndYear && l_intCurrentMonth == l_intEndMonth && this.m_arrDayDates[k].Date == l_intEndDay) {
                l_intCounter++;
                var l_intHour = this.m_arrHours[i].id;
                l_intHour = parseInt(l_intHour);
                if (l_intEndHour == l_intHour) {
                  if (l_boolAllDay == 1 || l_boolAllDay == true) {
                    l_intEndHour = 23;
                    l_intEndMinutes = 59;
                  }
                  l_boolMatched = true;
                  l_intStartMinutesJson = l_intStartMinutes;
                  l_intDurationMinutes = (Math.abs((l_intEndHour - 0)) * 60) + ((l_intEndMinutes - 0));

                  if (this.JsonDataForEvents[j].Title)
                    l_strTitle = this.JsonDataForEvents[j].Title;
                  if (this.JsonDataForEvents[j].Text)
                      l_strText = this.JsonDataForEvents[j].Text;
                  else
                    l_strText = 'Some text to display.';
                  if (this.JsonDataForEvents[j].Tooltip)
                    l_strTooltipText = this.JsonDataForEvents[j].Tooltip;
                  else
                    l_strTooltipText = 'Tooltip data';

                  if (l_intStartHour < 10)
                    l_intStartHour = 0 + '' + l_intStartHour;
                  if (l_intStartMinutes < 10)
                    l_intStartMinutes = 0 + '' + l_intStartMinutes;
                  if (l_intEndHour < 10)
                    l_intEndHour = 0 + '' + l_intEndHour;
                  if (l_intEndMinutes < 10)
                    l_intEndMinutes = 0 + '' + l_intEndMinutes;

                  if (l_boolAllDay) {
                    l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear;
                    l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear;
                  }
                  else {
                    l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear + ', ' + l_intStartHour + ':' + l_intStartMinutes;
                    l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear + ', ' + l_intEndHour + ':' + l_intEndMinutes;
                  }

                  var l_strHour = 0 as any;
                  var l_strID = 0 + '' + this.m_arrDayNames[k];

                  var l_intIndex = CalendarUtility.GetIndexExactlyMatchingColumn(l_arrEvents, 'id', l_strID);
                  if (l_intIndex > -1) {
                    l_arrEvents[l_intIndex].Count++;
                    l_intCounter = l_arrEvents[l_intIndex].Count;
                    }

                    l_arrEvents[l_intCount] = { id: l_strID, id2: l_strID + '' + l_intCounter, Hour: l_strHour, Title: l_strTitle,Text: l_strText, Tooltip: l_strTooltipText, StartMinutes: l_intStartMinutesJson, Duration: l_intDurationMinutes, StartTime: l_strStartTime, EndTime: l_strEndTime, Count: l_intCounter, Color: l_strColor, URL: l_strURL, PersonalLeave: l_boolIsPersonalLeave, AllDay: l_boolAllDay, SelectedID: l_intSelectedEventDBId };
                    // MR
                    //l_arrEventsForJson[l_intCount] = { id: l_strID, id2: l_strID + '' + l_intCounter, StartTime: l_strStartTime, EndTime: l_strEndTime, Text: l_strText, URL: l_strURL };
                    l_arrEventsForJson[l_intCount] = l_arrEvents[l_intCount];


                  //l_arrEvents[l_intCount] = { id: l_strID, id2: l_strID + '' + l_intCounter, Hour: l_strHour, Text: l_strText, Tooltip: l_strTooltipText, StartMinutes: l_intStartMinutesJson, Duration: l_intDurationMinutes, StartTime: l_strStartTime, EndTime: l_strEndTime, Count: l_intCounter, Color: l_strColor, URL: l_strURL, PersonalLeave: l_boolIsPersonalLeave };
                  //l_arrEventsForJson[l_intCount] = { id: l_strID, id2: l_strID + '' + l_intCounter, StartTime: l_strStartTime, EndTime: l_strEndTime, Text: l_strText, URL: l_strURL };
                  l_intCount++;
                }
              }
            
            }
          }
        }
      }
    }
    //this.CalculatedDataFromDates = this.CalculatedDataFromDates.concat(l_arrEvents);
    //this.m_arrEvents = this.m_arrEvents.concat(l_arrEventsForJson);
    this.CalculatedDataFromDates = l_arrEvents;
    this.m_arrEvents = l_arrEventsForJson;
  }
  
  public Index = -1;
    public BindEvents(p_intIndex) {
        //debugger;
    if (p_intIndex != this.Index) {
      this.Index = p_intIndex;
      this.BindEventsBoxesFromJson();
      this.BindPublicHolidays();
    }
  }

  public BindEventsBoxesFromJson() {
    var l_arrOverlappingEvents = [];
    var l_intCounter = 0;
    for (var i = 0; i < this.CalculatedDataFromDates.length; i++) {
      var l_strElementID = this.CalculatedDataFromDates[i].id;
      var l_oElement = document.getElementById('tdColumn_' + l_strElementID);
      if (l_oElement != null) {

        if (this.CalculatedDataFromDates[i].Text != '') {
          var l_strTextToDisplay = this.CalculatedDataFromDates[i].Text;
          var l_intStartMinutes = this.CalculatedDataFromDates[i].StartMinutes;
          var l_intDuration = this.CalculatedDataFromDates[i].Duration;
          var l_strStartTime = this.CalculatedDataFromDates[i].StartTime;
          var l_strEndTime = this.CalculatedDataFromDates[i].EndTime;
          var l_intHour = this.CalculatedDataFromDates[i].Hour;
          var l_strColor = this.CalculatedDataFromDates[i].Color;
            var l_boolIsPersonalLeave = this.CalculatedDataFromDates[i].PersonalLeave;
            var l_boolAllDay = this.CalculatedDataFromDates[i].AllDay;
          ////debugger;
          var divRelative = document.createElement('li');
          divRelative.setAttribute('style', 'position:relative;list-style-type:none;');
          divRelative.setAttribute('class', 'CalendarEventColor');
          var divAbsolute = document.createElement('div');
          divRelative.appendChild(divAbsolute);


          divAbsolute.style.marginTop = "-20px";
          if (l_boolIsPersonalLeave) {
            divAbsolute.style.marginTop = "-20px";
          }
          else
            divRelative.setAttribute('class', 'CalendarEventColor');

          var l_strBottomBorder = this.GetBottomBorderForJsonEventsBoxes(l_intHour, l_intDuration, l_intStartMinutes);
          if (l_strBottomBorder == '0')
            divAbsolute.setAttribute('class', 'radiusbord');
          else
            divAbsolute.setAttribute('class', 'borderradius');

          if (l_boolIsPersonalLeave) {
            divAbsolute.setAttribute('style', 'padding:2px; font-size:10px; text-align:left; line-height:12px;overflow:hidden; color:#ff6961 !important;');
          }
          else {
            divAbsolute.setAttribute('style', 'padding:2px; font-size:10px; text-align:left; line-height:12px;overflow:hidden; color:#000000;');
          }

            //MR
            divAbsolute.style.position = "absolute";
           // divAbsolute.style.zIndex = "10001";
          divAbsolute.style.width = "80px";
          divAbsolute.style.height = this.GetHeightForCellForJsonEvents(l_intHour, l_intDuration, l_intStartMinutes) + "px";
          divAbsolute.style.backgroundColor = l_strColor;
          ////debugger;
          var l_boolExisted = false;
          for (var j = 0; j < l_arrOverlappingEvents.length; j++) {
            if (l_strElementID == l_arrOverlappingEvents[j].id) {
              if (l_arrOverlappingEvents[j].Count < 10)
                divAbsolute.style.marginLeft = (l_arrOverlappingEvents[j].Count * 6) + "px";
              l_arrOverlappingEvents[j].Count = l_arrOverlappingEvents[j].Count + 1;
              l_boolExisted = true;
            }
          }
          if (!l_boolExisted) {
            l_arrOverlappingEvents[l_intCounter] = { id: l_strElementID, Count: 1 };
            l_intCounter++;
            //divAbsolute.style.marginLeft = "0px"; 
          }
          if (l_boolIsPersonalLeave) {
            divAbsolute.style.marginTop = "340px";
          }
          else {
            //divAbsolute.style.marginTop = "340px";
          }
          //Change the marginTop
          divAbsolute.style.border = "2px solid #58C7B1";

          if (l_intDuration <= 25)
            divAbsolute.setAttribute('title', l_strStartTime + ' - ' + l_strEndTime + ' -> ' + l_strTextToDisplay);
          else
            divAbsolute.setAttribute('title', l_strTextToDisplay);

            divAbsolute.style.top = ((((l_intStartMinutes-20)  / 60) * 40).toFixed(0))  + "px";
         
          if (l_intDuration > 25 && l_intDuration < 45)
            divAbsolute.innerText = l_strStartTime + ' - ' + l_strEndTime;
          else if (l_intDuration >= 45)
            divAbsolute.innerText = l_strStartTime + ' - ' + l_strEndTime + ' ' + l_strTextToDisplay;

          //divAbsolute.style.left = GetLeftAdjustment() + "px";
          var l_strElementID2 = this.CalculatedDataFromDates[i].id2;
          divAbsolute.addEventListener('click', this.ShowEventsDataFromJson.bind(this, l_strElementID2));
          l_oElement.appendChild(divRelative);
        }
      }
    }
  }
  
  public BindEventsCountsFromJson() {
    for (var i = 0; i < this.CalculatedDataFromDates.length; i++) {
      var l_strElementID = this.CalculatedDataFromDates[i].id;
      var l_oElement = document.getElementById('tdColumn_' + l_strElementID);
      if (l_oElement != null) {
        var span = document.createElement('span');
        span.innerText = this.CalculatedDataFromDates[i].Count;
        span.setAttribute('title', this.CalculatedDataFromDates[i].Tooltip);
        l_oElement.appendChild(span);
        //l_oElement.addEventListener('click', this.ShowEventsDataFromJson.bind(this));
      }
    }
  }
  
  public GetHeightForCellForJsonEvents(p_intHour, p_intDuration, p_intStartMinutes) {
    var l_intDivHeight = ((p_intDuration / 60) * 40).toFixed(0);
    var l_intMaxDuration = ((18 - 9) * 60) + (60 - p_intStartMinutes);
    var l_intMaxDivHeight = ((l_intMaxDuration / 60) * 40).toFixed(0);
    if (parseInt(l_intDivHeight) >= parseInt(l_intMaxDivHeight))
      return l_intMaxDivHeight;
    else
      return l_intDivHeight;
  }
  
  public GetHeightForCell(p_intHour, p_intDuration, p_intStartMinutes) {
    var l_intDivHeight = ((p_intDuration / 60) * 40).toFixed(0);
    var l_intMaxDuration = ((24 - p_intHour) * 60) + (60 - p_intStartMinutes);
    var l_intMaxDivHeight = ((l_intMaxDuration / 60) * 40).toFixed(0);
    if (parseInt(l_intDivHeight) > parseInt(l_intMaxDivHeight))
      return l_intMaxDivHeight;
    else
      return l_intDivHeight;
  }
  
  public GetBottomBorderForJsonEventsBoxes(p_intHour, p_intDuration, p_intStartMinutes) {
    var l_intDivHeight = ((p_intDuration / 60) * 40).toFixed(0);
    var l_intMaxDuration = ((23 - p_intHour) * 60) + (60 - p_intStartMinutes);
    var l_intMaxDivHeight = ((l_intMaxDuration / 60) * 40).toFixed(0);
    if (parseInt(l_intDivHeight) >= parseInt(l_intMaxDivHeight))
      return '0';
    else
      return '2';
  }

  public RemovePreviouslyBindedEventsBoxesFromJson() {
    for (var i = 0; i < this.CalculatedDataFromDates.length; i++) {
      var l_strElementID = this.CalculatedDataFromDates[i].id;
      var l_oElement = document.getElementById('tdColumn_' + l_strElementID);
      if (l_oElement != null)
        l_oElement.innerText = '';
    }
  }

  public BindPublicHolidays() {
    var l_arrCalendarData = this.m_arrDayDates;
  
    for (var i = 0; i < l_arrCalendarData.length; i++) {
      var l_oCurrentDate = new Date(l_arrCalendarData[i].DateName);
      var l_strDay = this.m_arrDayNames[i];
      for (var k = 0; k < this.PublicHolidays.length; k++) {
        var l_strStartDateInDB = this.PublicHolidays[k].StartDate;
        var l_strEndDateInDB = this.PublicHolidays[k].EndDate;
        var l_strTitle = this.PublicHolidays[k].Purpose;
        if (l_strStartDateInDB != '' && l_strEndDateInDB != '') {
          var l_oStartDate = new Date(l_strStartDateInDB);
          var l_oEndDate = new Date(l_strEndDateInDB);
         
        }
      }
    }
  }

//TODO: Update Data For Popup View
  public ShowEventsDataFromJson(p_strID, p_oEvent) {

    var l_strID = p_strID;

      var l_arrJsonData = CalendarUtility.FilterJsonExactlyMatching(this.m_arrEvents, 'id2', l_strID);

      /*
    var l_arrFormattedColumns = [];
    var l_arrFormattedValues = [];
    let l_intCounter = 0;
    for (let l_oRow of l_arrJsonData) {
      console.log("Row:", l_oRow);
      for (let l_oColumn in l_oRow) {
        console.log("      Column:", l_oColumn, "value:", l_oRow[l_oColumn]);

        //if (l_oColumn == 'URL') {
        //  CalendarUtility.AppointmentID = parseInt(l_oRow[l_oColumn], 10);
        //}

        if (l_oColumn.indexOf('id') < 0 && l_oColumn.indexOf('Color') < 0 && l_oColumn != 'PersonalLeave' && ((l_oColumn == 'Initials') || l_oColumn != 'Initials')) {
          var l_strColumnName = l_oColumn;

          if (l_strColumnName == 'Text')
            l_strColumnName = 'Details';

          l_arrFormattedColumns[l_intCounter] = l_strColumnName;
          l_arrFormattedValues[l_intCounter] = l_oRow[l_oColumn];
          this.m_arrFormattedEvents[l_intCounter] = l_strColumnName + ': ' + l_oRow[l_oColumn]; // + " " + Math.random() * 100 + 100;
          l_intCounter++;
        }

      }
      }

    //MR 
    //CalendarUtility.isAppointmentEdit = true;
    */

      if (this.Modal) {

          this.Modal.FormattedValues = l_arrJsonData;
          this.Modal.m_arrFormattedEvents = [];
        //this.Modal.FormattedColumns = l_arrFormattedColumns;
        //this.Modal.FormattedValues = l_arrFormattedValues;
        //this.Modal.m_arrFormattedEvents = this.m_arrFormattedEvents;
        this.Modal.ShowFormattedJsonData();
    }
  }


  /** Gets the DaysPerMonth array, returns value of index corresponding to month number */
  public GetDaysOfMonth(p_intMonth: number, p_intYear: number): number {

    /* Check leap years if February */
    if (p_intMonth == 1 && this.IsLeapYear(p_intYear)) {
      return 29;
    }

    /** Return the number of days, corresponding to that month */
    return [31, 28, 31, 30, 31, 30,
      31, 31, 30, 31, 30, 31][p_intMonth];
  }

  /** Returns true if leap year */
  public IsLeapYear(p_intYear): boolean {
    return ((p_intYear % 4 == 0) && (p_intYear % 100 != 0)) || (p_intYear % 400 == 0);
  }
  
}
