/*eslint-disable*/
import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { DefaultTS } from '../SharedComponents/Default';


@Injectable()

@Component({
  selector: 'FileUploader',
  templateUrl: './FileUploader.html'
})
export class FileUploaderCustom {
  //@ViewChild("fileInput") fileInput;
  @ViewChild("fileInput", { static: false }) fileInput;
  public http: HttpClient;
  public baseUrl: string;
  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private zone: NgZone, public Default: DefaultTS,) {
    this.http = http;
    this.baseUrl = baseUrl;
  }
  public AddFile() {
    this.AddFileInQueueForUploading(this.fileInput);
  }
  public async AddFileInQueueForUploading(InputElement) {
    let SelectedFiles = InputElement.nativeElement;
    if (SelectedFiles.files && SelectedFiles.files[0]) {
      let fileToUpload = SelectedFiles.files[0];
      await this.SendRequestForUploadingToServer(fileToUpload, fileToUpload.name, 1).then((response) => {
        // debugger;
        console.log(response);
      });
      //.subscribe(res => {
      //  debugger;
      //  console.log(res);
      //});
    }
  }

  public async AddFileInQueueForUploadingV2(InputElement, Name) {
    let SelectedFiles = InputElement.nativeElement;
    if (SelectedFiles.files && SelectedFiles.files[0]) {
      let fileToUpload = SelectedFiles.files[0];

      await this.SendRequestForUploadingToServer(fileToUpload, Name, 1).then((response) => {
        if (response != undefined && response.length > 0) {
          if (response[0].Status != 1) {
            // this.Default.ShowAlert(response[0].Message);
            this.Default.ShowAlert(response[0].Message);
          }
          this.Default.FileUploaderStatus = response[0].Status;
          console.log(response[0].Message);
        }
      });
    }
  }

  public async AddFileInQueueForUploadingV3(InputElement, Name, UploadType) {// UploadType=1 means images and files, UploadType=2 means Images only, UploadType=3 means files only
    let SelectedFiles = InputElement.nativeElement;
    if (SelectedFiles.files && SelectedFiles.files[0]) {
      let fileToUpload = SelectedFiles.files[0];

      await this.SendRequestForUploadingToServer(fileToUpload, Name, UploadType).then((response) => {
        if (response != undefined && response.length > 0) {
          if (response[0].Status != 1) {
            //  this.Default.ShowAlert(response[0].Message);
            this.Default.ShowAlert(response[0].Message);
          }
          this.Default.FileUploaderStatus = response[0].Status;
          console.log(response[0].Message);
        }
      });
    }
  }

  public async AddFileInQueueForUploadingV3DragDrop(fileToUpload, Name, UploadType) {// UploadType=1 means images and files, UploadType=2 means Images only, UploadType=3 means files only

    this.Default.SetMasterAnimation(true);
    await this.SendRequestForUploadingToServer(fileToUpload, Name, UploadType).then((response) => {
      if (response != undefined && response.length > 0) {
        if (response[0].Status != 1) {
          //  this.Default.ShowAlert(response[0].Message);
          this.Default.ShowAlert(response[0].Message);
          console.log('Error: ' + response[0].Exception);
        }
        this.Default.FileUploaderStatus = response[0].Status;
        this.Default.SetMasterAnimation(false);
        console.log(response[0].Message);
        console.log('Error: ' + response[0].Exception);
      }
    });

  }

  public handlePromiseError(error: Response): Promise<any> {
    // console.error(error);
    throw (error);
  }

  public SendRequestForUploadingToServer(fileToUpload: any, FileName, UploadType): Promise<any> {
    let input = new FormData();
    input.append("file", fileToUpload, FileName);
    input.append('UploadType', UploadType);

    return this.http.post(this.baseUrl + "api/FileUploader/UploadFile", input).pipe(map((response: Response) => response as any))
      .toPromise()
      .catch(this.handlePromiseError);
  }
}
