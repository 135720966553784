import { Component, Inject } from '@angular/core';

import { Router } from '@angular/router';
import { ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common'

import { element } from 'protractor';
import { HttpParams } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'Default',
  templateUrl: './Default.html'
})
export class DefaultTS {
  public Message = '';
  public IsUserLoggedIn = false;
  public IsUserIsAdmin = false;
  public IsUserIsStaff = false;
  public IsUserIsScholar = false;
  public SiteUserID = 0;
  public AddClientFromCaseScreen = false;
  public AddScholarFromAppointmentScreen = false;
  public AddCaseFromAppointmentScreen = false;
  public UserTracking: any = {} as any;
  public http: HttpClient;
  public baseUrl: string;
  public minDate = new Date('0001-01-01T00:00:00Z');
  public FileUploaderStatus = 0;
  public static Role: string;
  public UserName = '';
  public EditedClientID = 0;


    public PageURL: string;
  //constructor(public datepipe: DatePipe) {
  //  //this.GetPageURL();
  //}
    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, public datepipe: DatePipe,private router: Router) {
    this.http = http;
    this.baseUrl = baseUrl;
  }
  GetPageURL() {
    var URL = window.location.href;
    var res = URL.split("/");
    this.PageURL = res[(res.length - 1)];
    if (this.PageURL == '' || this.PageURL == 'login') {
      this.IsUserLoggedIn = false;
    }
  }

  public ConvertServerDateToLocalDate(p_oDate) {

    var l_oDate = new Date(p_oDate); // val is in UTC
    var localOffset = l_oDate.getTimezoneOffset() * 60000;
    var localTime = l_oDate.getTime() - localOffset;

    l_oDate.setTime(localTime);
    return l_oDate.toLocaleString();
    //return new Date(Date.UTC(p_oDate.getFullYear(), p_oDate.getMonth(), p_oDate.getDate(), p_oDate.getHours(), p_oDate.getMinutes(), p_oDate.getSeconds())).toLocaleString();

    //var l_oNewDate = new Date(p_oDate.getTime() + p_oDate.getTimezoneOffset() * 60 * 1000);

    //var l_intOffset = p_oDate.getTimezoneOffset() / 60;
    //var l_intHours = p_oDate.getHours();
    //l_oNewDate.setHours(l_intHours - l_intOffset);

    //return l_oNewDate.toLocaleString();
  }
  public ConvertServerDateToLocalDateString(p_oDate) {
    var l_oDate = new Date(p_oDate); // val is in UTC
    var localOffset = l_oDate.getTimezoneOffset() * 60000;
    var localTime = l_oDate.getTime() - localOffset;

    l_oDate.setTime(localTime);
    return l_oDate.toLocaleDateString();
    //return new Date(Date.UTC(p_oDate.getFullYear(), p_oDate.getMonth(), p_oDate.getDate(), p_oDate.getHours(), p_oDate.getMinutes(), p_oDate.getSeconds())).toLocaleDateString();

  }
  public ConvertServerTimeZoneToLocalTimeZone(p_oDate) {
    var l_oDate = new Date(p_oDate); // val is in UTC
    var localOffset = l_oDate.getTimezoneOffset() * 60000;
    var localTime = l_oDate.getTime() - localOffset;

    l_oDate.setTime(localTime);
    return l_oDate;
  }

  //public ConvertServerTimeZoneToLocalTimeZoneUsingJson(p_oGridData, p_strColumnName) {

  //  var l_oArrayOfUniqueValues = [];
  //  var l_intCount = 0;
  //  debugger;
  //  for (var i = 0; i < p_oGridData.length; i++) {
  //    l_oArrayOfUniqueValues[l_intCount] = p_oGridData[l_intCount];

  //    var l_strValue = l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')];
  //    if (l_strValue != ' ' && l_strValue != '' && l_strValue != null) {

  //      var l_oDate = new Date(l_strValue); // val is in UTC
  //      if (new Date(l_oDate)) {
  //        var localOffset = l_oDate.getTimezoneOffset() * 60000;
  //        var localTime = l_oDate.getTime() - localOffset;

  //        l_oDate.setTime(localTime);
  //        l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')] = l_oDate;
  //      }
  //    }
  //    l_intCount++;
  //  }
  //  return l_oArrayOfUniqueValues;
  //}

  //public ConvertUSATimeZoneToLocalTimeZoneUsingJson(p_oGridData, p_strColumnName) {

  //  var l_oArrayOfUniqueValues = [];
  //  var l_intCount = 0;
  //  debugger;
  //  for (var i = 0; i < p_oGridData.length; i++) {
  //    l_oArrayOfUniqueValues[l_intCount] =  p_oGridData[l_intCount];

  //    var l_strValue = l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')];
  //    if (l_strValue != ' ' && l_strValue != '' && l_strValue != null) {

  //      var l_oDate = new Date(l_strValue); // val is in UTC
  //      if (new Date(l_oDate)) {
  //        var localOffset = l_oDate.getTimezoneOffset() * 60000;
  //        var localTime = l_oDate.getTime() - localOffset;

  //        l_oDate.setTime(localTime);
  //        l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')] = l_oDate;
  //      }
  //    }
  //    l_intCount++;
  //  }
  //  return l_oArrayOfUniqueValues;
  //}

  public getTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  //MM:: Get Unique Records Against The Column from OverAll Data
  public GetUniqueJsonRecordsAgainstNthColumn(p_oGridData, p_strColumnName) {

    var lookup = {};
    var l_oArrayOfUniqueValues = [];
    for (var item, i = 0; item = p_oGridData[i++];) {
      var name = item[eval('p_strColumnName')];

      if (!(name in lookup)) {
        lookup[name] = 1;
        l_oArrayOfUniqueValues.push(item);
      }
    }
    return l_oArrayOfUniqueValues;
  }

  //MM:: Get Unique Records Against The Column from OverAll Data
  public GetUniqueRecordsAgainstNthColumn(p_oGridData, p_strColumnName) {

    var lookup = {};
    var l_oArrayOfUniqueValues = [];
    for (var item, i = 0; item = p_oGridData[i++];) {
      var name = item[eval('p_strColumnName')];

      if (!(name in lookup)) {
        lookup[name] = 1;
        l_oArrayOfUniqueValues.push(name);
      }
    }
    return l_oArrayOfUniqueValues.sort();
  }

  //MM:: Get Unique Records Against The Column from OverAll Data
  public GetUniqueRecordsAgainstNthColumnWithoutSort(p_oGridData, p_strColumnName) {

    var lookup = {};
    var l_oArrayOfUniqueValues = [];
    for (var item, i = 0; item = p_oGridData[i++];) {
      var name = item[eval('p_strColumnName')];

      if (!(name in lookup)) {
        lookup[name] = 1;
        l_oArrayOfUniqueValues.push(name);
      }
    }
    return l_oArrayOfUniqueValues;
  }

  //MM:: Get Unique Records Against The Column from OverAll Data
  public GetUniqueValuesAgainstNthColumn(p_oGridData, p_strColumnName) {

    var lookup = {};
    var l_oArrayOfUniqueValues = [];
    for (var item, i = 0; item = p_oGridData[i++];) {
      var name = item[eval('p_strColumnName')];

      if (!(name in lookup)) {
        lookup[name] = 1;
        l_oArrayOfUniqueValues.push(item);
      }
    }
    return l_oArrayOfUniqueValues;
  }

  public FilterJsonExactlyMatching(p_oGridData, p_oColumn, p_strValue) {
    var l_arrFilteredData = [];
    var count = 0;
    if (p_oGridData) {
      for (var i = 0; i < p_oGridData.length; i++) {
        if (p_oGridData[i][p_oColumn] == p_strValue) {
          l_arrFilteredData[count] = p_oGridData[i];
          count++;
        }
      }
    }
    return l_arrFilteredData;
  }

  public FilterJsonExactlyMatchingDates(p_oGridData, p_oColumn, p_strValue) {
    var l_arrFilteredData = [];
    var count = 0;
    for (var i = 0; i < p_oGridData.length; i++) {
      if (p_strValue != '' && p_strValue != null && p_strValue != ' ') {
        if (p_oGridData[i][p_oColumn] != ' ') {
          var l_oEnteredDate = new Date(p_strValue);
          var l_oDateInDatabase = new Date(p_oGridData[i][p_oColumn]);
          l_oEnteredDate.setHours(0, 0, 0, 0);
          l_oDateInDatabase.setHours(0, 0, 0, 0);
          if (l_oEnteredDate.getTime() == l_oDateInDatabase.getTime()) {
            l_arrFilteredData[count] = p_oGridData[i];
            count++;
          }
        }
      }
    }
    return l_arrFilteredData;
  }
  public FormatDateInUKFormat(p_oDate) {
    return this.datepipe.transform(p_oDate, 'dd/MM/yyyy');
  }

  public FormatDateInUKFormatUsingJson(p_oGridData, p_strColumnName) {
    var l_oArrayOfUniqueValues = [];
    var l_intCount = 0;
    if (p_oGridData) {
      //debugger;
      for (var i = 0; i < p_oGridData.length; i++) {
        l_oArrayOfUniqueValues[l_intCount] = p_oGridData[l_intCount];
        var l_strValue = l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')];
        if (l_strValue.toString().indexOf('1900') > -1) {
          l_strValue = '';
          l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')] = l_strValue;
        }
        if (l_strValue != ' ' && l_strValue != '' && l_strValue != null) {
          var l_arrSplittedDate = l_strValue.toString().split('/');
          if (l_arrSplittedDate.length > 0) {
            var l_oDate = new Date(l_arrSplittedDate[1] + '/' + l_arrSplittedDate[0] + '/' + l_arrSplittedDate[2]); // val is in UTC
            var localOffset = l_oDate.getTimezoneOffset() * 60000;
            var localTime = l_oDate.getTime() - localOffset;

            l_oDate.setTime(localTime);
            l_oDate.setHours(0, 0, 0, 0);
            l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')] = l_oDate;
          }
          else
            l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')] = l_strValue;
        }
        l_intCount++;
      }
    }
    return l_oArrayOfUniqueValues;
  }
  //  AR  Show/ Hide a dive/////

  public PTSetVisibilty(p_strDivID, p_boolShow) {

    var l_strVisibility = "none";
    if (p_boolShow) {

      l_strVisibility = "block";

    }
    if (document.getElementById(p_strDivID) != null)
      document.getElementById(p_strDivID).style.display = l_strVisibility;

  }

  public PTSetVisibiltyTableRow(p_strDivID, p_boolShow) {

    var l_strVisibility = "none";
    if (p_boolShow) {

      l_strVisibility = "table-row";

    }
    if (document.getElementById(p_strDivID) != null)
      document.getElementById(p_strDivID).style.display = l_strVisibility;

  }


  public PTSetVisibilityAsHiddenOrVisible(p_strDivID, p_boolShow) {

    var l_strVisibility = "hidden";
    if (p_boolShow) {

      l_strVisibility = "visible";

    }
    document.getElementById(p_strDivID).style.visibility = l_strVisibility;

  }

  public getDateForComparison(p_strDateToConvert) {
    var l_arrSplittedDate = p_strDateToConvert.split('/');
    var l_dtToReturn = new Date(l_arrSplittedDate[2], parseInt(l_arrSplittedDate[1]) - 1, l_arrSplittedDate[0]);

    return l_dtToReturn;
  }

  //  AR  Show/ Hide a dive/////

  //MM:: ConvertSlashesSeperatedStringsToDates
  public ConvertSlashesSeperatedStringsToDates(p_oGridData, p_strColumnName) {

    var l_oArrayOfUniqueValues = [];
    var l_intCount = 0;
    for (var i = 0; i < p_oGridData.length; i++) {
      l_oArrayOfUniqueValues[l_intCount] = p_oGridData[l_intCount];

      var l_strValue = l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')];
      if (l_strValue != ' ' && l_strValue != '' && l_strValue != null) {
        var l_arrSplittedData = l_strValue.split('/');

        if (l_arrSplittedData.length > 1) {
          var l_oDate = new Date(l_arrSplittedData[2], (l_arrSplittedData[1] - 1), l_arrSplittedData[0]);
          l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')] = l_oDate;
        }
      }
      l_intCount++;
    }
    return l_oArrayOfUniqueValues;
  }

  //MM:: ConvertSlashesSeperatedStringsToDates
  public AssignGridDataValuesToOtherGridData(p_oGridData) {

    var l_oArrayOfUniqueValues = [];
    var l_intCount = 0;
    if (p_oGridData != undefined) {
      for (var item, i = 0; item = p_oGridData[i++];) {
        l_oArrayOfUniqueValues[l_intCount] = p_oGridData[l_intCount];
        l_intCount++;
      }
    }
    return l_oArrayOfUniqueValues;
  }

  public GetIndexExactlyMatchingColumn(p_oGridData, p_oColumn, p_strValue) {
    var l_arrFilteredData = [];
    var index = -1;
    if (p_oGridData && p_oGridData.length > 0) {
      for (var i = 0; i < p_oGridData.length; i++) {
        if (p_oGridData[i][p_oColumn] == p_strValue) {
          index = i;
          break;
        }
      }
    }
    return index;
  }


  public RemoveSelectedChildFromList(p_oGridData, p_oColumn, p_strValue) {
    var l_arrFilteredData = [];
    var index = -1;
    for (var i = 0; i < p_oGridData.length; i++) {
      if (p_oGridData[i][p_oColumn] == p_strValue) {
        index = i;
        break;
      }
    }
    if (index > -1) {
      p_oGridData.splice(index, 1);
    }
    return p_oGridData;
  }

  public SetMasterAnimation(l_boolShow) {
    if (l_boolShow)
      document.getElementById('Animation').style.display = '';
    else
      document.getElementById('Animation').style.display = 'none';
  }
  public ShowAlert(p_strMessage) {
    //debugger;
    this.Message = p_strMessage;
    //(<HTMLButtonElement>document.getElementById('ShowModal')).click();
    document.getElementById("ShowModal").click();
    document.getElementById("myModal").style.display = 'block';
    document.getElementById("Message").innerHTML = p_strMessage;
  }
  public HideAlert() {
    document.getElementById("myModal").style.display = 'none';
  }

  public GetColumnNamesFromJson(p_oGridData) {
    var l_arrColumns = [];
    var l_intCounter = 0;
    for (let obj of p_oGridData) {
      for (let key in obj) {
        if (key.toString().toLowerCase().indexOf('id') < 0) {
          l_arrColumns[l_intCounter] = key;
          l_intCounter++;
        }
      }
      break;
    }
    return l_arrColumns;
  }

  //////////// AR: Checkboxes/RadioButtons functions starts /////////

  public GetSelectedIDsFromList(p_strID) {

    var l_intSelectedIDs = "-1";
    let l_strList = <any>document.getElementsByName(p_strID);

    for (var i = 0, length = l_strList.length; i < length; i++) {
      if (l_strList[i].checked) {

        l_intSelectedIDs = l_intSelectedIDs + "," + l_strList[i].value;

      }
    }
    l_intSelectedIDs = l_intSelectedIDs.replace("-1,", "");
    return l_intSelectedIDs;
  }

  public SelectUnSelectSpecificElement(p_strID, p_intValue, p_boolSelect) {

    let l_strList = <any>document.getElementsByName(p_strID);

    for (var i = 0, length = l_strList.length; i < length; i++) {
      if (p_intValue == l_strList[i].value) {
        l_strList[i].checked = p_boolSelect;
      }
    }
  }

  public SelectUnSelectAllElement(p_strID, p_boolChecked) {
    var l_strList = <any>document.getElementsByName(p_strID);
    for (var i = 0, length = l_strList.length; i < length; i++) {
      l_strList[i].checked = p_boolChecked;
    }
  }

  public SelectUnselectAllButOne(p_strID, p_intValue, p_boolSelect) {

    var l_strList = <any>document.getElementsByName(p_strID);

    for (var i = 0, length = l_strList.length; i < length; i++) {

      if (p_intValue == l_strList[i].value) {
        l_strList[i].checked = p_boolSelect;

      }
      else {
        l_strList[i].checked = !p_boolSelect;
      }
    }
  }

  public CheckIfSelectedAnyElement(p_strID) {
    var l_boolIsSelectedAny = false;
    var l_strList = <any>document.getElementsByName(p_strID);

    for (var i = 0, length = l_strList.length; i < length; i++) {
      if (l_strList[i].checked) {
        l_boolIsSelectedAny = true;
      }
    }
    return l_boolIsSelectedAny;
  }

  public CheckIfSelectedAllElement(p_strID) {
    var l_boolIsSelectedAll = true;
    var l_strList = <any>document.getElementsByName(p_strID);

    for (var i = 0, length = l_strList.length; i < length; i++) {
      if (l_strList[i].checked) {

      }
      else {
        l_boolIsSelectedAll = false;
      }
    }
    return l_boolIsSelectedAll;
  }


  //////////// AR: Checkboxes/RadioButtons functions ends /////////


  public GetStockPrice(p_oStockData, IsPatch) {
    var l_intPrice = -1;
    if (IsPatch) {
      if (p_oStockData != null && p_oStockData.length > 0) {
        for (var i = 0; i < p_oStockData.length; i++) {
          if (p_oStockData[i]["path"] != undefined && p_oStockData[i]["path"] != null && p_oStockData[i]["path"] == 'Last')
            l_intPrice = p_oStockData[i]["value"];
        }
      }
    }
    else {
      if (p_oStockData != null && p_oStockData.length > 0) {
        l_intPrice = p_oStockData[0]["Last"];
      }
    }
    return l_intPrice;
  }

  public GetMin_MaxValueFrom1DArray(p_arrDataValues) {
    var l_intMaxValue = Math.max.apply(Math, p_arrDataValues);
    var l_intMinValue = Math.min.apply(Math, p_arrDataValues);

    return [l_intMinValue, l_intMaxValue];
  }

  //public GetMin_MaxValueFromJsonArray(p_arrJsonValues, p_strColumnName) {
  //  var l_arrSortedDate = new SortJsonData().transform(p_arrJsonValues, eval('p_strColumnName'));
  //  var l_intMinValue = 0;
  //  var l_intMaxValue = 0;
  //  if (l_arrSortedDate.length > 0) {
  //    l_intMinValue = l_arrSortedDate[0][eval('p_strColumnName')];
  //    l_intMaxValue = l_arrSortedDate[l_arrSortedDate.length - 1][eval('p_strColumnName')];
  //  }
  //  return [l_intMinValue, l_intMaxValue];
  //}

  public IsDateBetweenTwoDates(p_oStartDate, p_oToCheckDate, p_oEndDate) {
    p_oStartDate = new Date(p_oStartDate);
    p_oStartDate = p_oStartDate.setHours(0, 0, 0, 0);
    p_oToCheckDate = new Date(p_oToCheckDate);
    p_oToCheckDate = p_oToCheckDate.setHours(0, 0, 0, 0);
    p_oEndDate = new Date(p_oEndDate);
    p_oEndDate = p_oEndDate.setHours(0, 0, 0, 0);
    if (p_oStartDate <= p_oToCheckDate && p_oToCheckDate <= p_oEndDate) {
      return true;
    }
    else
      return false;
  }


  public isValidEmail(p_strmail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(p_strmail)) {
      return (true)
    }
    return (false)
  }

  public IsValidCreditCard(p_strCreditCard) {

    if (/^\d{4}-?\d{4}-?\d{4}-?\d{4}/.test(p_strCreditCard)) {
      return (true)
    }
    return (false)
  }

  public getParamValueQueryString(paramName) {    const url = window.location.href;    let paramValue = '';    //if (url.indexOf['?'] > -1) {      const httpParams = new HttpParams({ fromString: url.split('?')[1] });      paramValue = httpParams.get(paramName);    //}    return paramValue;  }
  public UserTrackingSaveRecord() {

    let data = {} as any;

    data.SiteSessionID = 0;
    data.SiteUserID = 0;
    data.Name = '';
    data.URL = '';
    data.StartTime = '01/01/1900';
    data.EndTime = '01/01/1900';
    data.CreatedByID = 0;
    data.LastUpdatedByID = 0;
    data.IsDeleted = false;
    for (let field in data) {
      data[field] = this.UserTracking[field];
    }
    var URL = window.location.href;
    data.URL = URL;
    data.Name = this.UserName;

    this.http.post(this.baseUrl + 'api/UserTracking/UserTrackingSave', data).subscribe(result => {

    }, error => console.error(error));
  }

    //public CheckLoginUserDetails() {
    //    let data = {} as any;
    //    var l_strAddress = window.location.href;
    //    var l_strPathName = window.location.pathname;
    //    data.CompleteURL = l_strAddress;
    //    data.PageURL = l_strPathName;
    //    data.BaseURL = this.baseUrl;
    //    data.UserName = '';
    //    data.Password = '';
    //    this.http.post(this.baseUrl + 'api/SiteUser/GetUserLogin', data).subscribe(result => {
    //        //  document.getElementById('litMessage').innerText = '';
    //        var l_arrResponse = result as any;
    //        if (l_arrResponse[0].Status == 1) {
    //            DefaultTS.Role = l_arrResponse[0].Role;
    //            this.IsUserLoggedIn = true;                
    //            this.UserName = l_arrResponse[0].UserName;

    //        };
    //        if (this.PageURL != '' && this.IsUserLoggedIn == false) {
    //            if (this.PageURL != "login") {
    //                this.ShowAlert('You do not have access to this page.');
    //                this.router.navigate(['/login']);
    //            }
    //        }

    //    }, error => console.error(error));
    //}


  public CheckLoginUserDetails() {
    let data = {} as any;
    var l_strAddress = window.location.href;
    var l_strPathName = window.location.pathname;
    var IsUserAuthentic = true;
    data.CompleteURL = l_strAddress;
    data.PageURL = l_strPathName;
    data.BaseURL = this.baseUrl;
    data.UserName = '';
    data.Password = '';
    this.http.post(this.baseUrl + 'api/SiteUser/GetUserLogin', data).subscribe(result => {
      //  document.getElementById('litMessage').innerText = '';
      var l_arrResponse = result as any;
      if (l_arrResponse[0].Status == 1) {
        DefaultTS.Role = l_arrResponse[0].Role;
        this.IsUserLoggedIn = true;
        this.SiteUserID = l_arrResponse[0].SiteUserID;

        if (l_arrResponse[0].Role == "Admin") {
          this.IsUserIsAdmin = true;
          this.IsUserIsStaff = false;
          this.IsUserIsScholar = false;

          //this.router.navigate(['/dashboard']);
          //this.PageURL = "dashboard";
        }
        else if (l_arrResponse[0].Role == "Staff") {
          this.IsUserIsAdmin = false;
          this.IsUserIsStaff = true;
          this.IsUserIsScholar = false;

          //this.router.navigate(['/case']);
          //this.PageURL = "case";
        }
        else if (l_arrResponse[0].Role == "Scholar") {
          this.IsUserIsAdmin = false;
          this.IsUserIsStaff = false;
          this.IsUserIsScholar = true;

          //this.router.navigate(['/appointment']);
          //this.PageURL= "appointment";
        }
        else {
          this.IsUserIsAdmin = false;
          this.IsUserIsStaff = false;
          this.IsUserIsScholar = false;
        }
        this.UserName = l_arrResponse[0].UserName.substring(0, 100);

      };
      if (this.PageURL != '' && this.IsUserLoggedIn == false) {
        if (this.PageURL != "login" && this.PageURL != "forgotpassword" && this.PageURL.split('?')[0] != "resetpassword") {
          this.ShowAlert('You do not have permissions to access this page.');
          this.router.navigate(['/login']);
          IsUserAuthentic = false;
        }
        else if (this.PageURL.split('?')[0] == "resetpassword") {
        }
      }

      //For Scholar security
      if (this.PageURL != '' && this.IsUserLoggedIn == true) {
        if ((this.PageURL == "scholar" || this.PageURL == "client" || this.PageURL == "user" || this.PageURL == "filelocationlist" || this.PageURL == "caseprocedure") && this.IsUserIsScholar) {
          this.ShowAlert('You do not have permissions to access this page.');
          this.router.navigate(['/appointment']);
          this.PageURL = "appointment";
          IsUserAuthentic = false;
        }
      }
      //For Staff security
      if (this.PageURL != '' && this.IsUserLoggedIn == true) {
        if ((this.PageURL == "user" || this.PageURL == "filelocationlist") && this.IsUserIsStaff) {
          this.ShowAlert('You do not have permissions to access this page.');
          this.router.navigate(['/case']);
          this.PageURL = "case";
          IsUserAuthentic = false;
        }
      }
    }, error => console.error(error));
    return IsUserAuthentic;
  }

    public GenerateUniqueGUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
  }

  public GetFileIconToDisplay(FilePath) {
    var IconURL = 'Default/img/NoImage.jpg';
    var parts = FilePath.split('.');
    var ext = parts[parts.length - 1];
    switch (ext.toLowerCase()) {
      case 'jpeg':
        IconURL = FilePath;
        break;
      case 'jpg':
        IconURL = FilePath;
        break;
      case 'png':
        IconURL = FilePath;
        break;
      case 'tif':
        IconURL = FilePath;
        break;
      case 'tiff':
        IconURL = FilePath;
        break;
      case 'pdf':
        IconURL = 'Default/img/pdficon.jpg';
        break;
      case 'docx':
        IconURL = 'Default/img/Word.jpg';
        break;
      case 'doc':
        IconURL = 'Default/img/Word.jpg';
        break;
      case 'xlsx':
        IconURL = 'Default/img/xls_icon.png';
        break;
      case 'xls':
        IconURL = 'Default/img/xls_icon.png';
        break;
      case 'pptx':
        IconURL = 'Default/img/ppt.png';
        break;
      case 'ppt':
        IconURL = 'Default/img/ppt.png';
        break;
      case 'wav':
        IconURL = 'Default/img/audio.png';
        break;
      case 'mid':
        IconURL = 'Default/img/audio.png';
        break;
      case 'midi':
        IconURL = 'Default/img/audio.png';
        break;
      case 'wma':
        IconURL = 'Default/img/audio.png';
        break;
      case 'mp3':
        IconURL = 'Default/img/audio.png';
        break;
      case 'ogg':
        IconURL = 'Default/img/audio.png';
        break;
      case 'rma':
        IconURL = 'Default/img/audio.png';
        break;
      case 'au':
        IconURL = 'Default/img/audio.png';
        break;
      case 'ea':
        IconURL = 'Default/img/audio.png';
        break;
      case 'avi':
        IconURL = 'Default/img/video.png';
        break;
      case 'mp4':
        IconURL = 'Default/img/video.png';
        break;
      case 'divx':
        IconURL = 'Default/img/video.png';
        break;
      case 'wmv':
        IconURL = 'Default/img/video.png';
        break;
      case 'mkv':
        IconURL = 'Default/img/video.png';
        break;
      case 'flv':
        IconURL = 'Default/img/video.png';
        break;
      case 'avi':
        IconURL = 'Default/img/video.png';
        break;
      case 'vob':
        IconURL = 'Default/img/video.png';
        break;
      case 'ogv':
        IconURL = 'Default/img/video.png';
        break;
      case 'wmv':
        IconURL = 'Default/img/video.png';
        break;
      case 'm4v':
        IconURL = 'Default/img/video.png';
        break;
      case 'mov':
        IconURL = 'Default/img/video.png';
        break;
      case 'wmv':
        IconURL = 'Default/img/video.png';
        break;
      default:
        IconURL = 'Default/img/NoImage.jpg';
    }
    return IconURL;
  }
}
