import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { DefaultTS } from '../SharedComponents/Default';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Messaging } from '../Communication/Messaging';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-Case',
  templateUrl: './Case.html'
})

export class Case {
  @Input() public ISCCaseTypeID;
  @Output() public CaseOutPutObject = new EventEmitter();


  public OtherEntityID = 0 as any;
  public MessageTypeID = 2 as any;
  @ViewChild(Messaging, { static: false }) Messaging: Messaging;


  public http: HttpClient;
  public baseUrl: string;
  public IsAdd: boolean;
  public IsEdit: boolean;
  public IsAddress: boolean;
  public IsPayment: boolean;
  public ExpandOrCollapseAll = true;
  public IsCaseProcedure: boolean;
  public ISCCaseObject: any = {} as any;

  //public ISCCasePaymentObjectAddEdit: any = {} as any;
  public ISCCaseOutputObject: any = {} as any;
  public ISCScholarObject: any = {} as any;
  public ISCPerson = {} as any;

  public GridData: any[];
  public GridCaseRemindersData: any[];
  public ISCCaseTypeDropdown: any[];
  public ISCCaseFileLocationDropdown: any[];

  public ISCUsersList: any[];
  public ISCCaseStatusList: any[];
  public ISCCaseRemindersList = [];
  public ISCCasePaymentArray = [];

  SafeHtmlContent: SafeHtml = '';
  public htmlContent: string;
  public IsDeleteConfirmed = false;
  public NeedToSyncAll = false;
  //ZA:
  public ClientGridData: any[];
  public AddressTypeDropdown: any[];
  public CountryDropdown: any[];
  public RelationTypeDropdown: any[];
  public ISCClientObject: any = {} as any;
  public ISCClientSpouseObject: any = {} as any;
  public ISCAddressArray = [];
  public ISCSpouseAddressArray = [];
  public ISCCaseObjectAddEdit: any = {} as any;
  public ClientID: number;
  public HigligthRowID = -1;
  public ShowErrors = [];
  public ShowErrorsMsg = false;
  public IsUpdateRecord: boolean;
  public ISCList = [];
  public SendMultipleButton = false;
  public ExportISCCaseProcedureID = -1;
  public IndexForAdditionalAddress = -2;
  public IndexForAdditionalPayment = -2;
  public ExportISCCaseID = -1;
  public ExportISCCaseProcedureName = '';

  public CaseIdToDelete = 0;
  @Output() ReloadCaseGridData: EventEmitter<any> = new EventEmitter();
  //Show hide divs
  public CaseDetail = true;
  public CasePayment = true;
  public FileLocation = true;
  public CaseReminderStatus = true;
  public Role: string;
  //ZA: Pagination part
  public pagination: any[];
  public GridDataDataAfterFiltering: any[];
  public totalItems = 3;
  public currentPage = 1;
  public itemsPerPage = 10;
  public ItemsPerPageRange: Array<any> = [0, 1];
  public RecordsPerPage = [5, 10, 25, 50, 100, 250, 500, 1000];
  public CaseReminderISCCaseID = -1;
  //public minDate = new Date('0001-01-01T00:00:00Z');

  public paginationClient: any[];
  public totalItemsClient = 3;
  public currentPageClient = 1;
  public GridDataDataAfterFilteringClient: any[];
  public itemsPerPageClient = 5;
  public ItemsPerPageRangeClient: Array<any> = [0, 1];
  public RecordsPerPageClient = [5, 10, 25, 50, 100, 250, 500, 1000];

  public FillAllRequiredFileds = false;
  public IsNewStatus = false;
  public EditCaseID = -1;

  public editorConfig = {
    removeButtons: 'NumberedList,BulletedList',
    //addButtons : 'lineheight,language',
    allowedContent: true,
    //extraPlugins : 'lineheight,richcombo,floatpanel,panel,listblock,button',
  };

  public PickExistingClient = false;
  public ISCCaseProcedureCaseID = 0;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private router: Router, private cd: ChangeDetectorRef, private zone: NgZone, private router2: ActivatedRoute, public Default: DefaultTS) {
    this.http = http;
    this.baseUrl = baseUrl;
    this.IsAdd = false;
    this.IsEdit = false;
    this.IsAddress = false;
    this.IsPayment = false;
    this.IsCaseProcedure = false;
    this.IsUpdateRecord = false;
    this.ISCCaseObjectAddEdit.StatusOfCivilDivorce = "GBP";
    this.ISCClientSpouseObject.MaritalStatus = 0;
    this.ISCClientSpouseObject.Gender = 0;
    this.ISCCaseObjectAddEdit.ISCFileLocationListID = 1;

    this.ISCClientSpouseObject.NationalityID = 66;
    //****** Add client from case side part
    if (this.Default.AddCaseFromAppointmentScreen) {
      this.IsAdd = true;
    } else {
      this.IsAdd = false;
    }
    //******
    // Geting query param value
    var ParamValue = this.Default.getParamValueQueryString('ID');
    this.CaseReminderISCCaseID = parseInt(ParamValue);

    this.EditCaseID = parseInt(this.Default.getParamValueQueryString('CaseID'));
    if (this.EditCaseID != -1 && !isNaN(this.EditCaseID)) {
      this.GetCaseDataForEdit(this.EditCaseID);
      this.IsEdit = true;
      this.CaseDetail = true;
      this.CasePayment = true;
      this.FileLocation = true;
      this.CaseReminderStatus = true;
    }

    var l_AddNew = this.Default.getParamValueQueryString('AddCase')
    if (l_AddNew == "true") {
      this.IsAdd = true;
      this.IsEdit = false;
      this.CaseDetail = true;
      this.CasePayment = true;
      this.FileLocation = true;
      this.CaseReminderStatus = true;
    }


    this.ClientID = -1;
    this.ISCList = [];
    this.Default.UserTrackingSaveRecord();
    this.GetClientData();
    this.RelationTypeGetGridDataForDopdown();
    this.CountryGetGridDataForDopdown();
    this.AddressTypeGetGridDataForDropdown();
    this.ISCStatusGetCompleteStatusesListForDropdown();
    this.GetUserRoleListForDropdown();
    this.ISCCaseObject.CaseStatus = 0;
    this.ISCCaseObject.AssignedTo = 0;

    this.ISCCaseObjectAddEdit.DivorceIsConsider = 0;
    this.ISCCaseObjectAddEdit.IsAbsoluteIrrevocable = 0;

  }

  ngOnInit() {
    if (this.ISCCaseTypeID == undefined || this.ISCCaseTypeID == null)
      this.ISCCaseObject.ISCCaseTypeID = 0;
    else this.ISCCaseObject.ISCCaseTypeID = this.ISCCaseTypeID;
    this.CaseTypeGetGridDataForDopdown();
    this.ISCFileLocationListGetCompleteDataSetForDropdown();
    this.GetCaseData();
    this.Default.CheckLoginUserDetails();
  }
  ngAfterViewChecked() {

    if (DefaultTS.Role == "Scholar") {
      this.Role = DefaultTS.Role;

      var element = document.getElementById('disableSection');
      if (element) {
        element.style.opacity = '0.5';
        element.style.pointerEvents = 'none';
      }

      if (this.IsEdit == true)
        this.HideDivs('CaseDetail');
    }
  }

  public GetCaseData() {
    this.Default.SetMasterAnimation(true);
    let data = {} as any;
    data.ISCCaseTypeID = this.ISCCaseObject.ISCCaseTypeID1 ? this.ISCCaseObject.ISCCaseTypeID1 : this.ISCCaseObject.ISCCaseTypeID;
    data.ReferenceNumber = this.ISCCaseObject.ReferenceNumber;
    data.SpouseName = this.ISCCaseObject.SpouseName;
    data.ClientName = this.ISCCaseObject.ClientName;
    data.ClientEmail = this.ISCCaseObject.ClientEmail;
    data.SpouseEmail = this.ISCCaseObject.SpouseEmail;
    data.SpouseMobileNumber = this.ISCCaseObject.SpouseMobile;
    data.ClientMobileNumber = this.ISCCaseObject.ClientMobile;
    data.CaseStatus = this.ISCCaseObject.CaseStatus;
    data.AssignedUser = this.ISCCaseObject.AssignedTo;

    this.http.post(this.baseUrl + 'api/ISCCase/ISCCaseGetGridDataBySearchParameters', data).subscribe(result => {
      var l_arrResponse = result as any;
      this.GridData = l_arrResponse[0];

      if (this.CaseReminderISCCaseID != -1 && !isNaN(this.CaseReminderISCCaseID)) {
        this.GetCaseRiminderForm(this.CaseReminderISCCaseID);
      }

      this.Default.SetMasterAnimation(false);

      this.GridDataDataAfterFiltering = this.Default.AssignGridDataValuesToOtherGridData(this.GridData);
      this.pagination = [] as any;
      this.pagination = this.GridData.slice((this.currentPage - 1) * this.itemsPerPage, (this.currentPage) * this.itemsPerPage)
      if (this.pagination.length == 0) {
        this.Default.ShowAlert('No Record Found.');
      }
      this.totalItems = this.GridData.length;
      if (this.totalItems > 0) {
        this.ItemsPerPageRange[0] = ((this.currentPage - 1) * this.itemsPerPage) + 1;
        this.ItemsPerPageRange[1] = (this.ItemsPerPageRange[0] + this.pagination.length) - 1;
      }
      else {
        this.ItemsPerPageRange[0] = ((this.currentPage - 1) * this.itemsPerPage);
        this.ItemsPerPageRange[1] = (this.ItemsPerPageRange[0] + this.pagination.length);
      }
      this.currentPage = 1;
      this.GetListDataForPagination(false);

    }, error => console.error(error));
  }
  public AssignValuesToOutPutObject(id: number, caseType: string) {

    var l_intIndex = this.Default.GetIndexExactlyMatchingColumn(this.GridData, 'ISCCaseID', id);
    if (l_intIndex > -1) {
      this.ISCCaseOutputObject.ISCCaseID = id;
      this.HigligthRowID = id;

      this.ISCCaseOutputObject.CaseTypeName = caseType;
      this.CaseOutPutObject.emit(this.ISCCaseOutputObject);
    }
  }
  public DeSlectCaseProcedure() {

    this.ISCCaseOutputObject.ISCCaseID = 0;
    this.HigligthRowID = -1;

    this.ISCCaseOutputObject.CaseTypeName = '';
    this.CaseOutPutObject.emit(this.ISCCaseOutputObject);
  }
  public CaseTypeGetGridDataForDopdown() {
    this.Default.SetMasterAnimation(true);
    this.http.get(this.baseUrl + 'api/ISCCaseType/ISCCaseTypeGetCompleteDataSetForDropdown').subscribe(result => {
      var l_arrResponse = result[0] as any;
      this.ISCCaseTypeDropdown = l_arrResponse;
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }
  public ISCFileLocationListGetCompleteDataSetForDropdown() {
    this.Default.SetMasterAnimation(true);
    this.http.get(this.baseUrl + 'api/ISCFileLocationList/ISCFileLocationListGetCompleteDataSetForDropdown').subscribe(result => {
      var l_arrResponse = result[0] as any;
      this.ISCCaseFileLocationDropdown = l_arrResponse;
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }


  public ISCStatusGetCompleteStatusesListForDropdown() {
    this.Default.SetMasterAnimation(true);
    this.http.get(this.baseUrl + 'api/ISCStatus/ISCStatusGetCompleteStatusesList').subscribe(result => {
      var l_arrResponse = result[0] as any;
      this.ISCCaseStatusList = l_arrResponse;
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }

  public GetUserRoleListForDropdown() {
    this.Default.SetMasterAnimation(true);
    this.http.get(this.baseUrl + 'api/RelSiteUserAndSiteRole/GetUserRoleList').subscribe(result => {
      var l_arrResponse = result[0] as any;
      this.ISCUsersList = l_arrResponse;
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }



  public GetDivForAdditionalAddress(value) {
    var Address = value;
    if (Address == -1) {
      this.ISCAddressArray.push({ 'Line1': '', 'Line2': '', 'Line3': '', 'AddressTypeID': 2, 'CountryID': 66, 'State': '', 'City': '', 'Town': '', 'PostCode': '', 'AddressID': 0, 'ISCPersonAddressID': 0 });

      if (this.IsEdit) {
        this.ISCClientSpouseObject.ISCAdressArray = this.Default.AssignGridDataValuesToOtherGridData(this.ISCAddressArray);
      }
    }
    else {
      this.ISCAddressArray.splice(value, 1);
      if (this.IsEdit) {
        this.ISCClientSpouseObject.ISCAdressArray = this.Default.AssignGridDataValuesToOtherGridData(this.ISCAddressArray);
      }
    }
  }

  public GetIndexForAdditionalAddress(p_Index) {
    this.IndexForAdditionalAddress = p_Index;
  }

  public GetDivForAdditionalPayment(value) {

    var Payment = value;
    if (Payment == -1) {

      var casePaymentObject = {} as any
      casePaymentObject.AmountOfInvoice = '';
      casePaymentObject.AmountOfPayment = '';
      casePaymentObject.CardHolderName = '';
      casePaymentObject.ContactNumber = '';
      casePaymentObject.DateOfInvoice = '';
      casePaymentObject.DateOfPayment = '';
      casePaymentObject.ISCCaseID = 0;
      casePaymentObject.ISCCasePaymentID = 0;
      casePaymentObject.InstrumentNumber = '';
      casePaymentObject.InvoiceNumber = '';
      casePaymentObject.IsDeleted = false;
      casePaymentObject.PaymentRecieved = false;
      casePaymentObject.PaymentType = 0;
      casePaymentObject.Remarks = '';

      this.ISCCasePaymentArray.push(casePaymentObject);
    }
    else {
      var deltedCasePayment = this.ISCCasePaymentArray[value];
      this.ISCCasePaymentArray.splice(value, 1) as any;

      if (deltedCasePayment.ISCCasePaymentID > 0) {

        let data = {} as any;
        data.ISCCasePaymentID = deltedCasePayment.ISCCasePaymentID;
        this.http.post(this.baseUrl + 'api/ISCCasePayment/ISCCasePaymentDeleteRecord', data).subscribe(result => {
          var l_arrResponse = result as any;

          this.Default.SetMasterAnimation(false);
        }, error => console.error(error));

      }

    }
  }

  public GetIndexForAdditionalPayment(p_Index) {
    this.IndexForAdditionalPayment = p_Index;
  }

  public getCaseNameForSelectedOption() {
    var l_intIndex = this.Default.GetIndexExactlyMatchingColumn(this.ISCCaseTypeDropdown, 'ID', this.ISCCaseObjectAddEdit.ISCCaseTypeIDToAdd)
    if (l_intIndex > -1) {
      this.ISCCaseObjectAddEdit.CaseName = this.ISCCaseTypeDropdown[l_intIndex].Name;
      this.ISCCaseObjectAddEdit.ISCCaseTypeID = this.ISCCaseObjectAddEdit.ISCCaseTypeIDToAdd;
    }
    else {
      this.ISCCaseObjectAddEdit.CaseName = '';
      this.ISCCaseObjectAddEdit.ISCCaseTypeID = undefined;
    }
  }

  public GetCaseReminderData(p_CaseID) {
    let data = {} as any;
    data.ISCCaseID = p_CaseID;
    this.Default.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/ISCCaseReminders/ISCCaseRemindersDataWithRelationOfCaseProcedure', data).subscribe(result => {
      var l_arrResponse = result as any;
      this.GridCaseRemindersData = l_arrResponse[0];
      this.NeedToSyncAll = false;
      for (var i = 0; i < this.GridCaseRemindersData.length; i++) {
        if (this.GridCaseRemindersData[i].Status == "") {
          this.GridCaseRemindersData[i].Status = undefined;
        }

        if (this.GridCaseRemindersData[i].NeedToSync == true) {
          this.NeedToSyncAll = true;
        }

      }


      // this.CheckedSyncAll();

      /*
      for (var i = 0; i < this.GridCaseRemindersData.length; i++) {

      if (this.GridCaseRemindersData[i].ExpectedDateOfReminder == "1900-01-01T00:00:00") {
        this.GridCaseRemindersData[i].ExpectedDateOfReminder = "";
      }
      if (this.GridCaseRemindersData[i].SubsequentDateOfReminder == "1900-01-01T00:00:00") {
        this.GridCaseRemindersData[i].SubsequentDateOfReminder = "";
      }
      if (this.GridCaseRemindersData[i].LastDateSent == "1900-01-01T00:00:00") {
        this.GridCaseRemindersData[i].LastDateSent = "";
      }
    }
    */
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }

  public GetClientData() {
    let data = {} as any;
    data.FirstName = this.ISCScholarObject.FilterFirstName;
    data.LastName = this.ISCScholarObject.FilterLastName;
    data.Email = this.ISCScholarObject.FilterEmail;
    data.MobileNumber = this.ISCScholarObject.FilterMobile;
    this.Default.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/ISCClient/ISCClientGetCompleteDataSetCustom1', data).subscribe(result => {
      var l_arrResponse = result as any;
      this.ClientGridData = l_arrResponse[0];
      this.Default.SetMasterAnimation(false);
      console.log('Client Data')
      this.GridDataDataAfterFilteringClient = this.Default.AssignGridDataValuesToOtherGridData(this.ClientGridData);
      this.paginationClient = [] as any;
      this.paginationClient = this.ClientGridData.slice((this.currentPageClient - 1) * this.itemsPerPageClient, (this.currentPageClient) * this.itemsPerPageClient)

      this.totalItemsClient = this.ClientGridData.length;
      if (this.totalItemsClient > 0) {
        this.ItemsPerPageRangeClient[0] = ((this.currentPageClient - 1) * this.itemsPerPageClient) + 1;
        this.ItemsPerPageRangeClient[1] = (this.ItemsPerPageRangeClient[0] + this.paginationClient.length) - 1;
      }
      else {
        this.ItemsPerPageRangeClient[0] = ((this.currentPageClient - 1) * this.itemsPerPageClient);
        this.ItemsPerPageRangeClient[1] = (this.ItemsPerPageRangeClient[0] + this.paginationClient.length);
      }
      this.currentPageClient = 1;
      this.GetListDataForPaginationClient(false);
    }, error => console.error(error));
  }

  public RelationTypeGetGridDataForDopdown() {
    this.Default.SetMasterAnimation(true);
    this.http.get(this.baseUrl + 'api/ISCRelationType/ISCRelationTypeGetCompleteDataSetForDopdown').subscribe(result => {
      var l_arrResponse = result[0] as any;
      this.RelationTypeDropdown = l_arrResponse;
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }


  public CountryGetGridDataForDopdown() {
    this.Default.SetMasterAnimation(true);
    this.http.get(this.baseUrl + 'api/Country/CountryGetCompleteDataSetForDropdown').subscribe(result => {
      var l_arrResponse = result[0] as any;
      this.CountryDropdown = l_arrResponse;
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }


  public AddressTypeGetGridDataForDropdown() {
    this.Default.SetMasterAnimation(true);
    this.http.get(this.baseUrl + 'api/AddressType/AddressTypeGetCompleteDataSetForDropdown').subscribe(result => {
      var l_arrResponse = result[0] as any;
      this.AddressTypeDropdown = l_arrResponse;
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }

  public ISCClientCopyRecordToField(ISCClientID: number) {
    if (this.ClientGridData && this.ClientGridData.length > 0) {
      for (var i = 0; i < this.ClientGridData.length; i++) {
        if (ISCClientID == this.ClientGridData[i].ISCClientID) {
          this.ISCClientObject.Name = this.ClientGridData[i].FirstName + " " + this.ClientGridData[i].LastName;
          this.ISCClientObject.Email = this.ClientGridData[i].Email;
          this.ISCClientObject.MobileNumber = this.ClientGridData[i].MobileNumber;
          this.ClientID = ISCClientID;
          this.GetClientDetail(this.ClientGridData[i].ISCPersonID);
          break;
        }
      }
    }
  }

  public GetClientDetail(P_PersonID) {
    let data = {} as any;
    data.PersonID = P_PersonID;
    this.http.post(this.baseUrl + 'api/ISCClient/GetDataSetByPersonID', data).subscribe(result => {
      var l_arrResponse = result[0] as any;
      this.ISCSpouseAddressArray = [];
      for (var i = 0; i < l_arrResponse.length; i++) {
        if (l_arrResponse[i].CountryID && l_arrResponse[i].CountryID > 0) {
          this.ISCSpouseAddressArray.push({ 'Line1': l_arrResponse[i].Line1, 'Line2': l_arrResponse[i].Line2, 'Line3': l_arrResponse[i].Line3, 'AddressTypeID': l_arrResponse[i].AddressTypeID, 'CountryID': l_arrResponse[i].CountryID, 'State': l_arrResponse[i].State, 'City': l_arrResponse[i].City, 'Town': l_arrResponse[i].Town, 'PostCode': l_arrResponse[i].PostCode, 'AddressID': l_arrResponse[i].AddressID, 'ISCPersonAddressID': l_arrResponse[i].ISCPersonAddressID, 'DateOfBirth': l_arrResponse[i].DateOfBirth, 'PlaceOfBirth': l_arrResponse[i].PlaceOfBirth });
        }
      }
    }, error => console.error(error));
  }

  public DeSelectClient() {
    this.ClientID = -1;
    this.ISCClientObject.Email = '';
    this.ISCClientObject.Name = '';
    this.ISCClientObject.MobileNumber = '';
  }

  public SaveCaseInfo() {

    let data = {} as any;
    data.ISCPerson = {} as any;
    data.ISCCasePaymentsArray = [];
    data.ISCCase = {} as any;

    data.ISCPerson.Title = '';
    data.ISCPerson.FirstName = '';
    data.ISCPerson.MiddleName = '';
    data.ISCPerson.LastName = '';
    data.ISCPerson.DateOfBirth = this.Default.minDate;
    data.ISCPerson.PlaceOfBirth = '';
    data.ISCPerson.Nationality = '';
    data.ISCPerson.Gender = 0;
    data.ISCPerson.MaritalStatus = 0;
    data.ISCPerson.NationalityStatus = '';
    data.ISCPerson.CountryID = 0;
    data.ISCPerson.EmailAddress = ' ';
    data.ISCPerson.MobileNumber = ' ';
    data.ISCPerson.HomePhoneNumber = '';
    data.ISCPerson.WorkPhoneNumber = '';
    data.ISCPerson.ImageURL = '';
    data.ISCPerson.NationalityID = 0;
    data.ISCPerson.IsAbroad = 0;

    //address table
    //this.ISCAdressArray.push({ 'Line1': 'test', 'Line2': '', 'Line3': '', 'AddressTypeID': 2, 'CountryID': 19, 'State': '', 'City': '', 'Town': '', 'PostCode': '', 'AddressID': 0, 'ISCPersonAddressID': 0 });
    data.ISCPerson.ISCAdressArray = this.ISCAddressArray;

    data.ISCCase.ISCFileLocationListID = 1;
    data.ISCCase.ISCCaseTypeID = 1;   // First Drop Down
    data.ISCCase.ISCClientID = this.ClientID;  //2nd

    data.ISCCase.ISCClientSpouseID = 0;  //3rd
    data.ISCCase.CurrencyID = 0; // not used
    data.ISCCase.ISCScholarID = 0; // not used yet

    data.ISCCase.ReferenceNumber = '';
    data.ISCCase.DateOfApplication = this.Default.minDate;
    data.ISCCase.DateOfMarriage = this.Default.minDate;
    data.ISCCase.DateOfSeperation = this.Default.minDate;
    data.ISCCase.AmountOfMehr = 0;
    data.ISCCase.AmountOfMehrPaid = 0;
    data.ISCCase.AmountOfMeherDeffered = 0;
    data.ISCCase.WasJewelleryGivenAtMarriage = false;
    data.ISCCase.JewelleryDetails = '';
    data.ISCCase.DivorceReason = '';
    data.ISCCase.HasAppliedForCivilDivorce = false;
    data.ISCCase.StatusOfCivilDivorce = '';
    data.ISCCase.DidSpouseDefendCivilDivorce = false;
    data.ISCCase.DateOfDisolution = this.Default.minDate;
    data.ISCCase.IsCaseOpen = false;

    this.ISCCaseObjectAddEdit.FirstLetterSent = false;
    this.ISCCaseObjectAddEdit.SecondLetterSent = false;
    this.ISCCaseObjectAddEdit.ThirdLetterSent = false;
    this.ISCCaseObjectAddEdit.AddressVerifyLetterSent = false;
    this.ISCCaseObjectAddEdit.TallaqRequestFormSent = false;
    this.ISCCaseObjectAddEdit.IDProvider = 0;
    this.ISCCaseObjectAddEdit.DivorceReason = '';


    data.ISCCase.DidSpouseReply = false;
    data.ISCCase.PlaceOfNikkah = '';
    data.ISCCase.DateOfNikkah = this.Default.minDate;
    data.ISCCase.PlaceOfRegistrationInUK = '';
    data.ISCCase.DateOfRegistration = this.Default.minDate;
    data.ISCCase.HaveYouSponsoredYourSpouse = false;
    data.ISCCase.ThisCountryEntryDate = this.Default.minDate;
    data.ISCCase.HaveYouMarriedBefore = false;
    data.ISCCase.DateOfDecreeAbsolute = this.Default.minDate;
    data.ISCCase.CommentOrRemarks = '';

    data.ISCCase.CaseStatus = undefined;
    data.ISCCase.AssignedTo = undefined;

    for (let field in data.ISCPerson) {
      if (this.ISCClientSpouseObject[field] !== undefined && this.ISCClientSpouseObject[field] != null && this.ISCClientSpouseObject[field] != '')
        data.ISCPerson[field] = this.ISCClientSpouseObject[field];
    }



    for (var i = 0; i < this.ISCCasePaymentArray.length; i++) {

      var casePayment = {} as any;
      var iscCasePaymentObject = this.ISCCasePaymentArray[i];

      for (let field in iscCasePaymentObject) {
        if (iscCasePaymentObject[field] !== undefined && iscCasePaymentObject[field] != null && iscCasePaymentObject[field] != '')
          casePayment[field] = iscCasePaymentObject[field];
      }
      data.ISCCasePaymentsArray.push(casePayment);
    }

    for (let field in this.ISCCaseObjectAddEdit) {


      if (this.ISCCaseObjectAddEdit[field] !== undefined && this.ISCCaseObjectAddEdit[field] != null && this.ISCCaseObjectAddEdit[field] != '')
        data.ISCCase[field] = this.ISCCaseObjectAddEdit[field];
    }

    this.Default.SetMasterAnimation(true);
    this.http.post('api/ISCCase/ISCCaseCompleteSave', data).subscribe(result => {
      var reponse = result[0];
      this.Default.ShowAlert(reponse.ModalMessage);
      this.CancelRecord();
      this.GetCaseData();
      this.ReloadCaseGridData.emit();
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));

  }

  public ISCCaseValidation(p_SaveClicked) {



    var l_boolShouldSave = true;
    if (this.ShowErrorsMsg) {
      this.ShowErrors = [];

      if (!this.ISCCaseObjectAddEdit.ReferenceNumber || this.ISCCaseObjectAddEdit.ReferenceNumber == '') {
        this.ShowErrors[31] = '*Required';
        l_boolShouldSave = false;
      }

      //if (!this.ISCCaseObjectAddEdit.DateOfApplication || this.ISCCaseObjectAddEdit.DateOfApplication == '') {
      //  this.ShowErrors[32] = '*Required';
      //  l_boolShouldSave = false;
      //}

      //if (!this.ISCCaseObjectAddEdit.CaseStatus || this.ISCCaseObjectAddEdit.CaseStatus == '' || this.ISCCaseObjectAddEdit.CaseStatus == undefined) {
      //  this.ShowErrors[45] = '*Required';
      //  l_boolShouldSave = false;
      //}

      //if (!this.ISCCaseObjectAddEdit.AssignedTo || this.ISCCaseObjectAddEdit.AssignedTo == '' || this.ISCCaseObjectAddEdit.AssignedTo == undefined) {
      //  this.ShowErrors[46] = '*Required';
      //  l_boolShouldSave = false;
      //}


      //if (!this.ISCClientSpouseObject.FirstName || this.ISCClientSpouseObject.FirstName == '') {
      //  this.ShowErrors[36] = '*Required';
      //  this.ShowErrors[100] = '*Some Fields Are Required.';
      //  l_boolShouldSave = false;
      //}
      //if (!this.ISCClientSpouseObject.LastName || this.ISCClientSpouseObject.LastName == '') {
      //  this.ShowErrors[37] = '*Required';
      //  this.ShowErrors[100] = '*Some Fields Are Required.';
      //  l_boolShouldSave = false;
      //}
      //if (!this.ISCClientSpouseObject.DateOfBirth || this.ISCClientSpouseObject.DateOfBirth == '') {
      //  this.ShowErrors[47] = '*Required';
      //  this.ShowErrors[100] = '*Some Fields Are Required.';
      //  l_boolShouldSave = false;
      //}
      //if (!this.ISCClientSpouseObject.Gender || this.ISCClientSpouseObject.Gender == '') {
      //  this.ShowErrors[38] = '*Required';
      //  this.ShowErrors[100] = '*Some Fields Are Required.';
      //  l_boolShouldSave = false;
      //}

      if (!this.ClientID || this.ClientID == -1) {
        this.ShowErrors[33] = '*Required';
        l_boolShouldSave = false;
      }
      //for (var i = 0; i < this.ISCAddressArray.length; i++) {
      //  if (!this.ISCAddressArray[i].Line1 || this.ISCAddressArray[i].Line1 == '') {
      //    this.ShowErrors[i] = '*Required';
      //    this.ShowErrors[100] = '*Some Fields Are Required.';
      //    l_boolShouldSave = false;
      //  }
      //  if (!this.ISCAddressArray[i].AddressTypeID || this.ISCAddressArray[i].AddressTypeID == '') {
      //    this.ShowErrors["1" + i] = '*Required';
      //    this.ShowErrors[100] = '*Some Fields Are Required.';
      //    l_boolShouldSave = false;
      //  }
      //  if (!this.ISCAddressArray[i].CountryID || this.ISCAddressArray[i].CountryID == '') {
      //    this.ShowErrors["2" + i] = '*Required';
      //    this.ShowErrors[100] = '*Some Fields Are Required.';
      //    l_boolShouldSave = false;
      //  }
      //}
      //if (this.ISCAddressArray.length == 0) {
      //  //alert("Atleast One Address Is Required.");
      //  this.ShowErrors[34] = "Atleast One Address Is Required.";
      //  this.ShowErrors[100] = '*Some Fields Are Required.';
      //  l_boolShouldSave = false;
      //}


      //for (var i = 0; i < this.ISCCasePaymentArray.length; i++) {
      //  if (!this.ISCCasePaymentArray[i].InvoiceNumber || this.ISCCasePaymentArray[i].InvoiceNumber == '') {
      //    this.ShowErrors["11" + i] = '*Required';
      //    this.ShowErrors[200] = '*Some Fields Are Required.';
      //    l_boolShouldSave = false;
      //  }

      //  if (!this.ISCCasePaymentArray[i].DateOfInvoice || this.ISCCasePaymentArray[i].DateOfInvoice == '' || this.ISCCasePaymentArray[i].DateOfInvoice == this.Default.minDate) {
      //    this.ShowErrors["12" + i] = '*Required';
      //    this.ShowErrors[200] = '*Some Fields Are Required.';
      //    l_boolShouldSave = false;
      //  }
      //  if (!this.ISCCasePaymentArray[i].AmountOfInvoice || this.ISCCasePaymentArray[i].AmountOfInvoice == '') {
      //    this.ShowErrors["13" + i] = '*Required';
      //    this.ShowErrors[200] = '*Some Fields Are Required.';
      //    l_boolShouldSave = false;
      //  }
      //  if (this.ISCCasePaymentArray[i].PaymentRecieved == true) {

      //    if (!this.ISCCasePaymentArray[i].PaymentType || this.ISCCasePaymentArray[i].PaymentType == '') {
      //      this.ShowErrors["14" + i] = '*Required';
      //      this.ShowErrors[200] = '*Some Fields Are Required.';
      //      l_boolShouldSave = false;
      //    }
      //    if (!this.ISCCasePaymentArray[i].DateOfPayment || this.ISCCasePaymentArray[i].DateOfPayment == '' || this.ISCCasePaymentArray[i].DateOfPayment == this.Default.minDate) {
      //      this.ShowErrors["15" + i] = '*Required';
      //      this.ShowErrors[200] = '*Some Fields Are Required.';
      //      l_boolShouldSave = false;
      //    }
      //    if (!this.ISCCasePaymentArray[i].AmountOfPayment || this.ISCCasePaymentArray[i].AmountOfPayment == '') {
      //      this.ShowErrors["16" + i] = '*Required';
      //      this.ShowErrors[200] = '*Some Fields Are Required.';
      //      l_boolShouldSave = false;
      //    }

      //  }
      //}


      //if (this.ISCClientSpouseObject.EmailAddress != undefined) {
      //  if (!this.Default.isValidEmail(this.ISCClientSpouseObject.EmailAddress)) {
      //    this.ShowErrors[35] = '*Please Enter Valid Email ID.';
      //    this.ShowErrors[100] = '*Some Fields Are Required.';
      //    l_boolShouldSave = false;
      //  }
      //}
      //if (!this.ISCClientSpouseObject.EmailAddress || this.ISCClientSpouseObject.EmailAddress == '') {
      //  this.ShowErrors[35] = '*Required';
      //  this.ShowErrors[100] = '*Some Fields Are Required.';
      //  l_boolShouldSave = false;
      //}
      //if (!this.ISCClientSpouseObject.MobileNumber || this.ISCClientSpouseObject.MobileNumber == '') {
      //  this.ShowErrors[41] = '*Required';
      //  this.ShowErrors[100] = '*Some Fields Are Required.';
      //  l_boolShouldSave = false;
      //}

      if (l_boolShouldSave && p_SaveClicked && !this.IsUpdateRecord && this.IsTherePendingMessage == false) {
        this.SaveCaseInfo();
      }
      else if (p_SaveClicked && !this.IsUpdateRecord && this.IsTherePendingMessage == false) {
        this.Default.ShowAlert("Please fill in all the required fields.");
      }

      if (l_boolShouldSave && p_SaveClicked && this.IsUpdateRecord && this.IsTherePendingMessage == false) {
        this.UpdateCaseInfo();

        if (this.IsUpdateRecord) {
          this.SaveMultipleCaseReminderData();
          this.GridCaseRemindersData = [];
        }
        this.IsUpdateRecord = false;
      }
      else if (p_SaveClicked && this.IsUpdateRecord && this.IsTherePendingMessage == false) {
        this.Default.ShowAlert("Please fill in all the required fields.");
      }
      this.ShowErrors[50000] = '';
      if (this.IsTherePendingMessage == true) {
        this.ShowErrors[50000] = 'You have pending message, please sent it first.'
      }

    }





  }

  public ClearObject() {
    this.IsNewStatus = false;
    this.FillAllRequiredFileds = false;
    this.ISCClientSpouseObject = {};
    this.ISCCaseObjectAddEdit = {};
    this.ISCCasePaymentArray = [];
    this.ISCClientObject = {};
    this.ISCAddressArray = [];
    this.ShowErrors = [];
    this.ClientID = -1;
    this.GridCaseRemindersData = [];
    this.ISCList = [];
    this.SendMultipleButton = false;
    this.ShowErrorsMsg = false;
    this.Default.AddCaseFromAppointmentScreen = false;
    this.ISCClientSpouseObject.MaritalStatus = 0;
    this.ISCClientSpouseObject.Gender = 0;
    this.ISCCaseObjectAddEdit.ISCFileLocationListID = 1;
    this.DeSelectClient();
  }

  public CancelRecord() {
    this.IsAdd = false;
    this.IsEdit = false;
    this.IsAddress = false;
    this.IsPayment = false;
    this.IsUpdateRecord = false;
    this.CaseDetail = false;
    this.CasePayment = false;
    this.FileLocation = false;
    this.CaseReminderStatus = false;
    this.GoBackToAppointmentScreen();
    this.ClearObject();


  }

  public GoBackToAppointmentScreen() {
    if (this.Default.AddCaseFromAppointmentScreen) {
      this.Default.AddCaseFromAppointmentScreen = false;
    }
  }

  public ReloadClientGridData(event) {
    console.log(event);
    this.GetClientData();
    setTimeout(() => {
      this.ISCClientCopyRecordToField(event);    }, 1000);
  }

  public GetCaseDataForEdit(p_CaseID) {

    let data = {} as any;
    data.ISCCaseID = p_CaseID;
    this.ISCCaseProcedureCaseID = p_CaseID;
    this.Default.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/ISCCase/ISCCaseDataGetByID', data).subscribe(result => {
      var l_arrResponse = result as any;
      var reponse = l_arrResponse;
      var l_arrCaseResponse = result[0] as any;
      var l_arrCasePaymentResponse = result[1] as any;
      var l_arrResponse = result[2] as any;

      this.ISCCaseObjectAddEdit.ISCCaseTypeID = l_arrCaseResponse[0].ISCCaseTypeID;
      this.ISCCaseObjectAddEdit.ISCCaseTypeIDToAdd = l_arrCaseResponse[0].ISCCaseTypeID;
      this.ISCClientObject.NationalityID = l_arrCaseResponse[0].CountryID;

      this.ISCCaseObjectAddEdit.DivorceIsConsider = 0;
      this.ISCCaseObjectAddEdit.IsAbsoluteIrrevocable = 0;

      this.ISCClientSpouseObject.ISCClientID = l_arrCaseResponse[0].ISCClientID;
      debugger;
      setTimeout(() => {
        this.ISCClientCopyRecordToField(l_arrCaseResponse[0].ISCClientID);      }, 2000);

      this.getCaseNameForSelectedOption();
      //this.ISCClientSpouseObject.SiteUserID = l_arrResponse[0].SiteUserID;
      this.ISCClientSpouseObject.ISCPersonID = l_arrResponse[0].ISCPersonID;
      this.ISCClientSpouseObject.Title = l_arrResponse[0].Title;
      this.ISCClientSpouseObject.FirstName = l_arrResponse[0].FirstName;
      this.ISCClientSpouseObject.MiddleName = l_arrResponse[0].MiddleName;
      this.ISCClientSpouseObject.LastName = l_arrResponse[0].LastName;
      // this.ISCClientSpouseObject.DateOfBirth = l_arrResponse[0].DateOfBirth;
      if (l_arrResponse[0].DateOfBirth == "1900-01-01T00:00:00") {
        this.ISCClientSpouseObject.DateOfBirth = '';
      } else {
        this.ISCClientSpouseObject.DateOfBirth = l_arrResponse[0].DateOfBirth;
      }

      this.ISCClientSpouseObject.PlaceOfBirth = l_arrResponse[0].PlaceOfBirth;
      this.ISCClientSpouseObject.Nationality = l_arrResponse[0].Nationality;
      this.ISCClientSpouseObject.Gender = l_arrResponse[0].Gender;
      this.ISCClientSpouseObject.MaritalStatus = l_arrResponse[0].MaritalStatus;
      this.ISCClientSpouseObject.NationalityStatus = l_arrResponse[0].NationalityStatus;
      this.ISCClientSpouseObject.CountryID = l_arrResponse[0].CountryId;
      this.ISCClientSpouseObject.EmailAddress = l_arrResponse[0].EmailAddress;
      this.ISCClientSpouseObject.MobileNumber = l_arrResponse[0].MobileNumber;
      this.ISCClientSpouseObject.HomePhoneNumber = l_arrResponse[0].HomePhoneNumber;
      this.ISCClientSpouseObject.WorkPhoneNumber = l_arrResponse[0].WorkPhoneNumber;
      this.ISCClientSpouseObject.ImageURL = l_arrResponse[0].ImageURL;
      this.ISCClientSpouseObject.NationalityID = l_arrResponse[0].NationalityID;
      this.ISCClientSpouseObject.IsAbroad = l_arrResponse[0].IsAbroad;

      if (this.ISCClientSpouseObject.NationalityID == 0)
        this.ISCClientSpouseObject.NationalityID = undefined;

      //this.ISCCasePaymentArray = l_arrCasePaymentResponse;

      this.ISCAddressArray = [];
      for (var i = 0; i < l_arrResponse.length; i++) {
        if (l_arrResponse[i].CountryID && l_arrResponse[i].CountryID > 0) {
          this.ISCAddressArray.push({ 'Line1': l_arrResponse[i].Line1, 'Line2': l_arrResponse[i].Line2, 'Line3': l_arrResponse[i].Line3, 'AddressTypeID': l_arrResponse[i].AddressTypeID, 'CountryID': l_arrResponse[i].CountryID, 'State': l_arrResponse[i].State, 'City': l_arrResponse[i].City, 'Town': l_arrResponse[i].Town, 'PostCode': l_arrResponse[i].PostCode, 'AddressID': l_arrResponse[i].AddressID, 'ISCPersonAddressID': l_arrResponse[i].ISCPersonAddressID });
        }
      }
      this.ISCCasePaymentArray = [];
      for (var i = 0; i < l_arrCasePaymentResponse.length; i++) {

        var casePaymentObject = {} as any;
        casePaymentObject.AmountOfInvoice = l_arrCasePaymentResponse[i].AmountOfInvoice;
        casePaymentObject.AmountOfPayment = l_arrCasePaymentResponse[i].AmountOfPayment;
        casePaymentObject.CardHolderName = l_arrCasePaymentResponse[i].CardHolderName;
        casePaymentObject.ContactNumber = l_arrCasePaymentResponse[i].ContactNumber;
        casePaymentObject.DateOfInvoice = l_arrCasePaymentResponse[i].DateOfInvoice;
        casePaymentObject.DateOfPayment = l_arrCasePaymentResponse[i].DateOfPayment;
        casePaymentObject.ISCCaseID = l_arrCasePaymentResponse[i].ISCCaseID;
        casePaymentObject.ISCCasePaymentID = l_arrCasePaymentResponse[i].ISCCasePaymentID;
        casePaymentObject.InstrumentNumber = l_arrCasePaymentResponse[i].InstrumentNumber;
        casePaymentObject.InvoiceNumber = l_arrCasePaymentResponse[i].InvoiceNumber;
        casePaymentObject.IsDeleted = l_arrCasePaymentResponse[i].IsDeleted;
        casePaymentObject.PaymentRecieved = l_arrCasePaymentResponse[i].PaymentRecieved;
        casePaymentObject.PaymentType = l_arrCasePaymentResponse[i].PaymentType;
        casePaymentObject.Remarks = l_arrCasePaymentResponse[i].Remarks;
        casePaymentObject.TimeToContact = l_arrCasePaymentResponse[i].TimeToContact;

        this.ISCCasePaymentArray.push(casePaymentObject);

        // this.ISCCasePaymentArray.push({ 'InvoiceNumber': l_arrCasePaymentResponse[i].InvoiceNumber, 'DateOfInvoice': l_arrCasePaymentResponse[i].DateOfInvoice, 'AmountOfInvoice': l_arrCasePaymentResponse[i].AmountOfInvoice, 'PaymentRecieved': l_arrCasePaymentResponse[i].PaymentRecieved, 'PaymentType': l_arrCasePaymentResponse[i].PaymentType, 'DateOfPayment': l_arrCasePaymentResponse[i].DateOfPayment, 'AmountOfPayment': l_arrCasePaymentResponse[i].AmountOfPayment, 'InstrumentNumber': l_arrCasePaymentResponse[i].InstrumentNumber, 'CardHolderName': l_arrCasePaymentResponse[i].CardHolderName, 'ContactNumber': l_arrCasePaymentResponse[i].ContactNumber, 'Remarks': l_arrCasePaymentResponse[i].Remarks });
      }
      this.ISCClientSpouseObject.ISCAdressArray = this.Default.AssignGridDataValuesToOtherGridData(this.ISCAddressArray);
      this.IsAddress = true;
      this.IsPayment = true;

      this.ISCCaseObjectAddEdit.CurrencyID = l_arrCaseResponse[0].CurrencyID;
      this.ISCCaseObjectAddEdit.ISCCaseID = l_arrCaseResponse[0].ISCCaseID;
      this.ISCCaseObjectAddEdit.ISCScholarID = l_arrCaseResponse[0].ISCScholarID;
      this.ISCCaseObjectAddEdit.ISCClientSpouseID = l_arrCaseResponse[0].ISCClientSpouseID;
      this.ISCCaseObjectAddEdit.ISCClientID = l_arrCaseResponse[0].ISCClientID;
      this.ISCCaseObjectAddEdit.ISCFileLocationListID = l_arrCaseResponse[0].ISCFileLocationListID;
      this.ISCCaseObjectAddEdit.ReferenceNumber = l_arrCaseResponse[0].ReferenceNumber;
      this.ISCCaseObjectAddEdit.DateOfApplication = l_arrCaseResponse[0].DateOfApplication;
      this.ISCCaseObjectAddEdit.DateOfMarriage = l_arrCaseResponse[0].DateOfMarriage;
      this.ISCCaseObjectAddEdit.DateOfSeperation = l_arrCaseResponse[0].DateOfSeperation;
      this.ISCCaseObjectAddEdit.AmountOfMehr = l_arrCaseResponse[0].AmountOfMehr;
      this.ISCCaseObjectAddEdit.AmountOfMehrPaid = l_arrCaseResponse[0].AmountOfMehrPaid;
      this.ISCCaseObjectAddEdit.AmountOfMeherDeffered = l_arrCaseResponse[0].AmountOfMeherDeffered;
      this.ISCCaseObjectAddEdit.WasJewelleryGivenAtMarriage = l_arrCaseResponse[0].WasJewelleryGivenAtMarriage;
      this.ISCCaseObjectAddEdit.JewelleryDetails = l_arrCaseResponse[0].JewelleryDetails;
      this.ISCCaseObjectAddEdit.DivorceReason = l_arrCaseResponse[0].DivorceReason;
      this.ISCCaseObjectAddEdit.HasAppliedForCivilDivorce = l_arrCaseResponse[0].HasAppliedForCivilDivorce;
      this.ISCCaseObjectAddEdit.StatusOfCivilDivorce = l_arrCaseResponse[0].StatusOfCivilDivorce;
      this.ISCCaseObjectAddEdit.DidSpouseDefendCivilDivorce = l_arrCaseResponse[0].DidSpouseDefendCivilDivorce;
      this.ISCCaseObjectAddEdit.DateOfDisolution = l_arrCaseResponse[0].DateOfDisolution;
      this.ISCCaseObjectAddEdit.IsCaseOpen = l_arrCaseResponse[0].IsCaseOpen;

      this.ISCCaseObjectAddEdit.FirstLetterSent = l_arrCaseResponse[0].FirstLetterSent;
      this.ISCCaseObjectAddEdit.SecondLetterSent = l_arrCaseResponse[0].SecondLetterSent;
      this.ISCCaseObjectAddEdit.ThirdLetterSent = l_arrCaseResponse[0].ThirdLetterSent;
      //this.ISCCaseObjectAddEdit.AddressVerifyLetterSent = l_arrCaseResponse[0].AddressVerifyLetterSent;
      //this.ISCCaseObjectAddEdit.TallaqRequestFormSent = l_arrCaseResponse[0].TallaqRequestFormSent;
      this.ISCCaseObjectAddEdit.AddressVerifyLetterSent = false;
      this.ISCCaseObjectAddEdit.TallaqRequestFormSent = false;
      this.ISCCaseObjectAddEdit.IDProvider = l_arrCaseResponse[0].IDProvider;
      this.ISCCaseObjectAddEdit.DivorceReason = l_arrCaseResponse[0].DivorceReason;

      this.ISCCaseObjectAddEdit.DidSpouseReply = l_arrCaseResponse[0].DidSpouseReply;
      this.ISCCaseObjectAddEdit.PlaceOfNikkah = l_arrCaseResponse[0].PlaceOfNikkah;
      this.ISCCaseObjectAddEdit.DateOfNikkah = l_arrCaseResponse[0].DateOfNikkah;
      this.ISCCaseObjectAddEdit.PlaceOfRegistrationInUK = l_arrCaseResponse[0].PlaceOfRegistrationInUK;

      if (l_arrCaseResponse[0].DateOfNikkah == "01/01/0001 00:00:00") {
        this.ISCCaseObjectAddEdit.DateOfNikkah = '';
      } else {
        this.ISCCaseObjectAddEdit.DateOfNikkah = l_arrCaseResponse[0].DateOfNikkah;
      }

      this.ISCCaseObjectAddEdit.HaveYouSponsoredYourSpouse = l_arrCaseResponse[0].HaveYouSponsoredYourSpouse;
      this.ISCCaseObjectAddEdit.ThisCountryEntryDate = l_arrCaseResponse[0].ThisCountryEntryDate;
      this.ISCCaseObjectAddEdit.HaveYouMarriedBefore = l_arrCaseResponse[0].HaveYouMarriedBefore;
      this.ISCCaseObjectAddEdit.DateOfDecreeAbsolute = l_arrCaseResponse[0].DateOfDecreeAbsolute;
      this.ISCCaseObjectAddEdit.CommentOrRemarks = l_arrCaseResponse[0].CommentOrRemarks;


      this.ISCCaseObjectAddEdit.CaseStatus = l_arrCaseResponse[0].CaseStatus;
      this.ISCCaseObjectAddEdit.AssignedTo = l_arrCaseResponse[0].AssignedTo;

      if (this.ISCCaseObjectAddEdit.CaseStatus == 0)
        this.ISCCaseObjectAddEdit.CaseStatus = undefined;
      if (this.ISCCaseObjectAddEdit.AssignedTo == 0)
        this.ISCCaseObjectAddEdit.AssignedTo = undefined;

      this.GetNationality()


      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));


    this.OtherEntityID = p_CaseID;

    if (this.Messaging) {
      this.Messaging.AjaxLoadMessages();
    }
    this.GetCaseReminderData(data.ISCCaseID);

  }

  public GetNationality() {
    debugger;

    var l_ClientNationality = this.Default.FilterJsonExactlyMatching(this.CountryDropdown, 'ID', this.ISCClientObject.NationalityID);
    var l_ClientSpouseNationality = this.Default.FilterJsonExactlyMatching(this.CountryDropdown, 'ID', this.ISCClientSpouseObject.NationalityID);


    if (this.ISCCaseObjectAddEdit.ISCCaseTypeIDToAdd == 1) { //Khula Case
      //Client Information
      this.ISCCaseObjectAddEdit.WifeNationality = l_ClientNationality[0].Name;
      //Spouse Information
      this.ISCCaseObjectAddEdit.HusbandNationality = l_ClientSpouseNationality[0].Name;
  
    }
    if (this.ISCCaseObjectAddEdit.ISCCaseTypeIDToAdd == 2) { //Talaq Case
      //Client Information
      this.ISCCaseObjectAddEdit.HusbandNationality = l_ClientNationality[0].Name;
      //Spouse Information
      this.ISCCaseObjectAddEdit.WifeNationality = l_ClientSpouseNationality[0].Name;
    }

  }

  public UpdateCaseInfo() {
    this.Default.SetMasterAnimation(true);
    let data = {} as any;
    data.ISCPerson = {} as any;
    data.ISCCasePaymentsArray = [];
    data.ISCCase = {} as any;

    for (let field in this.ISCClientSpouseObject) {
      if (this.ISCClientSpouseObject[field] !== undefined && this.ISCClientSpouseObject[field] != null && this.ISCClientSpouseObject[field] != '')
        data.ISCPerson[field] = this.ISCClientSpouseObject[field];
    }


    for (var i = 0; i < this.ISCCasePaymentArray.length; i++) {

      var casePayment = {} as any;
      var iscCasePaymentObject = this.ISCCasePaymentArray[i];

      for (let field in iscCasePaymentObject) {
        if (iscCasePaymentObject[field] !== undefined && iscCasePaymentObject[field] != null && iscCasePaymentObject[field] != '')
          casePayment[field] = iscCasePaymentObject[field];
      }

      data.ISCCasePaymentsArray.push(casePayment);
    }



    for (let field in this.ISCCaseObjectAddEdit) {
      if (this.ISCCaseObjectAddEdit[field] !== undefined && this.ISCCaseObjectAddEdit[field] != null && this.ISCCaseObjectAddEdit[field] != '')
        data.ISCCase[field] = this.ISCCaseObjectAddEdit[field];
    }
    data.ISCPerson.ISCClientID = this.ClientID;
    data.ISCCase.ISCClientID = this.ClientID;



    this.http.post('api/ISCCase/ISCCaseCompleteSave', data).subscribe(result => {
      var reponse = result[0];

      this.Default.ShowAlert(reponse.ModalMessage);
      this.CancelRecord();
      this.GetCaseData();
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }

  public DeleteCaseData(p_CaseID) {

    if (this.CaseIdToDelete > 0) {
      let data = {} as any;
      data.ISCCaseID = p_CaseID;
      this.Default.SetMasterAnimation(true);
      this.http.post(this.baseUrl + 'api/ISCCase/ISCCaseArchiveRecord', data).subscribe(result => {
        var l_arrResponse = result[0] as any;
        this.Default.ShowAlert(l_arrResponse.Message);
        this.CancelRecord();
        this.GetCaseData();
        this.Default.SetMasterAnimation(false);

        this.CaseIdToDelete = -1;
      }, error => console.error(error));
    }

  }

  public SetDeletableCaseID(p_CaseID) {
    this.CaseIdToDelete = p_CaseID;
  }


  public SaveMultipleCaseReminderData() {
    let data = {} as any;
    let ISCCaseReminders: any;
    this.ISCCaseRemindersList = [];
    this.Default.SetMasterAnimation(true);

    for (var i = 0; i < this.GridCaseRemindersData.length; i++) {
      ISCCaseReminders = {} as any;

      for (let field in this.GridCaseRemindersData[i]) {
        ISCCaseReminders.ISCCaseRemindersID = this.GridCaseRemindersData[i]["ISCCaseRemindersID"] == "" || this.GridCaseRemindersData[i]["ISCCaseRemindersID"] == null ? 0 : this.GridCaseRemindersData[i]["ISCCaseRemindersID"];
        ISCCaseReminders.ISCCaseProcedureID = this.GridCaseRemindersData[i]["ISCCaseProcedureID"];
        ISCCaseReminders.ISCCaseID = this.GridCaseRemindersData[i]["ISCCaseID"];
        ISCCaseReminders.ExpectedDateOfReminder = this.GridCaseRemindersData[i]["ExpectedDateOfReminder"] == "" ? this.Default.minDate : this.GridCaseRemindersData[i]["ExpectedDateOfReminder"];
        ISCCaseReminders.Status = this.GridCaseRemindersData[i]["Status"];
        ISCCaseReminders.SubsequentDateOfReminder = this.GridCaseRemindersData[i]["SubsequentDateOfReminder"] == "" ? this.Default.minDate : this.GridCaseRemindersData[i]["SubsequentDateOfReminder"];
        ISCCaseReminders.EmailTo = this.GridCaseRemindersData[i]["EmailTo"];
        ISCCaseReminders.EmailCC = this.GridCaseRemindersData[i]["EmailCC"];
        ISCCaseReminders.LastDateSent = this.GridCaseRemindersData[i]["LastDateSent"] == "" ? this.Default.minDate : this.GridCaseRemindersData[i]["LastDateSent"];
      }
      this.ISCCaseRemindersList.push(ISCCaseReminders);

    }


    data.ISCCaseRemindersList = this.ISCCaseRemindersList;
    this.http.post(this.baseUrl + 'api/ISCCaseReminders/ISCCaseRemindersMultipleSave', data).subscribe(result => {
      var l_arrResponse = result as any;
      var reponse = l_arrResponse;
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }

  public SendNotification(p_CaseID, p_CaseProcedureID) {
    let data = {} as any;
    data.ISCCaseID = p_CaseID;
    data.ISCCaseProcedureID = p_CaseProcedureID;
    this.Default.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/ISCCaseProcedure/SendNotification', data).subscribe(result => {
      var l_arrResponse = result[0] as any;
      this.Default.SetMasterAnimation(false);
      this.Default.ShowAlert(l_arrResponse.Message);
    }, error => console.error(error));
  }

  public GetCheckBoxValues(p_CaseID, p_CaseProcedureID, event) {
    let itemObject = {} as any;
    if (event.target.checked) {
      itemObject.ISCCaseID = p_CaseID;
      itemObject.ISCCaseProcedureID = p_CaseProcedureID;
      this.ISCList.push(itemObject);
    } else {
      for (var i = 0; i < this.ISCList.length; i++) {
        if (this.ISCList[i].ISCCaseProcedureID == p_CaseProcedureID) {
          this.ISCList.splice(i, 1);
        }
      }
    }
    if (this.ISCList.length >= 2) {
      this.SendMultipleButton = true;
    } else {
      this.SendMultipleButton = false;
    }
  }

  public SendMultipleNotification() {
    let data = {} as any;
    data.ISCList = this.ISCList;
    this.Default.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/ISCCaseProcedure/SendMultipleNotification', data).subscribe(result => {
      var l_arrResponse = result as any;
      this.ISCList = [];
      var reponse = l_arrResponse;
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }

  public HideDivs(CaseDiv) {
    this.IsEdit = true;
    if (CaseDiv == 'CaseDetail') {
      this.CaseDetail = true;
      this.CasePayment = false;
      this.FileLocation = false;
      this.CaseReminderStatus = false;
    }
    if (CaseDiv == 'CasePayment') {
      this.CaseDetail = false;
      this.CasePayment = true;
      this.FileLocation = false;
      this.CaseReminderStatus = false;
    }
    if (CaseDiv == 'FileLocation') {
      this.CaseDetail = false;
      this.CasePayment = false;
      this.FileLocation = true;
      this.CaseReminderStatus = false;
    }
    if (CaseDiv == 'CaseReminderStatus') {
      this.CaseDetail = false;
      this.CasePayment = false;
      this.FileLocation = false;
      this.CaseReminderStatus = true;
    }

  }

  public DownloadPDF(p_ProcedureId: number, p_CaseId: number, p_Name: string, p_isHeader: boolean, p_htmlContent: string) {
    let data = {} as any;
    data.ISCCaseProcedureID = p_ProcedureId;
    data.ISCCaseID = p_CaseId;
    data.IsHeader = p_isHeader;
    data.FileName = p_Name.trim();
    data.HTMLContent = p_htmlContent;

    this.Default.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/ISCCaseProcedure/ExportPDF', data).subscribe(result => {
      var l_arrResponse = result as any;
      this.Default.SetMasterAnimation(false);

      //if (l_arrResponse && l_arrResponse[0].Message.indexOf('.pdf') > -1) {
      //  var l_strMessage = "<center><h4>Data has been exported to file successfully!</h4></center>";
      //  // l_strMessage += "<p>Please click <a target='_blank' href='" + l_strResponse + "'>here</a> to download</p>";
      //  l_strMessage += "<center><p>Please click <a id='btn1' download href='" + l_arrResponse[0].Message + "'>here</a> to download.</p></center>";
      //  this.Default.ShowAlert(l_strMessage);
      //}
      //else {
      //  this.Default.ShowAlert("This is some issue in downloading file");
      //}

      var downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      downloadLink.href = l_arrResponse[0].Message;

      // Setting the file name
      downloadLink.download = p_Name.trim();

      //triggering the function
      downloadLink.click();


      document.body.removeChild(downloadLink);

      //window.open(l_arrResponse[0].Message, "resizeable,scrollbar");
    }, error => console.error(error));
  }

  public GettingTemplateForWordExport(p_ProcedureId: number, p_CaseId: number, p_Name: string) {
    let data = {} as any;
    data.ISCCaseProcedureID = p_ProcedureId;
    data.ISCCaseID = p_CaseId;
    data.FileName = p_Name.trim();
    this.Default.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/ISCCaseProcedure/GetPopulatedTemplateForNotification', data).subscribe(result => {
      var l_arrResponse = result[0] as any;
      this.htmlContent = l_arrResponse.Message;
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }
  public ISCCaseProcedureForCaseID = 0;

  public SaveCaseProcedureTemplateForCase(p_HtmlContent) {
    let data = {} as any;
    data.ISCCaseProcedureForCaseID = this.ISCCaseProcedureForCaseID;
    data.ISCCaseProcedureTemplate = p_HtmlContent;
    this.Default.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/ISCCaseProcedure/SaveCaseProcedureTemplateForCase', data).subscribe(result => {
      var l_arrResponse = result[0] as any;
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }

  public SyncCaseProcedureTemplate(p_index) {
    // this.CheckedSyncAll();
    let data = {} as any;
    data.ISCCaseProcedureForCaseID = this.ISCCaseProcedureForCaseID;
    this.Default.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/ISCCaseProcedure/SyncCaseProcedureTemplate', data).subscribe(result => {
      var l_arrResponse = result[0] as any;
      //this.Default.SetMasterAnimation(false);
      this.NeedToSyncAll = false;
      this.GridCaseRemindersData[p_index].NeedToSync = false;
      for (var i = 0; i < this.GridCaseRemindersData.length; i++) {
        if (this.GridCaseRemindersData[i].NeedToSync == true) {
          this.NeedToSyncAll = true;
        }
      }
      this.GetCaseReminderData(this.ISCCaseProcedureCaseID);

    }, error => console.error(error));
  }

  public StrISCCaseProcedureForCaseIDs = '';

  public CheckedSyncAll() {
    var ISCCaseProcedureForCaseIDs = '';
    for (var i = 0; i < this.GridCaseRemindersData.length; i++) {
      if (this.GridCaseRemindersData[i].NeedToSync == true) {
        this.NeedToSyncAll = true;
        if (ISCCaseProcedureForCaseIDs == '')
          ISCCaseProcedureForCaseIDs = this.GridCaseRemindersData[i].ISCCaseProcedureForCaseID;
        else
          ISCCaseProcedureForCaseIDs += ',' + this.GridCaseRemindersData[i].ISCCaseProcedureForCaseID;
      }
    }

    this.SyncAllCases(ISCCaseProcedureForCaseIDs);
  }

  public SyncAllCases(p_ISCCaseProcedureForCaseIDs) {
    let data = {} as any;
    data.ISCCaseProcedureForCaseIDs = p_ISCCaseProcedureForCaseIDs;
    this.Default.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/ISCCaseProcedure/SyncAllCaseProcedureTemplate', data).subscribe(result => {
      var l_arrResponse = result[0] as any;
      //this.Default.SetMasterAnimation(false);
      this.NeedToSyncAll = false;
      for (var i = 0; i < this.GridCaseRemindersData.length; i++) {
        this.GridCaseRemindersData[i].NeedToSync = false;
      }
      this.GetCaseReminderData(this.ISCCaseProcedureCaseID);

    }, error => console.error(error));
  }

  public GetNameID(p_ExportISCCaseProcedureID, p_ExportISCCaseProcedureName, p_ExportISCCaseID) {
    this.ExportISCCaseProcedureID = p_ExportISCCaseProcedureID;
    //this.ExportISCCaseProcedureName = 'file';
    this.ExportISCCaseProcedureName = p_ExportISCCaseProcedureName;
    this.ExportISCCaseID = p_ExportISCCaseID;
  }

  public GetListDataForPagination(p_boolRememberFilter: boolean) {
    this.pagination = this.GridDataDataAfterFiltering.slice((this.currentPage - 1) * this.itemsPerPage, (this.currentPage) * this.itemsPerPage);
    this.totalItems = this.GridDataDataAfterFiltering.length;
    if (this.pagination.length == 0 && this.totalItems > 0 && this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      this.GetListDataForPagination(p_boolRememberFilter);
    }
    if (this.totalItems > 0) {
      this.ItemsPerPageRange[0] = ((this.currentPage - 1) * this.itemsPerPage) + 1;
      this.ItemsPerPageRange[1] = (this.ItemsPerPageRange[0] + this.pagination.length) - 1;
    }
    else {
      this.ItemsPerPageRange[0] = ((this.currentPage - 1) * this.itemsPerPage);
      this.ItemsPerPageRange[1] = (this.ItemsPerPageRange[0] + this.pagination.length);
    }
    //if (p_boolRememberFilter === false)
    //  //this.UserTrackingForFilter = [] as any;
  }

  public GetListDataForPaginationClient(p_boolRememberFilter: boolean) {
    this.paginationClient = this.GridDataDataAfterFilteringClient.slice((this.currentPageClient - 1) * this.itemsPerPageClient, (this.currentPageClient) * this.itemsPerPageClient);
    this.totalItemsClient = this.GridDataDataAfterFilteringClient.length;
    if (this.paginationClient.length == 0 && this.totalItemsClient > 0 && this.currentPageClient > 1) {
      this.currentPageClient = this.currentPageClient - 1;
      this.GetListDataForPaginationClient(p_boolRememberFilter);
    }
    if (this.totalItemsClient > 0) {
      this.ItemsPerPageRangeClient[0] = ((this.currentPageClient - 1) * this.itemsPerPageClient) + 1;
      this.ItemsPerPageRangeClient[1] = (this.ItemsPerPageRangeClient[0] + this.paginationClient.length) - 1;
    }
    else {
      this.ItemsPerPageRangeClient[0] = ((this.currentPageClient - 1) * this.itemsPerPageClient);
      this.ItemsPerPageRangeClient[1] = (this.ItemsPerPageRangeClient[0] + this.paginationClient.length);
    }
    //if (p_boolRememberFilter === false)
    //  //this.UserTrackingForFilter = [] as any;
  }

  public GetCaseRiminderForm(P_ISCCaseID) {
    this.HideDivs('CaseReminderStatus');
    this.GetCaseDataForEdit(P_ISCCaseID);
  }

  public Export2Doc(element, filename = '') {

    var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";
    var html = preHtml + element + postHtml;

    //MR    
    //var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><title></title>";
    //var styleHTML = "<!--[if gte mso 9]><xml><w:WordDocument><w:View>Print</w:View><w:Zoom>100</w:Zoom><w:DoNotOptimizeForBrowser/></w:WordDocument></xml><![endif]--><style>p.MsoFooter, li.MsoFooter, div.MsoFooter{margin:0in;margin-bottom:.0001pt;mso-pagination:widow-orphan;tab-stops:center 3.0in right 6.0in;font-size:12.0pt;}<style><!-- @page Section1{size:8.5in 11.0in;margin:1.0in 1.25in 1.0in 1.25in ;mso-header-margin:0in;mso-header:h1;mso-footer: f1;mso-footer-margin:0in;}div.Section1{page:Section1;}table#hrdftrtbl{margin:0in 0in 0in 9in;}--></style></head><body lang=EN-US style='tab-interval:0in'>";
    //var headerFooter = "<table id='hrdftrtbl' border='1' cellspacing='0' cellpadding='0'><tr><td><div style='mso-element:header' id=h1 ><p class=MsoHeader style='text-align:left'><img style='height:100px; width:400px' src='" + this.baseUrl + "/assets/img/islamic.png' ></p></div></td><td><div style='mso-element:footer' id=f1><p class=MsoFooter>Islamic Sharia Council<span style=mso-tab-count:2'></span><span style='mso-field-code:\"\" PAGE \"\"'></span><span style='mso-field-code:\"\" NUMPAGES \"\"'></span></p></div>/td></tr></table>";
    //var htmlContent = "<div class=Section1>" + element + "</div>" + headerFooter + "</body></html>";
    //var html = preHtml + styleHTML + htmlContent;

    var blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
    });

    // Specify link url
    var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

    // Specify file name
    filename = filename ? filename + '.doc' : 'document.doc';

    // Create download link element
    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }

  public formatDate(date) {
    let formatedDate = new DatePipe('en-GB').transform(date, 'dd/mm/yyyy')
    console.log(formatedDate);
    return formatedDate;
  }

  public ExpandAll() {
    document.getElementById('collapseOne').setAttribute('class', 'collapse multi-collapse');
    document.getElementById('collapseOneClientSpouse').setAttribute('class', 'collapse multi-collapse');
    document.getElementById('collapseOneClientDetail').setAttribute('class', 'collapse multi-collapse');
    document.getElementById('collapseOneCasePayment').setAttribute('class', 'collapse multi-collapse');
    document.getElementById('collapseOneCaseFileLocation').setAttribute('class', 'collapse multi-collapse');
    document.getElementById('collapseOneClientReminderStatus').setAttribute('class', 'collapse multi-collapse');
  }

  public CollapseAll() {
    document.getElementById('collapseOne').setAttribute('class', 'collapse multi-collapse show');
    document.getElementById('collapseOneClientSpouse').setAttribute('class', 'collapse multi-collapse show');
    document.getElementById('collapseOneClientDetail').setAttribute('class', 'collapse multi-collapse show');
    document.getElementById('collapseOneCasePayment').setAttribute('class', 'collapse multi-collapse show');
    document.getElementById('collapseOneCaseFileLocation').setAttribute('class', 'collapse multi-collapse show');
    document.getElementById('collapseOneClientReminderStatus').setAttribute('class', 'collapse multi-collapse show');
  }

  public SaveCaseDate(p_oevent, p_Date) {
    var Value = p_oevent.target.value;
    if (p_Date == 'DateOfApplication')
      this.ISCCaseObjectAddEdit.DateOfApplication = Value;
    if (p_Date == 'DateOfBirth')
      this.ISCClientSpouseObject.DateOfBirth = Value;
    if (p_Date == 'DateOfMarriage')
      this.ISCCaseObjectAddEdit.DateOfMarriage = Value;
    if (p_Date == 'DateOfSeperation')
      this.ISCCaseObjectAddEdit.DateOfSeperation = Value;
    if (p_Date == 'DateOfNikkah')
      this.ISCCaseObjectAddEdit.DateOfNikkah = Value;
    if (p_Date == 'ThisCountryEntryDate')
      this.ISCCaseObjectAddEdit.ThisCountryEntryDate = Value;
    if (p_Date == 'DateOfDecreeAbsolute')
      this.ISCCaseObjectAddEdit.DateOfDecreeAbsolute = Value;
    if (p_Date == 'DateOfDisolution')
      this.ISCCaseObjectAddEdit.DateOfDisolution = Value;
    if (p_Date == 'DivorceDate')
      this.ISCCaseObjectAddEdit.DivorceDate = Value;
  }

  public SaveCaseDateIndex(p_oevent, p_index, p_Date) {
    var Value = p_oevent.target.value;
    if (p_Date == 'DateOfInvoice')
      this.ISCCasePaymentArray[p_index].DateOfInvoice = Value;
    if (p_Date == 'DateOfPayment')
      this.ISCCasePaymentArray[p_index].DateOfPayment = Value;
    if (p_Date == 'ExpectedDateOfReminder')
      this.GridCaseRemindersData[p_index].ExpectedDateOfReminder = Value;
    if (p_Date == 'LastDateSent')
      this.GridCaseRemindersData[p_index].LastDateSent = Value;
  }

  public AddNewStatus() {
    if (this.ISCCaseObjectAddEdit.CaseStatus == 7) {
      this.IsNewStatus = true;
    } else {
      this.IsNewStatus = false;
    }
  }

  public AddValueInStatusList() {
    let data = {} as any;
    data.NewStatus = this.ISCCaseObjectAddEdit.NewStatus;
    this.Default.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/ISCStatus/ISCStatusAddNewStatusInTheList', data).subscribe(result => {
      var l_arrResponse = result[0] as any;

      this.ISCCaseStatusList = l_arrResponse;
      if (this.ISCCaseStatusList.length > 2) {
        this.ISCCaseObjectAddEdit.CaseStatus = this.ISCCaseStatusList[this.ISCCaseStatusList.length - 2].ISCStatusID;
      }
      this.IsNewStatus = false
      this.Default.SetMasterAnimation(false);
    }, error => console.error(error));
  }

  public IsTherePendingMessage = false;

  public PendingMessage(data) {
    this.IsTherePendingMessage = data;
    if (this.IsTherePendingMessage == false) {
      this.ShowErrors[50000] = '';
    }
    //console.log(this.IsTherePendingMessage);
  }


  public Message;
  public FileURL;
  public FileName;

  public DownloadInvoicePDF(p_boolIsFullLetter) {
    let data = {} as any;
    //Missing Information
    data.DivorceDate = new Date();
    data.HusbandNationality = '';
    data.WifeNationality = '';
    data.ISCCaseTypeID = 0;
    data.CaseNumber = 0;
    data.WifeName = '';
    data.WifePlaceOfBirth = '';
    data.WifeDatePlaceofBirth = '';
    data.WifeAddress = '';
    data.WifePostCode = '';
    data.HusbandName = '';
    data.HusbandPlaceOfBirth = '';
    data.HusbandDatePlaceOfBirth = '';
    data.HusbandAddress = '';
    data.HusbandPostCode = '';
    data.IsFullLetter = p_boolIsFullLetter;
    data.DivorceIsConsider = this.ISCCaseObjectAddEdit.DivorceIsConsider;// 1,2,3
    data.IsAbsoluteIrrevocable = this.ISCCaseObjectAddEdit.IsAbsoluteIrrevocable;// 1,2
    if (this.ISCCaseObjectAddEdit.DivorceDate && this.ISCCaseObjectAddEdit.DivorceDate != null && this.ISCCaseObjectAddEdit.DivorceDate != '')
      data.DivorceDate = this.ISCCaseObjectAddEdit.DivorceDate;

    //General Information
    data.ISCCaseTypeID = this.ISCCaseObjectAddEdit.ISCCaseTypeIDToAdd;
    data.CaseNumber = this.ISCCaseObjectAddEdit.ReferenceNumber;
    debugger
    if (this.ISCCaseObjectAddEdit.ISCCaseTypeIDToAdd == 1) { //Khula Case
      //Client Information
      data.WifeName = this.ISCClientObject.Name;
     // data.WifeNationality = this.ISCClientObject.NationalityID;
      if (this.ISCSpouseAddressArray && this.ISCSpouseAddressArray != null && this.ISCSpouseAddressArray.length > 0) {

        data.WifePlaceOfBirth = this.ISCSpouseAddressArray[0].PlaceOfBirth;
        var l_WifeDateOfBirth = new Date(this.ISCSpouseAddressArray[0].DateOfBirth);
        debugger;
        data.WifeDatePlaceofBirth = l_WifeDateOfBirth.getDate() + "/" + (l_WifeDateOfBirth.getMonth() + 1) + "/" + l_WifeDateOfBirth.getFullYear() + ", " + this.ISCSpouseAddressArray[0].PlaceOfBirth;
        if (this.ISCSpouseAddressArray[0].City && this.ISCSpouseAddressArray[0].City != null && this.ISCSpouseAddressArray[0].City != '') {

          if (this.ISCSpouseAddressArray[0].Line2 != "") {
            data.WifeAddress = this.ISCSpouseAddressArray[0].Line1 + ", " + this.ISCSpouseAddressArray[0].Line2 + ", " + this.ISCSpouseAddressArray[0].City;
          }
          if (this.ISCSpouseAddressArray[0].Line3 != "") {
            data.WifeAddress = this.ISCSpouseAddressArray[0].Line1 + ", " + this.ISCSpouseAddressArray[0].Line2 + ", " + this.ISCSpouseAddressArray[0].Line3 + ", " + this.ISCSpouseAddressArray[0].City;
          }

          if (this.ISCSpouseAddressArray[0].Line2 == "" && this.ISCSpouseAddressArray[0].Line3 == "") {
            data.WifeAddress = this.ISCSpouseAddressArray[0].Line1 + ", " + this.ISCSpouseAddressArray[0].City;
          }
        } else {

          if (this.ISCSpouseAddressArray[0].Line2 != "") {
            data.WifeAddress = this.ISCSpouseAddressArray[0].Line1 + ", " + this.ISCSpouseAddressArray[0].Line2;
          }
          if (this.ISCSpouseAddressArray[0].Line3 != "") {
            data.WifeAddress = this.ISCSpouseAddressArray[0].Line1 + ", " + this.ISCSpouseAddressArray[0].Line2 + ", " + this.ISCSpouseAddressArray[0].Line3;
          }

          if (this.ISCSpouseAddressArray[0].Line2 == "" && this.ISCSpouseAddressArray[0].Line3 == "") {
            data.WifeAddress = this.ISCSpouseAddressArray[0].Line1
          }

        }
        data.WifePostCode = this.ISCSpouseAddressArray[0].PostCode;
      }
      //Spouse Information
      data.HusbandName = this.ISCClientSpouseObject.FirstName + " " + this.ISCClientSpouseObject.LastName;
      data.HusbandPlaceOfBirth = this.ISCClientSpouseObject.PlaceOfBirth;
     // data.HusbandNationality = this.ISCClientSpouseObject.NationalityID;
      var l_HusbandDateOfBirth = new Date(this.ISCClientSpouseObject.DateOfBirth);
      data.HusbandDatePlaceOfBirth = l_HusbandDateOfBirth.getDate() + "/" + (l_HusbandDateOfBirth.getMonth() + 1) + "/" + l_HusbandDateOfBirth.getFullYear() + ", " + this.ISCClientSpouseObject.PlaceOfBirth;
      if (this.ISCAddressArray && this.ISCAddressArray != null && this.ISCAddressArray.length > 0) {
        if (this.ISCAddressArray && this.ISCAddressArray[0].City != null && this.ISCAddressArray[0].City != '') {
        /*  data.HusbandAddress = this.ISCAddressArray[0].Line1 + ", " + this.ISCAddressArray[0].City;*/

          if (this.ISCAddressArray[0].Line2 != "") {
            data.HusbandAddress = this.ISCAddressArray[0].Line1 + ", " + this.ISCAddressArray[0].Line2 + ", " + this.ISCAddressArray[0].City;
          }
          if (this.ISCAddressArray[0].Line3 != "") {
            data.HusbandAddress = this.ISCAddressArray[0].Line1 + ", " + this.ISCAddressArray[0].Line2 + ", " + this.ISCAddressArray[0].Line3 + ", " + this.ISCAddressArray[0].City;
          }

          if (this.ISCAddressArray[0].Line2 == "" && this.ISCAddressArray[0].Line3 == "") {
            data.HusbandAddress = this.ISCAddressArray[0].Line1 + ", " + this.ISCAddressArray[0].City;
          }


        } else {
          //data.HusbandAddress = this.ISCAddressArray[0].Line1;

          if (this.ISCAddressArray[0].Line2 != "") {
            data.HusbandAddress = this.ISCAddressArray[0].Line1 + ", " + this.ISCAddressArray[0].Line2;
          }
          if (this.ISCAddressArray[0].Line3 != "") {
            data.HusbandAddress = this.ISCAddressArray[0].Line1 + ", " + this.ISCAddressArray[0].Line2 + ", " + this.ISCAddressArray[0].Line3;
          }

          if (this.ISCAddressArray[0].Line2 == "" && this.ISCAddressArray[0].Line3 == "") {
            data.HusbandAddress = this.ISCAddressArray[0].Line1;
          }


        }
        data.HusbandPostCode = this.ISCAddressArray[0].PostCode;
      }
    }
    if (this.ISCCaseObjectAddEdit.ISCCaseTypeIDToAdd == 2) { //Talaq Case
      //Client Information
      data.HusbandName = this.ISCClientObject.Name;
      //data.HusbandNationality = this.ISCClientObject.NationalityID;
      if (this.ISCSpouseAddressArray && this.ISCSpouseAddressArray != null && this.ISCSpouseAddressArray.length > 0) {
        data.HusbandPlaceOfBirth = this.ISCSpouseAddressArray[0].PlaceOfBirth;
        var l_HusbandDateOfBirth = new Date(this.ISCSpouseAddressArray[0].DateOfBirth);
        data.HusbandDatePlaceOfBirth = l_HusbandDateOfBirth.getDate() + "/" + (l_HusbandDateOfBirth.getMonth() + 1) + "/" + l_HusbandDateOfBirth.getFullYear() + ", " + this.ISCSpouseAddressArray[0].PlaceOfBirth;
        if (this.ISCSpouseAddressArray[0].City && this.ISCSpouseAddressArray[0].City != null && this.ISCSpouseAddressArray[0].City != '') {
          //data.HusbandAddress = this.ISCSpouseAddressArray[0].Line1 + ", " + this.ISCSpouseAddressArray[0].City;

          if (this.ISCSpouseAddressArray[0].Line2 != "") {
            data.HusbandAddress = this.ISCSpouseAddressArray[0].Line1 + ", " + this.ISCSpouseAddressArray[0].Line2 + ", " + this.ISCSpouseAddressArray[0].City;
          }
          if (this.ISCSpouseAddressArray[0].Line3 != "") {
            data.HusbandAddress = this.ISCSpouseAddressArray[0].Line1 + ", " + this.ISCSpouseAddressArray[0].Line2 + ", " + this.ISCSpouseAddressArray[0].Line3 + ", " + this.ISCSpouseAddressArray[0].City;
          }

          if (this.ISCSpouseAddressArray[0].Line2 == "" && this.ISCSpouseAddressArray[0].Line3 == "") {
            data.HusbandAddress = this.ISCSpouseAddressArray[0].Line1 + ", " + this.ISCSpouseAddressArray[0].City;
          }


        } else {

          if (this.ISCSpouseAddressArray[0].Line2 != "") {
            data.HusbandAddress = this.ISCSpouseAddressArray[0].Line1 + ", " + this.ISCSpouseAddressArray[0].Line2;
          }
          if (this.ISCSpouseAddressArray[0].Line3 != "") {
            data.HusbandAddress = this.ISCSpouseAddressArray[0].Line1 + ", " + this.ISCSpouseAddressArray[0].Line2 + ", " + this.ISCSpouseAddressArray[0].Line3;
          }

          if (this.ISCSpouseAddressArray[0].Line2 == "" && this.ISCSpouseAddressArray[0].Line3 == "") {
            data.HusbandAddress = this.ISCSpouseAddressArray[0].Line1;
          }
        }
        data.HusbandPostCode = this.ISCSpouseAddressArray[0].PostCode;
      }
      //Spouse Information
      data.WifeName = this.ISCClientSpouseObject.FirstName + " " + this.ISCClientSpouseObject.LastName;
      data.WifePlaceOfBirth = this.ISCClientSpouseObject.PlaceOfBirth;
      //data.WifeNationality = this.ISCClientSpouseObject.NationalityID;
      var l_WifeDateOfBirth = new Date(this.ISCClientSpouseObject.DateOfBirth);
      data.WifeDatePlaceofBirth = l_WifeDateOfBirth.getDate() + "/" + (l_WifeDateOfBirth.getMonth() + 1) + "/" + l_WifeDateOfBirth.getFullYear() + ", " + this.ISCClientSpouseObject.PlaceOfBirth;
      if (this.ISCAddressArray && this.ISCAddressArray != null && this.ISCAddressArray.length > 0) {
        if (this.ISCAddressArray && this.ISCAddressArray[0].City != null && this.ISCAddressArray[0].City != '') {
          /* data.WifeAddress = this.ISCAddressArray[0].Line1 + ", " + this.ISCAddressArray[0].City;*/

          if (this.ISCAddressArray[0].Line2 != "") {
            data.WifeAddress = this.ISCAddressArray[0].Line1 + ", " + this.ISCAddressArray[0].Line2 + ", " + this.ISCAddressArray[0].City;
          }
          if (this.ISCAddressArray[0].Line3 != "") {
            data.WifeAddress = this.ISCAddressArray[0].Line1 + ", " + this.ISCAddressArray[0].Line2 + ", " + this.ISCAddressArray[0].Line3 + ", " + this.ISCAddressArray[0].City;
          }

          if (this.ISCAddressArray[0].Line2 == "" && this.ISCAddressArray[0].Line3 == "") {
            data.WifeAddress = this.ISCAddressArray[0].Line1 + ", " + this.ISCAddressArray[0].City;
          }


        } else {
          //data.WifeAddress = this.ISCAddressArray[0].Line1;

          if (this.ISCAddressArray[0].Line2 != "") {
            data.WifeAddress = this.ISCAddressArray[0].Line1 + ", " + this.ISCAddressArray[0].Line2;
          }
          if (this.ISCAddressArray[0].Line3 != "") {
            data.WifeAddress = this.ISCAddressArray[0].Line1 + ", " + this.ISCAddressArray[0].Line2 + ", " + this.ISCAddressArray[0].Line3;
          }

          if (this.ISCAddressArray[0].Line2 == "" && this.ISCAddressArray[0].Line3 == "") {
            data.WifeAddress = this.ISCAddressArray[0].Line1;
          }

        }
        data.WifePostCode = this.ISCAddressArray[0].PostCode;
      }
    }

    if (this.ISCCaseObjectAddEdit.HusbandNationality && this.ISCCaseObjectAddEdit.HusbandNationality != null && this.ISCCaseObjectAddEdit.HusbandNationality != '')
      data.HusbandNationality = this.ISCCaseObjectAddEdit.HusbandNationality;
    if (this.ISCCaseObjectAddEdit.WifeNationality && this.ISCCaseObjectAddEdit.WifeNationality != null && this.ISCCaseObjectAddEdit.WifeNationality != '')
      data.WifeNationality = this.ISCCaseObjectAddEdit.WifeNationality;


    data.CaseNumber = this.IsStringNullOrUndefined(data.CaseNumber);
    data.HusbandNationality = this.IsStringNullOrUndefined(data.HusbandNationality);
    data.WifeNationality = this.IsStringNullOrUndefined(data.WifeNationality);
    data.WifeName = this.IsStringNullOrUndefined(data.WifeName);
    data.WifePlaceOfBirth = this.IsStringNullOrUndefined(data.WifePlaceOfBirth);
    data.WifeDatePlaceofBirth = this.IsStringNullOrUndefined(data.WifeDatePlaceofBirth);
    data.WifeAddress = this.IsStringNullOrUndefined(data.WifeAddress);
    data.WifePostCode = this.IsStringNullOrUndefined(data.WifePostCode);
    data.HusbandName = this.IsStringNullOrUndefined(data.HusbandName);
    data.HusbandPlaceOfBirth = this.IsStringNullOrUndefined(data.HusbandPlaceOfBirth);
    data.HusbandDatePlaceOfBirth = this.IsStringNullOrUndefined(data.HusbandDatePlaceOfBirth);
    data.HusbandAddress = this.IsStringNullOrUndefined(data.HusbandAddress);
    data.HusbandPostCode = this.IsStringNullOrUndefined(data.HusbandPostCode);

    debugger;

    this.Default.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/ISCStatus/DownloadInvoicePDF', data).subscribe(result => {

      this.Default.SetMasterAnimation(false);

      var StatusData = result[0][0] as any;
      if (StatusData.Status == 1) {
        this.Message = StatusData.Message;
        this.FileURL = StatusData.PDF;
        this.FileName = StatusData.FileName;
        document.getElementById("btnFIleDownload").click();
      } else {
        this.Default.ShowAlert(StatusData.Message);
      }


    }, error => console.error(error));

  }

  public IsStringNullOrUndefined(p_strValue) {
    if (p_strValue && p_strValue != undefined && p_strValue != null && !p_strValue.includes('undefined')) {
      return p_strValue;
    } else {
      return '';
    }
  }


}



