import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';

//import { FilterJsonData } from '../../Pipes/FilterPipe';
//import { SortJsonData } from '../../Pipes/SortingPipe';

//import { DefaultTS } from '../../SharedComponents/Default';
import { Location } from '@angular/common';
import { RoutingState } from '../../SharedComponents/RoutingState';
import { ShowCalendarModal } from '../CalendarModal/ShowCalendarModal';
import { CalendarUtility } from '../CalendarUtility/CalendarUtility';


@Component({
  selector: 'MainCalendar',
  templateUrl: './MainCalendar.html',
    styleUrls: ['./MainCalendar.css'],
    providers: [ShowCalendarModal]
})

export class MainCalendar {

  public http: HttpClient;
  public baseUrl: string;

  /** Today */
  public m_oToday;

  public m_oCurrentPage;

  /** Currently selected date */
  public m_oSelectedDate;

  public m_strViewFormat = 'month';

    //public EventsData = [{ Title: 'Khula Case', Text: 'Appointment', StartTime: '9/22/2019 17:50', EndTime: '9/22/2019 18:30', Tooltip: 'Tooltip data', Color: 'green', AllDay: false, Initials: 'KL', Icon: 'fa fa-user', URL: '', ID: '1' }, { Title: 'Tallaq Case', Text: 'Appointment', StartTime: '10/24/2019 17:50', EndTime: '10/24/2019 17:50', Tooltip: 'Tooltip data', Color: 'green', AllDay: true, Initials: 'TL', Icon: 'fa fa-user', URL: 'pfiresourcebooking?BID=1&Type=Filter', ID: '2' }, { Title: 'Tallaq Case', Text: 'Appointment', StartTime: '10/24/2019 18:50', EndTime: '10/24/2019 19:20', Tooltip: 'Tooltip data', Color: 'green', AllDay: false, Initials: 'TL', Icon: 'fa fa-user', PersonalLeave: true, URL: 'pfiresourcebooking?BID=1&Type=Filter', ID: '3' }];
    public EventsData: any[] = [];

    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private router: Router, private cd: ChangeDetectorRef, private zone: NgZone, private activatedRoute: ActivatedRoute, private p_oLocation: Location, private p_oRoutingState: RoutingState) {
    /* Initialize */
    this.http = http;
    this.baseUrl = baseUrl;
    this.m_oToday = MainCalendar.getToday();
    this.m_oCurrentPage = { ...this.m_oToday };
    this.m_oSelectedDate = { ...this.m_oToday };

    
 
  }

  ngOnInit() {
   
    }

    ngAfterViewChecked() {
        this.EventsData = CalendarUtility.EventsData;
    }

  /* Get today's date */
  public static getToday(): any {
    const l_oTodayDate = new Date();

    return {
      date: l_oTodayDate.getDate(),
      month: l_oTodayDate.getMonth(),
      year: l_oTodayDate.getFullYear()
    };
  }

  public dayClicked(event) {
    this.m_oCurrentPage = event.Date;
    if (event.Change) {
      this.m_strViewFormat = event.ViewFormat;
    }
  }
}
