import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked, SimpleChanges } from '@angular/core';
import { CalendarUtility } from '../CalendarUtility/CalendarUtility';
import { ShowCalendarModal } from '../CalendarModal/ShowCalendarModal';
import { SortJsonData } from '../../Pipes/SortingPipe';

@Component({
  selector: 'DayView',
  templateUrl: './DayView.html',
  styleUrls: ['./DayView.css']
})

export class DayView implements OnInit, AfterViewChecked {
  ngAfterViewChecked(): void {

    this.scrollWeek();
  }
  @ViewChild(ShowCalendarModal, {static: false }) Modal: ShowCalendarModal;

  /* Currently Selected Date */
  @Input() public m_oSelectedDate;

  /* Default date to load data for page */
  @Input() public m_oCurrentPage;

  /* m_oToday's date */
  @Input() public m_oToday;

  /* Array with all the Events data */
  @Input() public m_arrEvents: any[] = [];

  /* Array with all the Events data */
  @Input() public Events: any[] = [];

  @Input() public EventsData: any[] = [];
  @Input() public JsonDataForEvents: any[] = [];

  public CalculatedDataFromDates = [];

  @Input() public WeekendDays: any[] = [];
  @Input() public PublicHolidays: any[] = [];
  /** Emits the new event, which calls the function in parent component (MainCalendar.ts) */
  @Output() dayClicked: EventEmitter<any> = new EventEmitter();


  public m_strDayName = '';
  public m_arrHours = [];
  public m_arrFormattedEvents = [];
  public WeekDayNumber = 0;
    constructor() {
    //this.Default.CheckIsUserLoggedIn();
    this.GenerateHours();
  }

  ngOnInit() {
    this.m_oCurrentPage = this.m_oCurrentPage;
    this.ChangeDay(0);
  }
  ngOnChanges(changes: SimpleChanges) {
    /* Fill Data in calendar according to date */
    this.ChangeDay(0);
  }
    public SetToday() {
    this.m_oCurrentPage = { ...this.m_oToday };
    this.ChangeDay(0);
  }

  public GenerateHours() {
    var l_intStartTime = '0' as any;
    for (var i = 0; i < 24; i++) {

      if (i < 12) {
        if (i < 10)
          this.m_arrHours[i] = { Hour: '0' + l_intStartTime + ' : 00', Text: '', id: l_intStartTime, StartMinutes: 0, Duration: 0, StartTime: 0, EndTime: 0 };
        else
          this.m_arrHours[i] = { Hour: l_intStartTime + ' : 00', Text: '', id: l_intStartTime, StartMinutes: 0, Duration: 0, StartTime: 0, EndTime: 0  };
      }
      else
        this.m_arrHours[i] = { Hour: l_intStartTime + ' : 00', Text: '', id: l_intStartTime, StartMinutes: 0, Duration: 0, StartTime: 0, EndTime: 0  };
      l_intStartTime = parseInt(l_intStartTime) + 1;
    }
  }

  /** Returns some class, if there is any event! */
  public IsEventPresent(p_strDate) {
    /* Check if date is selected */
    for (var i = 0; i < this.Events.length; i++) {
      var l_oDateOfEvent = new Date(this.Events[i].Date);
      var l_intDay = l_oDateOfEvent.getDate();
      var l_intMonth = l_oDateOfEvent.getMonth();
      var l_intYear = l_oDateOfEvent.getFullYear();
      var l_intHour = l_oDateOfEvent.getHours();
      var l_intMinutes = l_oDateOfEvent.getMinutes();
     
      var p_intDate = 0;
      p_intDate = parseInt(p_strDate);

      if (l_intHour == p_intDate && this.m_oCurrentPage.date == l_intDay && l_intMonth == this.m_oCurrentPage.month && l_intYear == this.m_oCurrentPage.year)
        return 'someClass';

    }
    /* No match found */
    return '';
  }

    public ChangeDay(p_intAddDay) {

        //debugger;
    var l_oDate = new Date(this.m_oCurrentPage.year, this.m_oCurrentPage.month, this.m_oCurrentPage.date);
    l_oDate.setDate(l_oDate.getDate() + p_intAddDay);
    this.m_strDayName = l_oDate.toString();

    this.m_oCurrentPage.date = l_oDate.getDate();
    this.m_oCurrentPage.month = l_oDate.getMonth();
    this.m_oCurrentPage.year = l_oDate.getFullYear();
    //this.dayClicked.emit(this.m_oCurrentPage);
    this.scrollWeek();
    this.WeekDayNumber = l_oDate.getDay();

    //this.AddRunningOrderEvent();
    this.m_arrEvents = [];
    this.RemovePreviouslyBindedEventsBoxesFromJson();
    this.CalculatedDataFromDates = [];
    this.BindEventsFromJSON(this.EventsData);
  }

  public Index = -1;
    public BindEvents(p_intIndex) {

      //  //debugger;
    if (p_intIndex != this.Index) {
      this.Index = p_intIndex;
      this.BindEventsBoxesFromJson();
      this.BindPublicHolidays();
    }
  }

    public BindEventsFromJSON(p_arrGridData) {

    this.Index = -1;
    var l_arrEvents = this.CalculatedDataFromDates
    var l_arrEventsForJson = this.m_arrEvents;

    var l_intCount = 0;
    if (l_arrEventsForJson.length > 0)
      l_intCount = l_arrEventsForJson.length;

    this.JsonDataForEvents = p_arrGridData;
    for (var i = 0; i < this.m_arrHours.length; i++) {
      var l_boolMatched = false;
        var l_strText = 'Some text to display';
        var l_strTitle = '';
      var l_strTooltipText = '';
      var l_intStartMinutesJson = 0;
      var l_intDurationMinutes = 0;
      var l_strStartTime = '';
      var l_strEndTime = '';
        var l_intCounter = 0;
        
      var l_oCurrentPageDate = new Date(this.m_oCurrentPage.year, this.m_oCurrentPage.month, this.m_oCurrentPage.date);

        for (var j = 0; j < this.JsonDataForEvents.length; j++) {

           

        if (this.JsonDataForEvents[j].StartTime != ' ') {
          var l_strStartDateInDB = this.JsonDataForEvents[j].StartTime;
            var l_strEndDateInDB = this.JsonDataForEvents[j].EndTime;
            //TODO: Trace or Change
          var l_boolIsPersonalLeave = false;
          if (this.JsonDataForEvents[j].PersonalLeave)
                l_boolIsPersonalLeave = true;

          var l_strURL = '';
          if (this.JsonDataForEvents[j].URL)
            l_strURL = this.JsonDataForEvents[j].URL;

            var l_intSelectedEventDBId = 0;
            if (this.JsonDataForEvents[j].ID)
                l_intSelectedEventDBId = this.JsonDataForEvents[j].ID;


          var l_strColor = '#A3E1D4';
          if (this.JsonDataForEvents[j].Color)
            l_strColor = this.JsonDataForEvents[j].Color;
          var l_boolAllDay = false as any;
          if (this.JsonDataForEvents[j].AllDay)
            l_boolAllDay = this.JsonDataForEvents[j].AllDay;
          var l_oDate = new Date(l_strStartDateInDB) as any;
          var a = new Date();
          if (l_oDate.toString() != 'Invalid Date') {
            var l_intDay = l_oDate.getDate();
            var l_intMonth = l_oDate.getMonth();
            var l_intYear = l_oDate.getFullYear();
            var l_intStartHour = l_oDate.getHours();
            var l_intStartMinutes = l_oDate.getMinutes();

            l_strEndDateInDB = new Date(l_strEndDateInDB) as any;
            var l_intEndDay = l_strEndDateInDB.getDate();
            var l_intEndMonth = l_strEndDateInDB.getMonth();
            var l_intEndYear = l_strEndDateInDB.getFullYear();
            var l_intEndHour = l_strEndDateInDB.getHours();
            var l_intEndMinutes = l_strEndDateInDB.getMinutes();

            if (this.m_oCurrentPage.year == l_intYear && this.m_oCurrentPage.month == l_intMonth && this.m_oCurrentPage.date == l_intDay) {
              l_intCounter++;
              var l_intHour = this.m_arrHours[i].id;
              l_intHour = parseInt(l_intHour);
              if (l_intStartHour == l_intHour) {
                if (l_boolAllDay == 1 || l_boolAllDay == true) {
                  l_intStartHour = 0;
                  l_intStartMinutes = 0;
                  l_intHour = 0;
                  l_intEndHour = 23;
                  l_intEndMinutes = 59;
                }
                l_boolMatched = true;
                l_intStartMinutesJson = l_intStartMinutes;
                if (this.m_oCurrentPage.date == l_intEndDay)
                  l_intDurationMinutes = (Math.abs((l_intEndHour - l_intStartHour)) * 60) + ((l_intEndMinutes - l_intStartMinutes));
                else
                  l_intDurationMinutes = (Math.abs((23 - l_intStartHour)) * 60) + ((60 - l_intStartMinutes));

                if (this.JsonDataForEvents[j].Title)
                    l_strTitle = this.JsonDataForEvents[j].Title; 
                if (this.JsonDataForEvents[j].Text && this.JsonDataForEvents[j].Text != '')
                  l_strText = this.JsonDataForEvents[j].Text;
                else
                  l_strText = 'Some text to display in div.';
                if (this.JsonDataForEvents[j].Tooltip)
                  l_strTooltipText = this.JsonDataForEvents[j].Tooltip;
                else
                  l_strTooltipText = 'Tooltip data';

                if (l_intStartHour < 10)
                  l_intStartHour = 0 + '' + l_intStartHour;
                if (l_intStartMinutes < 10)
                  l_intStartMinutes = 0 + '' + l_intStartMinutes;
                if (l_intEndHour < 10)
                  l_intEndHour = 0 + '' + l_intEndHour;
                if (l_intEndMinutes < 10)
                  l_intEndMinutes = 0 + '' + l_intEndMinutes;

                if (l_boolAllDay) {
                  l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear;
                  l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear;
                }
                else {
                  l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear + ', ' + l_intStartHour + ':' + l_intStartMinutes;
                  l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear + ', ' + l_intEndHour + ':' + l_intEndMinutes;
                }

                var l_strHour = l_intHour;
                var l_strID = l_intHour;

                var l_intIndex = CalendarUtility.GetIndexExactlyMatchingColumn(l_arrEvents, 'id', l_strID);
                if (l_intIndex > -1) {
                  l_arrEvents[l_intIndex].Count++;
                  l_intCounter = l_arrEvents[l_intIndex].Count;
                }

                  l_arrEvents[l_intCount] = { id: l_strID, id2: l_strID + '' + l_intCounter, Hour: l_strHour, Title: l_strTitle, Text: l_strText, Tooltip: l_strTooltipText, StartMinutes: l_intStartMinutesJson, Duration: l_intDurationMinutes, StartTime: l_strStartTime, EndTime: l_strEndTime, Count: l_intCounter, Color: l_strColor, URL: l_strURL, PersonalLeave: l_boolIsPersonalLeave, AllDay: l_boolAllDay, SelectedID: l_intSelectedEventDBId };
                // MR
                //l_arrEventsForJson[l_intCount] = { id: l_strID, id2: l_strID + '' + l_intCounter, StartTime: l_strStartTime, EndTime: l_strEndTime, Text: l_strText, URL: l_strURL };
                  l_arrEventsForJson[l_intCount] = l_arrEvents[l_intCount];
               l_intCount++;
              }
            }
            else if (this.m_oCurrentPage.year == l_intEndYear && this.m_oCurrentPage.month == l_intEndMonth && this.m_oCurrentPage.date == l_intEndDay) {
              l_intCounter++;
              var l_intHour = this.m_arrHours[i].id;
              l_intHour = parseInt(l_intHour);
              if (l_intEndHour == l_intHour) {
                if (l_boolAllDay == 1 || l_boolAllDay == true) {
                  l_intEndHour = 23;
                  l_intEndMinutes = 59;
                }
                l_boolMatched = true;
                l_intStartMinutesJson = l_intStartMinutes;
                l_intDurationMinutes = (Math.abs((l_intEndHour - 0)) * 60) + ((l_intEndMinutes - 0));

                if (this.JsonDataForEvents[j].Title)
                  l_strTitle = this.JsonDataForEvents[j].Title;
                if (this.JsonDataForEvents[j].Text && this.JsonDataForEvents[j].Text != '')
                  l_strText = this.JsonDataForEvents[j].Text;
                else
                  l_strText = 'Some text to display.';
                if (this.JsonDataForEvents[j].Tooltip)
                  l_strTooltipText = this.JsonDataForEvents[j].Tooltip;
                else
                  l_strTooltipText = 'Tooltip data';

                if (l_intStartHour < 10)
                  l_intStartHour = 0 + '' + l_intStartHour;
                if (l_intStartMinutes < 10)
                  l_intStartMinutes = 0 + '' + l_intStartMinutes;
                if (l_intEndHour < 10)
                  l_intEndHour = 0 + '' + l_intEndHour;
                if (l_intEndMinutes < 10)
                  l_intEndMinutes = 0 + '' + l_intEndMinutes;

                if (l_boolAllDay) {
                  l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear;
                  l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear;
                }
                else {
                  l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear + ', ' + l_intStartHour + ':' + l_intStartMinutes;
                  l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear + ', ' + l_intEndHour + ':' + l_intEndMinutes;
                }

                var l_strHour = 0 as any;
                var l_strID = 0 as any;

                var l_intIndex = CalendarUtility.GetIndexExactlyMatchingColumn(l_arrEvents, 'id', l_strID);
                if (l_intIndex > -1) {
                  l_arrEvents[l_intIndex].Count++;
                  l_intCounter = l_arrEvents[l_intIndex].Count;
                }

                  l_arrEvents[l_intCount] = { id: l_strID, id2: l_strID + '' + l_intCounter, Hour: l_strHour, Title: l_strTitle, Text: l_strText, Tooltip: l_strTooltipText, StartMinutes: l_intStartMinutesJson, Duration: l_intDurationMinutes, StartTime: l_strStartTime, EndTime: l_strEndTime, Count: l_intCounter, Color: l_strColor, URL: l_strURL, PersonalLeave: l_boolIsPersonalLeave, AllDay: l_boolAllDay, SelectedID: l_intSelectedEventDBId };
                  //MR
                  //l_arrEventsForJson[l_intCount] = { id: l_strID, id2: l_strID + '' + l_intCounter, StartTime: l_strStartTime, EndTime: l_strEndTime, Text: l_strText, URL: l_strURL };
                  l_arrEventsForJson[l_intCount] = l_arrEvents[l_intCount];
                  l_intCount++;
              }
            }
            else if (CalendarUtility.IsDateBetweenTwoDates(l_oDate, l_oCurrentPageDate, l_strEndDateInDB)) {
              l_intCounter++;
              var l_intHour = this.m_arrHours[i].id;
              l_intHour = parseInt(l_intHour);
              if (l_intHour == 0) {
                l_boolMatched = true;
                l_intStartMinutesJson = l_intStartMinutes;
                l_intDurationMinutes = (Math.abs((23 - 0)) * 60) + ((60 - 0));

                if (this.JsonDataForEvents[j].Title)
                  l_strTitle = this.JsonDataForEvents[j].Title;
                if (this.JsonDataForEvents[j].Text && this.JsonDataForEvents[j].Text != '')
                  l_strText = this.JsonDataForEvents[j].Text;
                else
                  l_strText = 'Some text to display.';
                if (this.JsonDataForEvents[j].Tooltip)
                  l_strTooltipText = this.JsonDataForEvents[j].Tooltip;
                else
                  l_strTooltipText = 'Tooltip data';

                if (l_intStartHour < 10)
                  l_intStartHour = 0 + '' + l_intStartHour;
                if (l_intStartMinutes < 10)
                  l_intStartMinutes = 0 + '' + l_intStartMinutes;
                if (l_intEndHour < 10)
                  l_intEndHour = 0 + '' + l_intEndHour;
                if (l_intEndMinutes < 10)
                  l_intEndMinutes = 0 + '' + l_intEndMinutes;

                if (l_boolAllDay) {
                  l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear;
                  l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear;
                }
                else {
                  l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear + ', ' + l_intStartHour + ':' + l_intStartMinutes;
                  l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear + ', ' + l_intEndHour + ':' + l_intEndMinutes;
                }

                var l_strHour = l_intHour;
                var l_strID = this.m_arrHours[i].id;

                var l_intIndex = CalendarUtility.GetIndexExactlyMatchingColumn(l_arrEvents, 'id', l_strID);
                if (l_intIndex > -1) {
                  l_arrEvents[l_intIndex].Count++;
                  l_intCounter = l_arrEvents[l_intIndex].Count;
                }

                  l_arrEvents[l_intCount] = { id: l_strID, id2: l_strID + '' + l_intCounter, Hour: l_strHour, Title: l_strTitle, Text: l_strText, Tooltip: l_strTooltipText, StartMinutes: l_intStartMinutesJson, Duration: l_intDurationMinutes, StartTime: l_strStartTime, EndTime: l_strEndTime, Count: l_intCounter, Color: l_strColor, URL: l_strURL, PersonalLeave: l_boolIsPersonalLeave, AllDay: l_boolAllDay, SelectedID: l_intSelectedEventDBId };
                //l_arrEventsForJson[l_intCount] = { id: l_strID, id2: l_strID + '' + l_intCounter, StartTime: l_strStartTime, EndTime: l_strEndTime, Text: l_strText, URL: l_strURL };
                  l_arrEventsForJson[l_intCount] = l_arrEvents[l_intCount];
                  l_intCount++;
              }
            }
          }
        }
      }
    }
    //this.CalculatedDataFromDates = this.CalculatedDataFromDates.concat(l_arrEvents);
    //this.CalculatedDataFromDates = new SortJsonData().transform(this.CalculatedDataFromDates, '-Text');
    //this.m_arrEvents = this.m_arrEvents.concat(l_arrEventsForJson);
    this.CalculatedDataFromDates = l_arrEvents;
    this.m_arrEvents = l_arrEventsForJson;
  }

  public GetHeightForCell(p_intHour, p_intDuration, p_intStartMinutes) {
    var l_intDivHeight = ((p_intDuration / 60) * 40).toFixed(0);
    var l_intMaxDuration = ((24 - p_intHour) * 60) + (60 - p_intStartMinutes);
    var l_intMaxDivHeight = ((l_intMaxDuration / 60) * 40).toFixed(0);
    if (parseInt(l_intDivHeight) > parseInt(l_intMaxDivHeight))
      return l_intMaxDivHeight;
    else
      return l_intDivHeight;
  }
    
    public BindEventsBoxesFromJson() {
        //debugger;
    var l_arrOverlappingEvents = [];
    var l_intCounter = 0;
    for (var i = 0; i < this.CalculatedDataFromDates.length; i++) {
      var l_strElementID = this.CalculatedDataFromDates[i].id;
      var l_oElement = document.getElementById('tdColumn_' + l_strElementID);
      if (l_oElement != null) {

        if (this.CalculatedDataFromDates[i].Text != '') {
          var l_strTextToDisplay = this.CalculatedDataFromDates[i].Text;
          var l_intStartMinutes = this.CalculatedDataFromDates[i].StartMinutes;
          var l_intDuration = this.CalculatedDataFromDates[i].Duration;
          var l_strStartTime = this.CalculatedDataFromDates[i].StartTime;
          var l_strEndTime = this.CalculatedDataFromDates[i].EndTime;
          var l_intHour = this.CalculatedDataFromDates[i].Hour;
          var l_strColor = this.CalculatedDataFromDates[i].Color;
          var l_boolIsPersonalLeave = this.CalculatedDataFromDates[i].PersonalLeave;

          var divRelative = document.createElement('li');
          divRelative.setAttribute('style', 'position:relative;list-style-type:none;');
          divRelative.setAttribute('class', 'CalendarEventColor');
          var divAbsolute = document.createElement('div');
          divRelative.appendChild(divAbsolute);

          var l_strBottomBorder = this.GetBottomBorderForJsonEventsBoxes(l_intHour, l_intDuration, l_intStartMinutes);
          if (l_strBottomBorder == '0')
            divAbsolute.setAttribute('class', 'radiusbord');
          else
            divAbsolute.setAttribute('class', 'borderradius');

          if (l_boolIsPersonalLeave) {
            divRelative.setAttribute('style', 'position:relative;float: left;width: 100%;background: #cccccc !important;color: #ffffff;font-size: 12px;padding: 2px 3px;height: 20px;margin-bottom:1px;overflow: hidden;');
          }
          else
            divRelative.setAttribute('class', 'CalendarEventColor');

          if (l_boolIsPersonalLeave) {
            divAbsolute.setAttribute('style', 'padding:2px; font-size:10px; text-align:left; line-height:12px;overflow:hidden; color:#ff6961 !important;');
          }
          else {
            divAbsolute.setAttribute('style', 'padding:2px; font-size:10px; text-align:left; line-height:12px;overflow:hidden; color:#000000;');
            }

          
          divAbsolute.style.position = "absolute";
          //divAbsolute.style.zIndex = "10001";
          //divAbsolute.style.position = "relative";
          //divAbsolute.style.zIndex = "10001";

          divAbsolute.style.width = "80px";
          divAbsolute.style.height = this.GetHeightForCellForJsonEvents(l_intHour, l_intDuration, l_intStartMinutes) + "px";
          divAbsolute.style.backgroundColor = l_strColor;
          var l_boolExisted = false;
          for (var j = 0; j < l_arrOverlappingEvents.length; j++) {
            if (l_strElementID == l_arrOverlappingEvents[j].id) {
              if (l_arrOverlappingEvents[j].Count < 30)
                divAbsolute.style.marginLeft = ((l_arrOverlappingEvents[j].Count * 30)) + "px";
              l_arrOverlappingEvents[j].Count = l_arrOverlappingEvents[j].Count + 1;
              l_boolExisted = true;
            }
          }
          if (!l_boolExisted) {
            l_arrOverlappingEvents[l_intCounter] = { id: l_strElementID, Count: 1 };
            l_intCounter++;
            //divAbsolute.style.marginLeft = "0px"; 
          }
          if (l_boolIsPersonalLeave) {
            divAbsolute.style.marginTop = "0px";
          }
          else {
            divAbsolute.style.marginTop = "0px";
          } 
          divAbsolute.style.border = "2px solid #58C7B1";

          if (l_intDuration <= 25)
            divAbsolute.setAttribute('title', l_strStartTime + ' - ' + l_strEndTime + ' -> ' + l_strTextToDisplay);
          else
            divAbsolute.setAttribute('title', l_strTextToDisplay);

          divAbsolute.style.top = (((l_intStartMinutes / 60 * 40)).toFixed(0)) + "px";
          if (l_intDuration > 25 && l_intDuration < 45)
            divAbsolute.innerText = l_strStartTime + ' - ' + l_strEndTime;
          else if (l_intDuration >= 45)
            divAbsolute.innerText = l_strStartTime + ' - ' + l_strEndTime + ' ' + l_strTextToDisplay;

          //divAbsolute.style.left = GetLeftAdjustment() + "px";
          var l_strElementID2 = this.CalculatedDataFromDates[i].id2;
          divAbsolute.addEventListener('click', this.ShowEventsDataFromJson.bind(this, l_strElementID2));
          l_oElement.appendChild(divRelative);
        }
      }
    }
  }

  public BindEventsCountsFromJson() {
    for (var i = 0; i < this.CalculatedDataFromDates.length; i++) {
      var l_strElementID = this.CalculatedDataFromDates[i].id;
      var l_oElement = document.getElementById('tdColumn_' + l_strElementID);
      if (l_oElement != null) {
        var span = document.createElement('span');
        span.innerText = this.CalculatedDataFromDates[i].Count;
        span.setAttribute('title', this.CalculatedDataFromDates[i].Tooltip);
        l_oElement.appendChild(span);
        //l_oElement.addEventListener('click', this.ShowEventsDataFromJson.bind(this));
      }
    }
  }

  public GetHeightForCellForJsonEvents(p_intHour, p_intDuration, p_intStartMinutes) {
    var l_intDivHeight = ((p_intDuration / 60) * 40).toFixed(0);
    var l_intMaxDuration = ((18 - 9) * 60) + (60 - p_intStartMinutes);
    var l_intMaxDivHeight = ((l_intMaxDuration / 60) * 40).toFixed(0);
    if (parseInt(l_intDivHeight) >= parseInt(l_intMaxDivHeight))
      return l_intMaxDivHeight;
    else
      return l_intDivHeight;
  }

  public GetBottomBorderForJsonEventsBoxes(p_intHour, p_intDuration, p_intStartMinutes) {
    var l_intDivHeight = ((p_intDuration / 60) * 40).toFixed(0);
    var l_intMaxDuration = ((23 - p_intHour) * 60) + (60 - p_intStartMinutes);
    var l_intMaxDivHeight = ((l_intMaxDuration / 60) * 40).toFixed(0);
    if (parseInt(l_intDivHeight) >= parseInt(l_intMaxDivHeight))
      return '0';
    else
      return '2';
  }

  public RemovePreviouslyBindedEventsBoxesFromJson() {
    for (var i = 0; i < this.CalculatedDataFromDates.length; i++) {
      var l_strElementID = this.CalculatedDataFromDates[i].id;
      var l_oElement = document.getElementById('tdColumn_' + l_strElementID);
      if (l_oElement != null)
        l_oElement.innerHTML = '';
    }
  }

  public BindPublicHolidays() {
    
    var l_oCurrentDate = new Date(this.m_oCurrentPage.year, this.m_oCurrentPage.month, this.m_oCurrentPage.date);
   
    for (var k = 0; k < this.PublicHolidays.length; k++) {
      var l_strStartDateInDB = this.PublicHolidays[k].StartDate;
      var l_strEndDateInDB = this.PublicHolidays[k].EndDate;
      var l_strTitle = this.PublicHolidays[k].Purpose;
      if (l_strStartDateInDB != '' && l_strEndDateInDB != '') {
        var l_oStartDate = new Date(l_strStartDateInDB);
        var l_oEndDate = new Date(l_strEndDateInDB);
      }
    }
  }


  public ShowEventsDataFromJson(p_strID, p_oEvent) {

    var l_strID = p_strID;

      var l_arrJsonData = CalendarUtility.FilterJsonExactlyMatching(this.m_arrEvents, 'id2', l_strID);

      /*
    this.m_arrFormattedEvents = [];
    var l_arrFormattedColumns = [];
    var l_arrFormattedValues = [];
      let l_intCounter = 0;

      //debugger;

    for (let l_oRow of l_arrJsonData) {
        console.log("Row:", l_oRow);

        for (let l_oColumn in l_oRow) {

        console.log("  Column:", l_oColumn, "value:", l_oRow[l_oColumn]);

        if (l_oColumn.indexOf('id') < 0 && l_oColumn.indexOf('Color') && l_oColumn != 'PersonalLeave' && ((l_oColumn == 'Initials') || l_oColumn != 'Initials')) {
          var l_strColumnName = l_oColumn;

          if (l_strColumnName == 'Text')
            l_strColumnName = 'Details';

          l_arrFormattedColumns[l_intCounter] = l_strColumnName;
          l_arrFormattedValues[l_intCounter] = l_oRow[l_oColumn];
          this.m_arrFormattedEvents[l_intCounter] = l_strColumnName + ': ' + l_oRow[l_oColumn]; // + " " + Math.random() * 100 + 100;
          l_intCounter++;
        }

      }
    }
    */

      if (this.Modal) {

          this.Modal.FormattedValues = l_arrJsonData;
          this.Modal.m_arrFormattedEvents = [];
      //this.Modal.FormattedColumns = l_arrFormattedColumns;
      //this.Modal.FormattedValues = l_arrFormattedValues;
      //this.Modal.m_arrFormattedEvents = this.m_arrFormattedEvents;
      this.Modal.ShowFormattedJsonData();
    }
  }
  public scrollWeek() {

    document.getElementById('tablescroll').scrollTop = 360;
  }
}
