import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Routes, RouterModule, UrlSerializer } from '@angular/router';
import { DayView } from '../DayView/DayView';
import { WeekView } from '../WeekView/WeekView';
import { YearView } from '../YearView/YearView';
import { MonthView } from '../MonthView/MonthView';
import { ShowCalendarModal } from '../CalendarModal/ShowCalendarModal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MainCalendar } from '../CalendarMain/MainCalendar';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [MainCalendar, DayView, WeekView, MonthView, YearView, ShowCalendarModal],


    exports: [MainCalendar, DayView, WeekView, MonthView, YearView, ShowCalendarModal, RouterModule, CommonModule, FormsModule, ReactiveFormsModule],
})
export class CalendarModule {
    
}
