
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';

import { ShowCalendarModal } from '../CalendarModal/ShowCalendarModal';
import { CalendarUtility } from '../CalendarUtility/CalendarUtility';

@Component({
  selector: 'MonthView',
  templateUrl: './MonthView.html',
  styleUrls: ['./MonthView.css']
})

export class MonthView implements OnInit {
  @ViewChild(ShowCalendarModal, { static: false }) Modal: ShowCalendarModal;

  /* m_oToday's date */
  @Input() public m_oToday;

  /* calendarUtility date to load data for page */
  @Input() public m_oCurrentPage;

  /* Currently Selected Date */
  @Input() public m_oSelectedDate;

  /* Array with all the calendar data */
  @Input() public m_arrCalendar: any[] = [];

  @Input() public EventsData: any[] = [];


  @Output() dayClicked: EventEmitter<any> = new EventEmitter();

  /** Constants For header and displaying month names */
  public readonly m_arrMonthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  public readonly m_arrDayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public m_strTestEvent = '05-05-2018';
  public l_arrMonthDates = [] as any;
  public m_arrRunningOrders = [] as any;
  //public ResourcesBookingsList = [] as any;
  public m_arrEvents = [] as any;
  public PublicHolidays = [] as any;
  public m_arrFormattedEvents = [] as any;


  constructor() {

  }

  /* When the page loading is finished, onInit */
  ngOnInit() {
    this.m_oCurrentPage = this.m_oCurrentPage;
    /* Fill Data in calendar according to date */
    this.FillDataForCalendar();
  }
  ngOnChanges(changes: SimpleChanges) {
    /* Fill Data in calendar according to date */
    this.FillDataForCalendar();
  }

  public SetToday() {
    this.m_oCurrentPage = { ...this.m_oToday };
    this.FillDataForCalendar();
  }

  /** Returns some class, if there is any event! */
  public IsEventPresent(p_intDate: number): string {
    /* Check if date is selected */
    var Events = [];
    for (var i = 0; i < Events.length; i++) {
      var l_oDateOfEvent = new Date(Events[i].Date);
      var l_intDay = l_oDateOfEvent.getDate();
      var l_intMonth = l_oDateOfEvent.getMonth();
      var l_intYear = l_oDateOfEvent.getFullYear();

      if (p_intDate == l_intDay && l_intMonth == this.m_oCurrentPage.month && l_intYear == this.m_oCurrentPage.year)
        return 'someClass';

    }
    /* No match found */
    return '';
  }

  /** Returns 'Bold (ClassName)' if col is m_oToday */
  public IsToday(p_intDate: number): string {

    if ((this.IsSameDate(p_intDate, this.m_oCurrentPage, this.m_oToday)) || (this.IsSameDate(p_intDate, this.m_oCurrentPage, this.m_oSelectedDate))) {
      return 'ClassName';
    }
    return '';
  }

  /* Returns true if two dates are the same with the date taken separately */
  public IsSameDate(p_intDate, p_oFirstMonthAndYear, p_oSecondMonthAndYear): boolean {

    return p_intDate == p_oSecondMonthAndYear.date &&
      p_oFirstMonthAndYear.month == p_oSecondMonthAndYear.month &&
      p_oFirstMonthAndYear.year == p_oSecondMonthAndYear.year;
  }

  /** Select a day in the open page */
  public ChangeFormatToDay(p_intDate: number) {
    this.m_oSelectedDate.date = p_intDate;
    this.m_oSelectedDate.month = this.m_oCurrentPage.month;
    this.m_oSelectedDate.year = this.m_oCurrentPage.year;
    let data = {} as any;
    data.Date = this.m_oSelectedDate;
    data.Change = true;
    data.ViewFormat = 'day';
    this.dayClicked.emit(data);
  }

  /** Change the month +1 or -1 */
  public ChangeMonth(p_intMonth: number) {
    var l_oDate = new Date(this.m_oCurrentPage.year, this.m_oCurrentPage.month, this.m_oCurrentPage.date);
    l_oDate.setMonth(l_oDate.getMonth() + p_intMonth);

    this.m_oCurrentPage.date = l_oDate.getDate();
    this.m_oCurrentPage.month = l_oDate.getMonth();
    this.m_oCurrentPage.year = l_oDate.getFullYear();

    /* Refill the data for calendar */
    this.FillDataForCalendar();
    let data = {} as any;
    data.Date = this.m_oCurrentPage;
    data.Change = false;
    this.dayClicked.emit(data);
  }

  /** Compute the calendar */
  public FillDataForCalendar() {
    const l_oNewCalendar = [[]];

    const l_intMonth = this.m_oCurrentPage.month;
    const l_intYear = this.m_oCurrentPage.year;

    /* Days in next month, and day of week */
    let l_intColumnNumber = new Date(l_intYear, l_intMonth, 1).getDay();
    let l_intRowNumber = 0;
    let l_intCounter = 1;
    const l_intNumOfDays = Number(this.GetDaysOfMonth(l_intMonth, l_intYear));

    /* Loop to build the calendar body */
    while (l_intCounter <= l_intNumOfDays) {
      /* When to start new line */
      if (l_intColumnNumber > 6) {
        l_intColumnNumber = 0;
        l_oNewCalendar[++l_intRowNumber] = [];
      }

      /* Set the value and increment */
      l_oNewCalendar[l_intRowNumber][l_intColumnNumber++] = l_intCounter++;
    }

    /* Set the calendar to the newly computed one */
    this.m_arrCalendar = l_oNewCalendar;
    this.GenerateDatesForMonth();
    //debugger;
  }

  public GenerateDatesForMonth() {

    var l_arrCalendarData = this.m_arrCalendar;

    var l_intCount = 0;

    for (var i = 0; i < l_arrCalendarData.length; i++) {
      for (var j = 0; j < l_arrCalendarData[i].length; j++) {
        this.l_arrMonthDates[l_intCount] = new Date(this.m_oCurrentPage.year, this.m_oCurrentPage.month, l_arrCalendarData[i][j]);
        l_intCount++;
      }
    }
    this.m_arrRunningOrders = [];
    this.m_arrEvents = [];
    this.RemovePreviouslyBindedEventsBoxes();
    this.BindEventsFromJSON(this.EventsData);

  }

  public BindEventsFromJSON(p_oGridData) {
    this.Index = -1;
    var l_arrRunningOrders = this.m_arrRunningOrders;
    var l_arrEvents = this.m_arrEvents;
    var l_intCount = 0;
    if (l_arrEvents.length > 0)
      l_intCount = l_arrEvents.length;
    for (var k = 0; k < this.m_arrCalendar.length; k++) {
      for (var i = 0; i < this.m_arrCalendar[k].length; i++) {
        if (this.m_arrCalendar[k][i]) {
          var l_boolMatched = false;
            var l_strText = 'Some text to display';
            var l_strTitle = '';
          var l_intStartMinutesJson = 0;
          var l_intDurationMinutes = 0;
          var l_strStartTime = '';
          var l_strEndTime = '';
          var l_intCounter = 0;
          for (var j = 0; j < p_oGridData.length; j++) {
            if (p_oGridData[j].StartTime != '') {
              var l_strStartDateInDB = p_oGridData[j].StartTime;
              var l_strEndDateInDB = p_oGridData[j].EndTime;
              var l_strInitials = '';
              var l_strIcon = '';
              if (p_oGridData[j].Initials)
                l_strInitials = p_oGridData[j].Initials;
              if (p_oGridData[j].Icon)
                l_strIcon = p_oGridData[j].Icon;
              var l_boolIsPersonalLeave = false;
                if (p_oGridData[j].PersonalLeave)
                l_boolIsPersonalLeave = true;
              var l_strURL = 'events';
              var l_strColor = '#A3E1D4'
              if (p_oGridData[j].Color)
                l_strColor = p_oGridData[j].Color;

              if (p_oGridData[j].URL)
                l_strURL = p_oGridData[j].URL;

                var l_intSelectedEventDBId = 0;
              if (p_oGridData[j].ID)
                  l_intSelectedEventDBId = p_oGridData[j].ID;

              var l_boolAllDay = false as any;
              if (p_oGridData[j].AllDay)
                l_boolAllDay = p_oGridData[j].AllDay;
              var l_oDate = new Date(l_strStartDateInDB) as any;
              if (l_oDate.toString() != 'Invalid Date') {
                var l_intDay = l_oDate.getDate();
                var l_intMonth = l_oDate.getMonth();
                var l_intYear = l_oDate.getFullYear();
                var l_intStartHour = l_oDate.getHours();
                var l_intStartMinutes = l_oDate.getMinutes();

                l_strEndDateInDB = new Date(l_strEndDateInDB) as any;
                var l_intEndDay = l_strEndDateInDB.getDate();
                var l_intEndMonth = l_strEndDateInDB.getMonth();
                var l_intEndYear = l_strEndDateInDB.getFullYear();
                var l_intEndHour = l_strEndDateInDB.getHours();
                var l_intEndMinutes = l_strEndDateInDB.getMinutes();

                if (l_intYear < this.m_oCurrentPage.year && this.m_oCurrentPage.year < l_intEndYear) {
                  l_intCounter++;
                  l_boolMatched = true;
                  l_intStartMinutesJson = l_intStartMinutes;
                  l_intDurationMinutes = (Math.abs((l_intEndHour - l_intStartHour)) * 60) + ((l_intEndMinutes - l_intStartMinutes));
                  l_strTitle = p_oGridData[j].Title;
                  if (p_oGridData[j].Text)
                    l_strText = p_oGridData[j].Text;

                  if (l_intStartHour < 10)
                    l_intStartHour = 0 + '' + l_intStartHour;
                  if (l_intStartMinutes < 10)
                    l_intStartMinutes = 0 + '' + l_intStartMinutes;
                  if (l_intEndHour < 10)
                    l_intEndHour = 0 + '' + l_intEndHour;
                  if (l_intEndMinutes < 10)
                    l_intEndMinutes = 0 + '' + l_intEndMinutes;

                  if (l_boolAllDay) {
                    l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear;
                    l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear;
                  }
                  else {
                    l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear + ', ' + l_intStartHour + ':' + l_intStartMinutes;
                    l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear + ', ' + l_intEndHour + ':' + l_intEndMinutes;
                  }
                  var l_strHour = l_intDay;
                  var l_strID = k + '' + i;

                    var l_intIndex = CalendarUtility.GetIndexExactlyMatchingColumn(l_arrRunningOrders, 'id', l_strID);
                  if (l_intIndex > -1) {
                    l_arrRunningOrders[l_intIndex].Count++;
                    l_intCounter = l_arrRunningOrders[l_intIndex].Count;
                  }
                  else
                      l_arrRunningOrders[l_arrRunningOrders.length] = { id: l_strID, Date: i, Count: l_intCounter, Tile: l_strTitle, Text: l_strText, Initials: l_strInitials };
                  var l_intTotal = 60000;
                  if (l_intCounter < 60000)
                    l_intTotal = l_intCounter;
                    l_arrEvents[l_intCount] = { id: 'tdColumn_' + l_strID, id2: 'tdColumn_' + l_strID + l_intTotal, StartTime: l_strStartTime, EndTime: l_strEndTime, Tile: l_strTitle, Text: l_strText, Initials: l_strInitials, URL: l_strURL, Color: l_strColor, PersonalLeave: l_boolIsPersonalLeave, Icon: l_strIcon, AllDay: l_boolAllDay, SelectedID: l_intSelectedEventDBId };
                  l_intCount++;
                }
                else if ((l_intYear == this.m_oCurrentPage.year && l_intMonth < this.m_oCurrentPage.month && this.m_oCurrentPage.year < l_intEndYear) || (this.m_oCurrentPage.year > l_intYear && this.m_oCurrentPage.year == l_intEndYear && this.m_oCurrentPage.month < l_intEndMonth)) {
                  l_intCounter++;
                  l_boolMatched = true;
                  l_intStartMinutesJson = l_intStartMinutes;
                  l_intDurationMinutes = (Math.abs((l_intEndHour - l_intStartHour)) * 60) + ((l_intEndMinutes - l_intStartMinutes));
                  l_strTitle = p_oGridData[j].Title;
                  if (p_oGridData[j].Text)
                    l_strText = p_oGridData[j].Text;

                  if (l_intStartHour < 10)
                    l_intStartHour = 0 + '' + l_intStartHour;
                  if (l_intStartMinutes < 10)
                    l_intStartMinutes = 0 + '' + l_intStartMinutes;
                  if (l_intEndHour < 10)
                    l_intEndHour = 0 + '' + l_intEndHour;
                  if (l_intEndMinutes < 10)
                    l_intEndMinutes = 0 + '' + l_intEndMinutes;

                  if (l_boolAllDay) {
                    l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear;
                    l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear;
                  }
                  else {
                    l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear + ', ' + l_intStartHour + ':' + l_intStartMinutes;
                    l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear + ', ' + l_intEndHour + ':' + l_intEndMinutes;
                  }
                  var l_strHour = l_intDay;
                  var l_strID = k + '' + i;

                    var l_intIndex = CalendarUtility.GetIndexExactlyMatchingColumn(l_arrRunningOrders, 'id', l_strID);
                  if (l_intIndex > -1) {
                    l_arrRunningOrders[l_intIndex].Count++;
                    l_intCounter = l_arrRunningOrders[l_intIndex].Count;
                  }
                  else
                      l_arrRunningOrders[l_arrRunningOrders.length] = { id: l_strID, Date: i, Count: l_intCounter, Title: l_strTitle, Text: l_strText, Initials: l_strInitials };
                  var l_intTotal = 60000;
                  if (l_intCounter < 60000)
                    l_intTotal = l_intCounter;
                    l_arrEvents[l_intCount] = { id: 'tdColumn_' + l_strID, id2: 'tdColumn_' + l_strID + l_intTotal, StartTime: l_strStartTime, EndTime: l_strEndTime, Title: l_strTitle, Text: l_strText, Initials: l_strInitials, URL: l_strURL, Color: l_strColor, PersonalLeave: l_boolIsPersonalLeave, Icon: l_strIcon };
                  l_intCount++;
                }
                else if ((l_intYear == this.m_oCurrentPage.year && l_intMonth == this.m_oCurrentPage.month && (((l_intEndMonth > this.m_oCurrentPage.month || l_intEndYear > this.m_oCurrentPage.year) && l_intDay <= this.m_arrCalendar[k][i]) || (l_intEndMonth == this.m_oCurrentPage.month && l_intDay <= this.m_arrCalendar[k][i] && this.m_arrCalendar[k][i] <= l_intEndDay))) || (this.m_oCurrentPage.year == l_intEndYear && this.m_oCurrentPage.month == l_intEndMonth && (((l_intMonth < this.m_oCurrentPage.month || l_intYear < this.m_oCurrentPage.year) && this.m_arrCalendar[k][i] <= l_intEndDay) || (l_intMonth == this.m_oCurrentPage.month && l_intDay <= this.m_arrCalendar[k][i] && this.m_arrCalendar[k][i] <= l_intEndDay)))) {
                  l_intCounter++;
                  l_boolMatched = true;
                  l_intStartMinutesJson = l_intStartMinutes;
                  l_intDurationMinutes = (Math.abs((l_intEndHour - l_intStartHour)) * 60) + ((l_intEndMinutes - l_intStartMinutes));
                  l_strTitle = p_oGridData[j].Title;
                  if (p_oGridData[j].Text)
                    l_strText = p_oGridData[j].Text;

                  if (l_intStartHour < 10)
                    l_intStartHour = 0 + '' + l_intStartHour;
                  if (l_intStartMinutes < 10)
                    l_intStartMinutes = 0 + '' + l_intStartMinutes;
                  if (l_intEndHour < 10)
                    l_intEndHour = 0 + '' + l_intEndHour;
                  if (l_intEndMinutes < 10)
                    l_intEndMinutes = 0 + '' + l_intEndMinutes;

                  if (l_boolAllDay) {
                    l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear;
                    l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear;
                  }
                  else {
                    l_strStartTime = l_intDay + '/' + (l_intMonth + 1) + '/' + l_intYear + ', ' + l_intStartHour + ':' + l_intStartMinutes;
                    l_strEndTime = l_intEndDay + '/' + (l_intEndMonth + 1) + '/' + l_intEndYear + ', ' + l_intEndHour + ':' + l_intEndMinutes;
                  }
                  var l_strHour = l_intDay;
                  var l_strID = k + '' + i;

                  var l_intIndex = CalendarUtility.GetIndexExactlyMatchingColumn(l_arrRunningOrders, 'id', l_strID);
                  if (l_intIndex > -1) {
                    l_arrRunningOrders[l_intIndex].Count++;
                    l_intCounter = l_arrRunningOrders[l_intIndex].Count;
                  }
                  else
                      l_arrRunningOrders[l_arrRunningOrders.length] = { id: l_strID, Date: i, Count: l_intCounter, Title: l_strTitle, Text: l_strText, Initials: l_strInitials };
                  var l_intTotal = 60000;
                  if (l_intCounter < 60000)
                    l_intTotal = l_intCounter;
                    l_arrEvents[l_intCount] = { id: 'tdColumn_' + l_strID, id2: 'tdColumn_' + l_strID + l_intTotal, StartTime: l_strStartTime, EndTime: l_strEndTime, Title: l_strTitle, Text: l_strText, Initials: l_strInitials, URL: l_strURL, Color: l_strColor, PersonalLeave: l_boolIsPersonalLeave, Icon: l_strIcon, AllDay: l_boolAllDay, SelectedID: l_intSelectedEventDBId };
                  l_intCount++;
                }
              }
            }
          }
        }
      }
    }

    this.m_arrRunningOrders = l_arrRunningOrders;
    this.m_arrEvents = l_arrEvents;
    //this.m_arrRunningOrders = this.m_arrRunningOrders.concat(l_arrRunningOrders);
    //this.m_arrEvents = this.m_arrEvents.concat(l_arrEvents);
  }


  public Index = -1;
  public BindEvents(p_intIndex) {
    if (p_intIndex != this.Index) {
      this.BindPublicHolidays();
      this.BindEventsBoxesFromJson();
      this.Index = p_intIndex;
      for (var i = 0; i < this.m_arrRunningOrders.length; i++) {
        var l_strElementID = this.m_arrRunningOrders[i].id;
        var l_strInitials = this.m_arrRunningOrders[i].Initials;
        var l_oElement = document.getElementById('tdColumn_' + l_strElementID);
        //  if (l_oElement != null && l_strInitials == '') {

        //  //if (this.ResourcesBookingsList.length > 0)
        //  //  l_oElement.setAttribute('class', 'ResourcesMonth');
        //  //else
        //    l_oElement.setAttribute('class', 'RunningOrderMonth');
        //  //var span = document.createElement('span');
        //  //span.innerHTML = this.m_arrRunningOrders[i].Count;
        //  //span.title = 'Some Text ' + (i + 1);
        //  //span.addEventListener('click', this.ShowEventsDataFromJson.bind(this));
        //  //l_oElement.appendChild(span);
        //  l_oElement.innerText = this.m_arrRunningOrders[i].Count;
        //  l_oElement.addEventListener('click', this.ShowEventsDataFromJson2.bind(this));
        //}
      }
    }
  }

  public BindEventsBoxesFromJson() {
    var l_arrOverlappingEvents = [];
    var l_intCounter = 0;
    for (var i = 0; i < this.m_arrEvents.length; i++) {
      var l_strElementID = this.m_arrEvents[i].id;
      var l_Colors = this.m_arrEvents[i].Color
      var l_strIcon = this.m_arrEvents[i].Icon
      var l_oElement = document.getElementById(l_strElementID + '_Box');
      var l_intCounter2 = 1;
      if (l_oElement != null) {

        if (this.m_arrEvents[i].Text != '') {
          var l_strTextToDisplay = this.m_arrEvents[i].Text;
          var l_boolIsPersonalLeave = this.m_arrEvents[i].PersonalLeave;
          var l_strInitials = this.m_arrEvents[i].Initials;
          var l_boolShouldAdd = true;
          var l_boolAlreadyExisted = false;
          for (var j = 0; j < l_arrOverlappingEvents.length; j++) {
            if (l_strElementID == l_arrOverlappingEvents[j].id) {
              l_boolAlreadyExisted = true;
              if ((l_arrOverlappingEvents[j].Count >= 2 && l_strInitials == '') || (l_strInitials != '' && l_arrOverlappingEvents[j].Count >= 60000)) {
                l_boolShouldAdd = false;
              }
              if (l_strInitials != '' && l_arrOverlappingEvents[j].Count == 50000) {
                var l_strElementIDForCompare = l_strElementID.replace('tdColumn_', '');
                var l_arrFilteredData = CalendarUtility.FilterJsonExactlyMatching(this.m_arrRunningOrders, 'id', l_strElementIDForCompare);
                if (l_arrFilteredData.length > 0) {
                  if (l_arrFilteredData[0].Count > 60000)
                    l_strInitials = '+' + (l_arrFilteredData[0].Count - 50000);
                }
              }
              l_arrOverlappingEvents[j].Count = l_arrOverlappingEvents[j].Count + 1;
              l_intCounter2 = l_arrOverlappingEvents[j].Count;
            }
          }
          if (l_boolShouldAdd) {
            if (!l_boolAlreadyExisted) {
              l_arrOverlappingEvents[l_intCounter] = { id: l_strElementID, Count: 1 };
              l_intCounter++;
            }
            var l_arrFilteredData = CalendarUtility.FilterJsonExactlyMatching(this.m_arrEvents, 'id', l_strElementID);
            if (l_arrFilteredData.length == 1 && l_strInitials == '')
              l_oElement.setAttribute('style', 'float: left;width: 100%;padding: 0;margin: 0;position: relative;top: -26px;');
            else if (l_strInitials == '')
              l_oElement.setAttribute('style', 'float: left;width: 100%;padding: 0;margin: 0;position: relative;top: -5px;');
            else if (l_strInitials != '')
              l_oElement.setAttribute('style', 'float: left;width: 100%;padding: 0;margin: 0;position: relative;top: 0px; height:63px;overflow:hidden;');

            var divRelative = document.createElement('li');
            divRelative.setAttribute('style', 'position:relative;float: left;width: 100%; color: #000000;font-size: 9px;padding: 6px 3px 6px 3px;height: 20px;margin-bottom:1px;overflow: hidden; background:' + l_Colors + '!important;');

            if (l_strInitials != '') {
              divRelative.setAttribute('style', 'float: left;color: #ffffff;font-size: 12px;margin: 0 0px 2px 2px;text-align: center; height:20px;');
              divRelative.setAttribute('class', 'CalendarEventColor ForInitials');
            }
            if (l_boolIsPersonalLeave) {
              divRelative.setAttribute('style', 'float: left;color: #ffffff;font-size: 12px;margin: 0 0px 5px 2px;text-align: center; background:Transparent !important; border:2px dashed #ff0000 !important; height:17px;');
              divRelative.setAttribute('class', 'PersonalLeaveBorder');
            }
            else if (!l_boolIsPersonalLeave && l_strInitials == '')
              divRelative.setAttribute('class', 'CalendarEventColor');


            var divAbsolute = document.createElement('div');
            if (l_strInitials == '')
              divAbsolute.innerText = l_strTextToDisplay;
            else
              divAbsolute.innerText = l_strInitials;
            divAbsolute.setAttribute('title', l_strTextToDisplay);
            divAbsolute.setAttribute('id', l_strElementID + l_intCounter2);
            divAbsolute.setAttribute('style', 'padding:0 2px;');
            divAbsolute.addEventListener('click', this.ShowEventsDataFromJson.bind(this, 'id2'));
            var spanIcon = document.createElement('span');
            spanIcon.setAttribute('Class', l_strIcon + ' CalendarInitialsIcons');
            spanIcon.setAttribute('id', l_strElementID + l_intCounter2);
            divAbsolute.appendChild(spanIcon);
            divRelative.appendChild(divAbsolute);
            divAbsolute.style.backgroundColor = l_Colors; //set by zain
            l_oElement.appendChild(divRelative);

            if (l_intCounter2 == 1) {
              var l_arrJsonData = CalendarUtility.FilterJsonExactlyMatching(this.m_arrEvents, 'id', l_strElementID);
              var l_strTDId = l_strElementID.replace('tdColumn_', '');

              var spanTotal = document.createElement('span');
              spanTotal.innerHTML = l_arrJsonData.length + '';
              spanTotal.setAttribute('style', 'float: right;font-size: 11px !important;margin: -2px 7px 0 0;color: #ffffff;background: #4e5f7a;padding: 1px 0px 0px 0px;height: 20px;position: relative;top: -2px;width: 20px;text-align: center;border-radius: 100%;');
              var spanTotalInner = document.createElement('span');
              //spanTotalInner.setAttribute('Class', l_strIcon + ' CalendarInitialsIcons');
              spanTotal.setAttribute('id', l_strElementID);
              spanTotal.addEventListener('click', this.ShowEventsDataFromJson.bind(this, 'id'));
              spanTotal.appendChild(spanTotalInner);

              var l_oTD = document.getElementById('Td_' + l_strTDId);
              if (l_oTD)
                l_oTD.appendChild(spanTotal);

            }

          }
        }
      }
    }
  }

  public GetHeightForCell(p_intHour, p_intDuration, p_intStartMinutes) {
    var l_intDivHeight = ((p_intDuration / 60) * 40).toFixed(0);
    var l_intMaxDuration = ((23 - p_intHour) * 60) + (60 - p_intStartMinutes);
    var l_intMaxDivHeight = ((l_intMaxDuration / 60) * 40).toFixed(0);
    if (parseInt(l_intDivHeight) >= parseInt(l_intMaxDivHeight))
      return l_intMaxDivHeight;
    else
      return l_intDivHeight;
  }

  public GetBottomBorderForJsonEventsBoxes(p_intHour, p_intDuration, p_intStartMinutes) {
    var l_intDivHeight = ((p_intDuration / 60) * 40).toFixed(0);
    var l_intMaxDuration = ((23 - p_intHour) * 60) + (60 - p_intStartMinutes);
    var l_intMaxDivHeight = ((l_intMaxDuration / 60) * 40).toFixed(0);
    if (parseInt(l_intDivHeight) >= parseInt(l_intMaxDivHeight))
      return '0';
    else
      return '2';
  }

  public RemovePreviouslyBindedEventsBoxes() {
   // debugger;
    for (var i = 0; i < this.m_arrRunningOrders.length; i++) {
      var l_strElementID = this.m_arrRunningOrders[i].id;
      var l_oElement = document.getElementById('tdColumn_' + l_strElementID);
      if (l_oElement != null)
        l_oElement.innerHTML = '';
    }
  }

  public BindPublicHolidays() {
    var l_arrCalendarData = this.m_arrCalendar;

    for (var i = 0; i < l_arrCalendarData.length; i++) {
      for (var j = 0; j < l_arrCalendarData[i].length; j++) {
        if (l_arrCalendarData[i][j]) {
          var l_oCurrentDate = new Date(this.m_oCurrentPage.year, this.m_oCurrentPage.month, l_arrCalendarData[i][j]);
          for (var k = 0; k < this.PublicHolidays.length; k++) {
            var l_strStartDateInDB = this.PublicHolidays[k].StartDate;
            var l_strEndDateInDB = this.PublicHolidays[k].EndDate;
            var l_strTitle = this.PublicHolidays[k].Purpose;
            if (l_strStartDateInDB != '' && l_strEndDateInDB != '') {
              var l_oStartDate = new Date(l_strStartDateInDB);
              var l_oEndDate = new Date(l_strEndDateInDB);
              //if (CalendarUtility.IsDateBetweenTwoDates(l_oStartDate, l_oCurrentDate, l_oEndDate)) {
              //  var l_oElement = document.getElementById('Td_' + i + '' + j);
              //  if (l_oElement) {
              //    l_oElement.setAttribute('class', 'publicholidays');
              //    //l_oElement.setAttribute('style', 'background:#cccccc !important;');
              //    l_oElement.setAttribute('title', l_strTitle);
              //    break;
              //  }
              //}
            }
          }
        }
      }
    }
  }

  public ShowEventsDataFromJson(p_strColumnToUse, p_oEvent) {

    var l_arrJsonData = CalendarUtility.FilterJsonExactlyMatching(this.m_arrEvents, 'id', p_oEvent.target.id);
    if (p_strColumnToUse == 'id2')
          l_arrJsonData = CalendarUtility.FilterJsonExactlyMatching(this.m_arrEvents, 'id2', p_oEvent.target.id);

      //console.log(l_arrJsonData);
      /*
    this.m_arrFormattedEvents = [];
    var l_arrFormattedColumns = [];
    var l_arrFormattedValues = [];
    let l_intCounter = 0;
    for (let l_oRow of l_arrJsonData) {
      console.log("Row:", l_oRow);
      for (let l_oColumn in l_oRow) {
        console.log("      Column:", l_oColumn, "value:", l_oRow[l_oColumn]);
     
        if (l_oColumn.indexOf('id') < 0 && l_oColumn.indexOf('Color') && l_oColumn != 'PersonalLeave' && l_oColumn != 'Icon' && ((l_oColumn == 'Initials') || l_oColumn != 'Initials')) {
          var l_strColumnName = l_oColumn;

          if (l_strColumnName == 'Text')
            l_strColumnName = 'Details';

          l_arrFormattedColumns[l_intCounter] = l_strColumnName;
          l_arrFormattedValues[l_intCounter] = l_oRow[l_oColumn];
          this.m_arrFormattedEvents[l_intCounter] = l_strColumnName + ': ' + l_oRow[l_oColumn]; // + " " + Math.random() * 100 + 100;
          l_intCounter++;
        }

      }
    }

    //MR
   // CalendarUtility.isAppointmentEdit = true;
    */

      if (this.Modal) {

          this.Modal.FormattedValues = l_arrJsonData;
          this.Modal.m_arrFormattedEvents = [];

        //this.Modal.FormattedColumns = l_arrFormattedColumns;
        //this.Modal.FormattedValues = l_arrFormattedValues;
        //this.Modal.m_arrFormattedEvents = this.m_arrFormattedEvents;
        this.Modal.ShowFormattedJsonData();
    }
  }


  public ShowEventsDataFromJson2(p_oEvent) {
    var l_arrJsonData = CalendarUtility.FilterJsonExactlyMatching(this.m_arrEvents, 'id', p_oEvent.target.id);

      /*
      this.m_arrFormattedEvents = [];
    var l_arrFormattedColumns = [];
    var l_arrFormattedValues = [];
    let l_intCounter = 0;
    for (let l_oRow of l_arrJsonData) {
      console.log("Row:", l_oRow);
      for (let l_oColumn in l_oRow) {
        console.log("      Column:", l_oColumn, "value:", l_oRow[l_oColumn]);

        if (l_oColumn.indexOf('id') < 0 && l_oColumn.indexOf('Color') && l_oColumn != 'PersonalLeave' && ((l_oColumn == 'Initials') || l_oColumn != 'Initials')) {
          var l_strColumnName = l_oColumn;

          if (l_strColumnName == 'Text')
            l_strColumnName = 'Details';

          l_arrFormattedColumns[l_intCounter] = l_strColumnName;
          l_arrFormattedValues[l_intCounter] = l_oRow[l_oColumn];
          this.m_arrFormattedEvents[l_intCounter] = l_strColumnName + ': ' + l_oRow[l_oColumn]; // + " " + Math.random() * 100 + 100;
          l_intCounter++;
        }

      }
    }*/

      if (this.Modal) {
          this.Modal.FormattedValues = l_arrJsonData;
          this.Modal.m_arrFormattedEvents = [];
        //this.Modal.FormattedColumns = l_arrFormattedColumns;
        //this.Modal.FormattedValues = l_arrFormattedValues;
        //this.Modal.m_arrFormattedEvents = this.m_arrFormattedEvents;
        this.Modal.ShowFormattedJsonData();
    }
  }

  /** Gets the DaysPerMonth array, returns value of index corresponding to month number */
  public GetDaysOfMonth(p_intMonth: number, p_intYear: number): number {

    /* Check leap years if February */
    if (p_intMonth == 1 && this.IsLeapYear(p_intYear)) {
      return 29;
    }

    /** Return the number of days, corresponding to that month */
    return [31, 28, 31, 30, 31, 30,
      31, 31, 30, 31, 30, 31][p_intMonth];
  }

  /** Returns true if leap year */
  public IsLeapYear(p_intYear): boolean {
    return ((p_intYear % 4 == 0) && (p_intYear % 100 != 0)) || (p_intYear % 400 == 0);
  }

}
