import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-CalendarUtility',
  templateUrl: './CalendarUtility.html'
})
export class CalendarUtility {

    public http: HttpClient;
    public baseUrl: string;

    public static isEditAction = false;
    public static isDeleteAction = false;
    public static isViewAction = false;
    public static PID = -1;

    public static ModalDisplay: any = { Title: true, Text: true, StartTime: true, EndTime: true, AllDay: false, Initials: false, Icon: false, URL: false/*, ID: true*/ };
    public static Actions: any = { Edit: true, Delete: true, View: false };
    public static EventsData: any[] = [];

    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.http = http;
        this.baseUrl = baseUrl;
    }

    public static AssignGridDataValuesToOtherGridData(p_oGridData) {

        var l_oArrayOfUniqueValues = [];
        var l_intCount = 0;
        if (p_oGridData != undefined) {
            for (var item, i = 0; item = p_oGridData[i++];) {
                l_oArrayOfUniqueValues[l_intCount] = p_oGridData[l_intCount];
                l_intCount++;
            }
        }
        return l_oArrayOfUniqueValues;
    }

    public static GetIndexExactlyMatchingColumn(p_oGridData, p_oColumn, p_strValue) {
        var l_arrFilteredData = [];
        var index = -1;
        for (var i = 0; i < p_oGridData.length; i++) {
            if (p_oGridData[i][p_oColumn] == p_strValue) {
                index = i;
                break;
            }
        }
        return index;
    }

    public static IsDateBetweenTwoDates(p_oStartDate, p_oToCheckDate, p_oEndDate) {
        p_oStartDate = new Date(p_oStartDate);
        p_oStartDate = p_oStartDate.setHours(0, 0, 0, 0);
        p_oToCheckDate = new Date(p_oToCheckDate);
        p_oToCheckDate = p_oToCheckDate.setHours(0, 0, 0, 0);
        p_oEndDate = new Date(p_oEndDate);
        p_oEndDate = p_oEndDate.setHours(0, 0, 0, 0);
        if (p_oStartDate <= p_oToCheckDate && p_oToCheckDate <= p_oEndDate) {
            return true;
        }
        else
            return false;
    }

    public static FilterJsonExactlyMatching(p_oGridData, p_oColumn, p_strValue) {
        var l_arrFilteredData = [];
        var count = 0;
        if (p_oGridData) {
            for (var i = 0; i < p_oGridData.length; i++) {
                if (p_oGridData[i][p_oColumn] == p_strValue) {
                    l_arrFilteredData[count] = p_oGridData[i];
                    count++;
                }
            }
        }
        return l_arrFilteredData;
    }

    public static FilterJsonExactlyMatchingDates(p_oGridData, p_oColumn, p_strValue) {
        var l_arrFilteredData = [];
        var count = 0;
        for (var i = 0; i < p_oGridData.length; i++) {
            if (p_strValue != '' && p_strValue != null && p_strValue != ' ') {
                if (p_oGridData[i][p_oColumn] != ' ') {
                    var l_oEnteredDate = new Date(p_strValue);
                    var l_oDateInDatabase = new Date(p_oGridData[i][p_oColumn]);
                    l_oEnteredDate.setHours(0, 0, 0, 0);
                    l_oDateInDatabase.setHours(0, 0, 0, 0);
                    if (l_oEnteredDate.getTime() == l_oDateInDatabase.getTime()) {
                        l_arrFilteredData[count] = p_oGridData[i];
                        count++;
                    }
                }
            }
        }
        return l_arrFilteredData;
    }

    

}
