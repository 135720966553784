import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DefaultTS } from '../SharedComponents/Default';
import { ChangeDetectorRef, NgZone } from '@angular/core';


@Injectable()
@Component({
  selector: 'Login',
  templateUrl: './Login.html'
})
export class Login {
  public http: HttpClient;
  public baseUrl: string;

  public UserName = '';
  public Password = '';

  public sub: any = {} as any;
  public LoginObject: any = {} as any;
  public ReturnURL = '' as any;
  // public LocalST: LocalStorage;
  public ShowErrors = [];
  public LastPrice = 0 as any;
  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private router: Router, private cd: ChangeDetectorRef, private zone: NgZone, private router2: ActivatedRoute, private Default: DefaultTS) {
    this.http = http;
    this.baseUrl = baseUrl;
    this.LoginObject.CallLogin = false;
    this.Default.IsUserLoggedIn = false;
    this.Default.UserTrackingSaveRecord();
    this.GetPageURL();
    // this.VerifyUser();

  }
  public VerifyUser() {

    let data = {} as any;
    this.Default.IsUserLoggedIn = false;
    data.UserName = this.UserName;
    data.Password = this.Password;
    this.http.post(this.baseUrl + 'api/SiteUser/GetUserLogin', data).subscribe(result => {
      //  document.getElementById('litMessage').innerText = '';
      var l_arrResponse = result as any;
      if (l_arrResponse[0].Status == 0) {
        this.Default.ShowAlert(l_arrResponse[0].Message);
      }
        if (l_arrResponse[0].Status == 1) {

            DefaultTS.Role = l_arrResponse[0].Role;
          this.Default.SiteUserID = l_arrResponse[0].SiteUserID;
        this.Default.IsUserLoggedIn = true;
        if (l_arrResponse[0].Role == "Admin") {
          this.Default.IsUserIsAdmin = true;
          this.Default.IsUserIsStaff = false;
          this.Default.IsUserIsScholar = false;

          this.router.navigate(['/dashboard']);
          this.Default.PageURL = "dashboard";
        }
        else if (l_arrResponse[0].Role == "Staff") {
          this.Default.IsUserIsAdmin = false;
          this.Default.IsUserIsStaff = true;
          this.Default.IsUserIsScholar = false;

          this.router.navigate(['/case']);
          this.Default.PageURL = "case";
        }
        else if (l_arrResponse[0].Role == "Scholar") {
          this.Default.IsUserIsAdmin = false;
          this.Default.IsUserIsStaff = false;
          this.Default.IsUserIsScholar = true;

          this.router.navigate(['/appointment']);
          this.Default.PageURL = "appointment";
        }
        else {
          this.Default.IsUserIsAdmin = false;
          this.Default.IsUserIsStaff = false;
          this.Default.IsUserIsScholar = false;
        }
          this.Default.UserName = l_arrResponse[0].UserName.substring(0, 100);

      }
      else {

        // this.ShowErrors[2] = 'Incorrect UserName or Password.';

      }
    }, error => console.error(error));
  }

  public LoginValidation(p_SaveClicked) {
   
    var l_boolShouldSave = true;
    //this.LoginObject.CallLogin = true;
    if (this.LoginObject.CallLogin) {
      this.ShowErrors = [];
      if ((this.UserName == '') || !this.UserName) {
        this.ShowErrors[0] = 'User Name required.';
        l_boolShouldSave = false;
      }

      if (this.Password == '' || !this.Password) {
        this.ShowErrors[1] = 'Password required.';
        l_boolShouldSave = false;
      }

      if (l_boolShouldSave && p_SaveClicked)
        this.VerifyUser();
    }

  }

  GetPageURL() {
    var URL = window.location.href;
    var res = URL.split("/");
    this.Default.PageURL = res[(res.length - 1)];
  }

  public RoutToDashboard() {
   
    this.Default.IsUserLoggedIn = true;
    this.router.navigate(['/dashboard']);
  }

}
