import { Component, OnInit, Input, Output, EventEmitter,Inject } from '@angular/core';
import { CalendarUtility } from '../CalendarUtility/CalendarUtility';

@Component({
  selector: 'ShowCalendarModal',
  templateUrl: './ShowCalendarModal.html'
})

export class ShowCalendarModal implements OnInit {
    @Input() public m_arrFormattedEvents: any[] = [];
   // @Input() public FormattedColumns: any[] = [];
    @Input() public FormattedValues: any[] = [];

    public ModalHeading: any;
    public ListOfIds: any[]= [];
    public m_arrFormattedColumns: any[] = [];

    public Edit = false;
    public Delete = false;
    public View = false;

    constructor() {
       
    }
    ngOnInit() {     
    }

    ngOnDestroy() {
       
    }
    

    public ShowFormattedJsonData() {

        this.ListOfIds = [];
        this.m_arrFormattedColumns = [];

        this.Edit = CalendarUtility.Actions.Edit;
        this.Delete = CalendarUtility.Actions.Delete;
        this.View = CalendarUtility.Actions.View;

        this.ModalHeading = 'Details';


        for (let l_oRow of this.FormattedValues) {
           
            for (let l_oColumn in l_oRow) {              

                if (CalendarUtility.ModalDisplay.Title && l_oColumn == 'Title') {

                    var l_data = l_oColumn + ': ' + l_oRow[l_oColumn];
                    this.m_arrFormattedEvents.push(l_oRow[l_oColumn]);
                    this.m_arrFormattedColumns.push(l_oColumn);
                   

                    if (this.FormattedValues.length == 1) {
                        this.ModalHeading = l_oRow[l_oColumn];
                    }
                }

                if (CalendarUtility.ModalDisplay.Text && l_oColumn == 'Text') {
                    var l_data = l_oColumn + ': ' + l_oRow[l_oColumn];
                    this.m_arrFormattedEvents.push(l_oRow[l_oColumn]);
                    this.m_arrFormattedColumns.push(l_oColumn);
                 
                }

                if (CalendarUtility.ModalDisplay.StartTime && l_oColumn == 'StartTime') {
                    var l_data = l_oColumn + ': ' + l_oRow[l_oColumn];
                    this.m_arrFormattedEvents.push(l_oRow[l_oColumn]);
                    this.m_arrFormattedColumns.push(l_oColumn);
                  
                }

                if (CalendarUtility.ModalDisplay.EndTime && l_oColumn == 'EndTime') {
                    var l_data = l_oColumn + ': ' + l_oRow[l_oColumn];
                    this.m_arrFormattedEvents.push(l_oRow[l_oColumn]);
                    this.m_arrFormattedColumns.push(l_oColumn);
                    
                }

                if (CalendarUtility.ModalDisplay.AllDay && l_oColumn == 'AllDay') {
                    var l_data = l_oColumn + ': ' + l_oRow[l_oColumn];
                    this.m_arrFormattedEvents.push(l_oRow[l_oColumn]);
                    this.m_arrFormattedColumns.push(l_oColumn);
                
                }

                if (CalendarUtility.ModalDisplay.Initials && l_oColumn == 'Initials') {
                    var l_data = l_oColumn + ': ' + l_oRow[l_oColumn];
                    this.m_arrFormattedEvents.push(l_oRow[l_oColumn]);
                    this.m_arrFormattedColumns.push(l_oColumn);
                  
                }

                if (CalendarUtility.ModalDisplay.Icon && l_oColumn == 'Icon') {
                    var l_data = l_oColumn + ': ' + l_oRow[l_oColumn];
                    this.m_arrFormattedEvents.push(l_oRow[l_oColumn]);
                    this.m_arrFormattedColumns.push(l_oColumn);
                    
                }

                if (CalendarUtility.ModalDisplay.URL && l_oColumn == 'URL') {
                    var l_data = l_oColumn + ': ' + l_oRow[l_oColumn];
                    this.m_arrFormattedEvents.push(l_oRow[l_oColumn]);
                    this.m_arrFormattedColumns.push(l_oColumn);
                    
                }

                if (/*CalendarUtility.ModalDisplay.ID &&*/ l_oColumn == 'SelectedID') {
                   
                    var l_data = l_oColumn + ': ' + l_oRow[l_oColumn];
                    this.m_arrFormattedEvents.push(l_oRow[l_oColumn]);
                    this.m_arrFormattedColumns.push(l_oColumn);
                

                    let keyValue: KeyValuePair = { key: l_oColumn, value: l_oRow[l_oColumn] };                    
                    this.ListOfIds.push(keyValue);
                    
                }

            }
        } 

        this.showModal();
           
    }

    private closeModal() {
        document.getElementById("modalCloseButon").click();
    }

    private showModal() {
        document.getElementById("ShowCalendarModal2").click();            
    }

    public EditRecord(p_Id) {
       
        this.resetFlagAndValues();
        CalendarUtility.isEditAction = true;       
        CalendarUtility.PID = p_Id;
       
       
    }

    public DeleteRecord(p_Id) {

        this.resetFlagAndValues();
        CalendarUtility.isDeleteAction = true;
        CalendarUtility.PID = p_Id;
    }

    public ViewRecord(p_Id) {

        this.resetFlagAndValues();
        CalendarUtility.isViewAction = true;
        CalendarUtility.PID = p_Id;
    }

    public resetFlagAndValues() {

        this.ListOfIds = [];
        CalendarUtility.isEditAction = false;
        CalendarUtility.isDeleteAction = false;
        CalendarUtility.isViewAction = false;
        CalendarUtility.PID = -1;
        this.closeModal();
    } 

}

interface KeyValuePair {
    key: string;
    value: string;
}

