import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, UrlSerializer } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { PaginationModule } from 'ngx-bootstrap';
import { Login } from './Login/Login';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LowerCaseUrlSerializer } from './SharedComponents/URLSerializer';
import { SharedDataModule } from './SharedData.module';
import { AppRoutingModule } from './app-routing.module';
import { CalendarModule } from './Calendar/CalendarMain/Calendar.module';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    Login
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    PaginationModule.forRoot(),
      SharedDataModule.forRoot(),
      //CalendarModule.forRoot(),   
    CommonModule,
    ReactiveFormsModule,
      
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      { path: '', component: Login, pathMatch: 'full' },
      { path: 'login', component: Login }
    

    ])
  ],
//  providers: [ {
//  provide: UrlSerializer,
//  useClass: LowerCaseUrlSerializer
//}],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl }
  ],

  bootstrap: [AppComponent]
})


export class AppModule { }
