import { Component, Inject, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DefaultTS } from '../SharedComponents/Default';
import { FileUploaderCustom } from '../SharedComponents/FileUploader';


@Component({
  selector: 'app-PTDragDropUpload',
  templateUrl: './PTDragDropUpload.html',
  styleUrls: ['./PTDragDropUpload.css']
})
export class PTDragDropUpload {
  public http: HttpClient;
  public baseUrl: string;
  FilesList: any[] = [];

  @ViewChild("PTFileUploaderInput", { static: false }) PTFileUploaderInput;
  @Input() public PTFilesList = [];
  @Input() public ObjectType = 0;
  @Input() public ObjectID = 0;
  @Input() public MaxNumberOfFiles = 1000;
  @Input() public UploadType = 2; // UploadType=1 means images and files and videos, UploadType=2 means Images only, UploadType=3 means files only, UploadType=4 means Videos only
  @Output() ResponseFromPTUploader: EventEmitter<any> = new EventEmitter();
  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private router: Router, public Default: DefaultTS, private FileUpload: FileUploaderCustom) {
    this.http = http;
    this.baseUrl = baseUrl;


  }


  public async UploadAndSavePartnerFilesInArray1() {
    for (var i = 0; i < this.FilesList.length; i++) {

      if (this.PTFilesList.length >= this.MaxNumberOfFiles) {
        if (this.MaxNumberOfFiles == 1)
          this.Default.ShowAlert('You are only allowed to add upto ' + this.MaxNumberOfFiles + ' file only, Rest of the files are discarded.');
        else if (this.MaxNumberOfFiles > 1)
          this.Default.ShowAlert('You are only allowed to add upto ' + this.MaxNumberOfFiles + ' files only, Rest of the files are discarded.');

        break;
      }
      else {
      var FileNewName = this.Default.GenerateUniqueGUID() + "-" + this.FilesList[i].name;
      var FileUrlSpaces = FileNewName;
      var ReplaceSpaces = FileUrlSpaces.replace(/\s/g, "");
      var FilePath = 'Uploads/' + ReplaceSpaces;
        if (this.PTFileUploaderInput) {
          let SelectedFiles = this.PTFileUploaderInput.nativeElement;
          if (SelectedFiles.files && SelectedFiles.files[i]) {
            let fileToUpload = SelectedFiles.files[i];

            await this.FileUpload.AddFileInQueueForUploadingV3DragDrop(fileToUpload, ReplaceSpaces, this.UploadType);
            if (this.Default.FileUploaderStatus == 1) {
              this.PTFilesList.push({ ObjectID: this.ObjectID,  FileURL: FilePath, FileURLDisplay: this.Default.GetFileIconToDisplay(FilePath) });
             
            }
          }
        }
      }
  
    }
    this.UpdateStatusStatus();
   
    (document.getElementById("PTFileUploaderInput") as HTMLInputElement).value = "";
    this.FilesList = [];

  }


  OnPTFileDropped($event) {
    this.PreparePTFilesList($event);
  }


  PTFileBrowseHandler(files) {
    this.PreparePTFilesList(files);
  }

  PreparePTFilesList(files: Array<any>) {
    for (var i = 0; i < files.length; i++) {
      this.FilesList.push(files[i]);
      if (i == files.length - 1) {
        this.UploadAndSavePartnerFilesInArray1();
      }
    }
  
  }

  public RemovePTFile(p_index) {
    this.PTFilesList.splice(p_index, 1);
    this.UpdateStatusStatus();
  }


  public UpdateStatusStatus() {

    let data = {} as any;
    data.ObjectType = this.ObjectType;
    data.ObjectID = this.ObjectID;
    data.PTFilesList = this.PTFilesList;
    this.ResponseFromPTUploader.emit(data);
 
  }


}
