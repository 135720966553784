import { Component, Inject, Injectable, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { FilterJsonData } from '../Pipes/FilterPipe';
import { DefaultTS } from '../SharedComponents/Default';
import { SortJsonData } from '../Pipes/SortingPipe';



@Injectable()
@Component({
  selector: 'Messaging',
  templateUrl: './Messaging.html',
  styleUrls: ['./Messaging.css']
})

export class Messaging implements OnInit {
  public http: HttpClient;
  public baseUrl: string;
  public AddAttachmentMode = false;
  public InputFields = {} as any;
  public Message = [] as any;
  public Attachment = [] as any;
  public PTFilesList = [];
  @Input() public OtherEntityID = 0;
  @Input() public MessageTypeID = 0;
  @Input() public AddMessage = 1;
  @Input() public AddAttachmentsOnTop = false;
  @Output() PendingMessage: EventEmitter<any> = new EventEmitter();
  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private router: Router, public Default: DefaultTS) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngOnInit() {
    this.AjaxLoadMessages();
  }
  ngDoCheck() {
    if ((this.PTFilesList != undefined && this.PTFilesList.length > 0) || (this.InputFields.Content && this.InputFields.Content != '')) {
      this.PendingMessage.emit(true);
    } else if ((!this.PTFilesList || this.PTFilesList.length == 0) || (this.InputFields.Content && this.InputFields.Content != '') ) {
      this.PendingMessage.emit(false);
    }
  }
  public AjaxLoadMessages() {
    let PostData = {} as any;
    PostData.OtherEntityID = this.OtherEntityID;
    PostData.MessageTypeID = this.MessageTypeID;
    this.Message = [];
    this.Attachment = [];
    // this.Default.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/Message/AjaxLoadMessages', PostData).subscribe(result => {
      var l_arrResponse = result as any;
      //  this.Default.SetMasterAnimation(false);
      if (l_arrResponse[0][0].Status == 1) {
        this.Message = l_arrResponse[1];
        this.Attachment = l_arrResponse[2];
      }
      else {

      }
    }, error => console.error(error));
  }

  public AjaxLoadMessagesOnMark() {
    let PostData = {} as any;
    PostData.OtherEntityID = this.OtherEntityID;
    PostData.MessageTypeID = this.MessageTypeID;
    // this.Default.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/Message/AjaxLoadMessages', PostData).subscribe(result => {
      var l_arrResponse = result as any;
      //  this.Default.SetMasterAnimation(false);
      if (l_arrResponse[0][0].Status == 1) {
        this.Message = l_arrResponse[1];
        this.Attachment = l_arrResponse[2];
      }
      else {

      }
    }, error => console.error(error));
  }

  public SaveMessage() {
    let PostData = {} as any;
    PostData.Content = '';
    PostData.FilesData = this.PTFilesList;
    PostData.OtherEntityID = this.OtherEntityID;
    PostData.MessageTypeID = this.MessageTypeID;
    var l_boolIsOkay = true;
    var l_strMessage = "Following are required fields: ";
    for (let field in PostData) {
      if (this.InputFields[field] !== undefined && this.InputFields[field] != null)
        PostData[field] = this.InputFields[field];
    }
    if (PostData.Content == '') {
      l_strMessage += '*Message'
      l_boolIsOkay = false;
    }


    if (l_boolIsOkay) {
      this.AjaxSaveMessage(PostData);
    }
    else {
      this.Default.ShowAlert(l_strMessage);
    }

  }


  public AjaxSaveMessage(PostData) {

    this.Default.SetMasterAnimation(true);

    this.http.post(this.baseUrl + 'api/Message/AjaxSaveMessage', PostData).subscribe(result => {
      var l_arrResponse = result as any;
      this.Default.SetMasterAnimation(false);
      this.AddAttachmentMode = false;
      this.AjaxLoadMessages();
      if (l_arrResponse[0][0].Status == 1) {
        this.InputFields.Content = '';
        this.PTFilesList = [];
        this.Message = l_arrResponse[1];
        this.Attachment = l_arrResponse[2];
      }
      else {

      }
    }, error => console.error(error));
  }


  public MarkAsRead(MessageID, Index) {
    this.Message[Index].IsRead = !this.Message[Index].IsRead;
    this.AjaxMarkAsRead(MessageID);
  }

  public AjaxMarkAsRead(MessageID) {

    let PostData = {} as any;
    PostData.MessageID = MessageID;

    this.http.post(this.baseUrl + 'api/Message/AjaxMarkAsRead', PostData).subscribe(result => {
      this.AjaxLoadMessagesOnMark();
    }, error => console.error(error));
  }

  public MarkAsDelete(MessageID, Index) {
    this.Message.splice(Index,1);
    this.AjaxMarkAsDelete(MessageID);
  }

  public AjaxMarkAsDelete(MessageID) {

    let PostData = {} as any;
    PostData.MessageID = MessageID;
    for (var i = 0; i < this.Attachment.length; i++) {
      if (this.Attachment[i].MessageID == MessageID) {
        this.Attachment.splice(i, 1);
      }
    }
    this.http.post(this.baseUrl + 'api/Message/AjaxMarkAsDelete', PostData).subscribe(result => {
      this.AjaxLoadMessagesOnMark();
    }, error => console.error(error));
  }

  public ResponseFromPTUploader(data) {
    this.PTFilesList = data.PTFilesList;
  }


}
