import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'FilterJsonData',
    pure: false
})
export class FilterJsonData implements PipeTransform {

    
    transform(items: any, filter: any): any[] {
        if (!items || !filter) {
            return items;
        }
        //MM:: filter items array, items which match and return true will be kept, false will not be included
        return items.filter((item: any) => this.applyFilter(item, filter));
    }
    
    applyFilter(ArrayOfItems: any, filter: any): boolean {
        var l_intCounterToCheckFilters = 0;
        var l_intCounterToCheckAppliedFiltersLength = 0;
        //debugger;
        for (let field in filter) {
            l_intCounterToCheckAppliedFiltersLength++;
          if (filter[field] ) {
            if (typeof filter[field] === 'string') {
                    let l_strDataInDataBase = ArrayOfItems[field].toString();
                    let l_strEnteredData = filter[field].toString();
                    if (l_strDataInDataBase.toLowerCase().indexOf(l_strEnteredData.toLowerCase()) > -1) {
                        l_intCounterToCheckFilters++;
                    }
                }
                else if (typeof filter[field] === 'number') {
                    let l_strDataInDataBase = ArrayOfItems[field].toString();
                    let l_strEnteredData = filter[field].toString();
                    if (l_strDataInDataBase.toLowerCase().indexOf(l_strEnteredData.toLowerCase()) > -1) {
                        l_intCounterToCheckFilters++;
                    }
                }
                else if (typeof filter[field] === 'object') {
                  if (filter[field][0] == 'DateRange') {
                    let l_strDataInDataBase = ArrayOfItems[field].toString();
                    if (l_strDataInDataBase != '' && l_strDataInDataBase != ' ') {
                      var l_strSplittedData = l_strDataInDataBase.split('/');
                      debugger;
                      if (l_strSplittedData.length > 1) {
                        l_strDataInDataBase = l_strSplittedData[2] + '/' + l_strSplittedData[1] + '/' + l_strSplittedData[0];
                      }
                      let l_oDateInDataBase = new Date(l_strDataInDataBase);
                      l_oDateInDataBase.setHours(0, 0, 0, 0);
                      if (filter[field][1] != '' && filter[field][2] != '') {
                        let l_oStartDate = new Date(filter[field][1]);
                        l_oStartDate.setHours(0, 0, 0, 0);
                        let l_oEndDate = new Date(filter[field][2]);
                        l_oEndDate.setHours(0, 0, 0, 0);
                        if (l_oStartDate <= l_oDateInDataBase && l_oDateInDataBase <= l_oEndDate)
                          l_intCounterToCheckFilters++;
                      }
                      else if (filter[field][1] == '' && filter[field][2] == '') {
                        l_intCounterToCheckFilters++;
                      }
                    }
                  }
                    else {
                        let l_strDataInDataBase = ArrayOfItems[field].toString();
                        for (var i = 0; i < filter[field].length; i++) {
                            if (filter[field][i] !== undefined) {
                                let l_strEnteredData = filter[field][i].toString();
                                if (l_strDataInDataBase.toLowerCase().indexOf(l_strEnteredData.toLowerCase()) > -1) {
                                    l_intCounterToCheckFilters++;
                                    //break;
                                }
                            }
                        }
                    }
                }
            }
            else if (filter[field] === undefined || filter[field] == "") {
                l_intCounterToCheckFilters++;
            }
        }

        if (l_intCounterToCheckFilters === l_intCounterToCheckAppliedFiltersLength)
            return true;
        else
            return false;
    }
}
