import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
const routes: Routes = [
  { path: 'dashboard', loadChildren: './Dashboard/Dashboard.module#DashboardModule' },
  { path: 'signup', loadChildren: './Signup/Signup.module#SignupModule' },
  { path: 'forgotpassword', loadChildren: './ForgotPassword/ForgotPassword.module#ForgotPasswordModule' },
  { path: 'resetpassword', loadChildren: './Resetpassword/Resetpassword.module#ResetpasswordModule' },
  { path: 'updatepassword', loadChildren: './UpdatePassword/UpdatePassword.module#UpdatePasswordModule' },
  //{ path: 'calendar', loadChildren: './Calendar/CalendarMain/MainCalendar.module#MainCalendarModule' },
  { path: 'calendar', loadChildren: './Calendar/CalendarMain/Calendar.module#CalendarModule' },
  { path: 'admin1', loadChildren: './Admin1/Admin1.module#Admin1Module' },
  { path: 'client', loadChildren: './Client/Client.module#ClientModule' },
  { path: 'scholar', loadChildren: './Scholar/Scholar.module#ScholarModule' },
  { path: 'case', loadChildren: './Case/Case.module#CaseModule' },
  { path: 'caseprocedure', loadChildren: './CaseProcedure/CaseProcedure.module#CaseProcedureModule' },
  { path: 'appointment', loadChildren: './Appointment/Appointment.module#AppointmentModule' },
  { path: 'dailydiary', loadChildren: './DailyDiary/DailyDiary.module#DailyDiaryModule' },
  { path: 'user', loadChildren: './User/User.module#UserModule' },
  { path: 'unittest', loadChildren: './UnitTest/UnitTest.module#UnitTestModule' },
  { path: 'usertracking', loadChildren: './UserTracking/UserTracking.module#UserTrackingModule' },
  { path: 'filelocationlist', loadChildren: './ISCFileLocationList/ISCFileLocationList.module#ISCFileLocationListModule' },
  { path: 'messaging', loadChildren: './Communication/Messaging.module#MessagingModule' },
  { path: 'dashboardscholar', loadChildren: './DashboardScholar/DashboardScholar.module#DashboardScholarModule' },
  { path: 'dashboardstaff', loadChildren: './DashboardStaff/DashboardStaff.module#DashboardStaffModule' },
  { path: 'status', loadChildren: './ISCStatus/ISCStatus.module#ISCStatusModule' },
  { path: 'letterheadimages', loadChildren: './LetterHeadImages/LetterHeadImages.module#LetterHeadImagesModule' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
