import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { DefaultTS } from '../SharedComponents/Default';
import { Location } from '@angular/common';
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {

  public http: HttpClient;
  public baseUrl: string;
  public LeftMenuUL = false;
  public LeftMenuUL1 = false;
  //public PageURL: string;
  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private router: Router, private cd: ChangeDetectorRef, private zone: NgZone, private router2: ActivatedRoute, public Default: DefaultTS, private location: Location) {
    this.http = http;
    this.baseUrl = baseUrl;

    this.Default.IsUserLoggedIn = false;
    this.Default.IsUserIsAdmin = false;
    this.GetPageURL();
    this.CheckLoginUserDetails();
  }

  //public load() {
  //  location.reload();
  //}

  public CheckLoginUserDetails() {
    let data = {} as any;
    var l_strAddress = window.location.href;
    var l_strPathName = window.location.pathname;
    data.CompleteURL = l_strAddress;
    data.PageURL = l_strPathName;
    data.BaseURL = this.baseUrl;
    data.UserName = '';
    data.Password = '';
    this.http.post(this.baseUrl + 'api/SiteUser/GetUserLogin', data).subscribe(result => {
      //  document.getElementById('litMessage').innerText = '';
      var l_arrResponse = result as any;
        if (l_arrResponse[0].Status == 1) {
          DefaultTS.Role = l_arrResponse[0].Role;
          this.Default.SiteUserID = l_arrResponse[0].SiteUserID;
        this.Default.IsUserLoggedIn = true;
        if (l_arrResponse[0].Role == "Admin") {
          this.Default.IsUserIsAdmin = true;
          this.Default.IsUserIsStaff = false;
          this.Default.IsUserIsScholar = false;
            
          //this.router.navigate(['/dashboard']);
          //this.Default.PageURL = "dashboard";
        }
        else if (l_arrResponse[0].Role == "Staff") {
          this.Default.IsUserIsAdmin = false;
          this.Default.IsUserIsStaff = true;
          this.Default.IsUserIsScholar = false;

          //this.router.navigate(['/case']);
          //this.Default.PageURL = "case";
        }
        else if (l_arrResponse[0].Role == "Scholar") {
          this.Default.IsUserIsAdmin = false;
          this.Default.IsUserIsStaff = false;
          this.Default.IsUserIsScholar = true;

          //this.router.navigate(['/appointment']);
          //this.Default.PageURL= "appointment";
        }
        else {
          this.Default.IsUserIsAdmin = false;
          this.Default.IsUserIsStaff = false;
          this.Default.IsUserIsScholar = false;
        }
          this.Default.UserName = l_arrResponse[0].UserName;

      };
      if (this.Default.PageURL != '' && this.Default.IsUserLoggedIn == false) {
        if (this.Default.PageURL != "login" && this.Default.PageURL != "forgotpassword" && this.Default.PageURL.split('?')[0] != "resetpassword") {
          this.Default.ShowAlert('You do not have permissions to access this page.');
          this.router.navigate(['/login']);
        }
        else if (this.Default.PageURL.split('?')[0]  == "resetpassword") {
        }
      }

      ////For Scholar security
      //if (this.Default.PageURL != '' && this.Default.IsUserLoggedIn == true) {
      //  if ((this.Default.PageURL == "scholar" || this.Default.PageURL == "client" || this.Default.PageURL == "user" || this.Default.PageURL == "filelocationlist" || this.Default.PageURL == "caseprocedure") && this.Default.IsUserIsScholar) {
      //    this.Default.ShowAlert('You do not have access to this page.');
      //    this.router.navigate(['/appointment']);
      //    this.Default.PageURL = "appointment";
      //  }
      //}
      // //For Staff security
      //if (this.Default.PageURL != '' && this.Default.IsUserLoggedIn == true) {
      //  if ((this.Default.PageURL == "user" || this.Default.PageURL == "filelocationlist") && this.Default.IsUserIsStaff) {
      //    this.Default.ShowAlert('You do not have access to this page.');
      //    this.router.navigate(['/case']);
      //    this.Default.PageURL = "case";
      //  }
      //}
    }, error => console.error(error));
  }

  ShowHideLeftMenuUL() {
    this.LeftMenuUL = !this.LeftMenuUL;
    if (!this.LeftMenuUL) {
      document.getElementById('menuexpand').setAttribute('src', 'assets/img/arrowright.png');
      document.getElementById('menuexpand').setAttribute('style', 'height:auto; width:6px;');
    }
    else {
      document.getElementById('menuexpand').setAttribute('src', 'assets/img/arrowdown.png');
      document.getElementById('menuexpand').setAttribute('style', 'width:auto; height:6px;');
    }
  }
  ShowHideLeftMenuUL1() {
    this.LeftMenuUL1 = !this.LeftMenuUL1;
    if (!this.LeftMenuUL) {
      document.getElementById('menuexpand1').setAttribute('class', 'menu-expand');
    }
    else
      document.getElementById('menuexpand1').setAttribute('class', 'menu-expand menu-expand2');
  }

  GetPageURL() {
    var URL = window.location.href;
    var res = URL.split("/");
    this.Default.PageURL = res[(res.length - 1)];
  }

  public LogoutUser() {
    let data = {} as any;
    this.http.post(this.baseUrl + 'api/SiteUser/LogoutUser', data).subscribe(result => {
      this.Default.IsUserLoggedIn = false;
      this.router.navigate(['/login']);
    }, error => console.error(error));
  }

}
